import React, { useEffect } from 'react';
import { t } from 'react-i18nify';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { SUBSCRIPTION_PLANS } from '../utils';
import { Plan } from './pricing/plan.tsx';
import VpsHeader from './VpsHeader';

export default function Pricing(props) {
    const { setLastScreen, setOptions, authenticated, user, sid, language, notify } = props;
    useEffect(() => {  
        setLastScreen('PRICING');
    }, [setLastScreen]);

    //const credits4SpeechSynthesizer = [2, 125, 265, 420];
    const credits4Presenter = [2, 125, 265, 420];
    const credits4Transcription = [0.5, 125, 265, 420];
    const credits4Task = [0.5, 125, 265, 420];
    
    const subscriptionPlans = [
      {
        title: SUBSCRIPTION_PLANS[0].title,
        price: "0",
        highlights: [
            { text: t('edit_subtitles') },
            { text: t('total_presenters', {num: credits4Presenter[0]}) },
            { text: t('total_video_length_for_transcription', {num: credits4Transcription[0], duration: t('day')}) },
            { text: t('total_video_length_for_other_tasks', {num: credits4Task[0], duration: t('day')}) },
            { text: t('results_with_logo') },
            { text: t('results_kept_for_one_day') },
        ],
        duration: t('day'),
        //titleDescription: "Individual and small teams",
      },
      {
        title: SUBSCRIPTION_PLANS[1].title,
        price: "9",
        subscriptionPlanId: SUBSCRIPTION_PLANS[1].id,
        highlights: [
            { text: t('edit_subtitles') },
            { text: t('total_presenters', {num: credits4Presenter[1]}) },
            { text: t('total_video_length_for_transcription', {num: credits4Transcription[1], duration: t('month')}) },
            { text: t('total_video_length_for_other_tasks', {num: credits4Task[1], duration: t('month')}) },
            { text: t('results_without_logo') },
            { text: t('results_kept_for_ten_days') },
        ],
        duration: t('month'),
        //guarantee: "Change or cancel any time",
        //titleDescription: "Individual and small teams",
      },
      {
        title: SUBSCRIPTION_PLANS[2].title,
        price: "19",
        subscriptionPlanId: SUBSCRIPTION_PLANS[2].id,
        highlights: [
            { text: t('edit_subtitles') },
            { text: t('total_presenters', {num: credits4Presenter[2]}) },
            { text: t('total_video_length_for_transcription', {num: credits4Transcription[2], duration: t('month')}) },
            { text: t('total_video_length_for_other_tasks', {num: credits4Task[2], duration: t('month')}) },
            { text: t('results_without_logo') },
            { text: t('results_kept_for_ten_days') },
        ],
        duration: t('month'),
        //highlightText: t('Popular'),
        //guarantee: "Change or cancel any time",
        //titleDescription: "Individual and small teams",
      },
      {
        title: SUBSCRIPTION_PLANS[3].title,
        price: "29",
        subscriptionPlanId: SUBSCRIPTION_PLANS[3].id,
        highlights: [
            { text: t('edit_subtitles') },
            { text: t('total_presenters', {num: credits4Presenter[3]}) },
            { text: t('total_video_length_for_transcription', {num: credits4Transcription[3], duration: t('month')}) },
            { text: t('total_video_length_for_other_tasks', {num: credits4Task[3], duration: t('month')}) },
            { text: t('results_without_logo') },
            { text: t('results_kept_for_ten_days') },
        ],
        duration: t('month'),
        //guarantee: "Change or cancel any time",
        //titleDescription: "Individual and small teams",
      },
    ];
    
    return (
        <VpsHeader {...props}>
            <div className="flex mx-2 mb-2">
                <div className="col-12 text-center">
                    { t('Pricing_description') } <sup>*</sup>{/*<font color="yellow">{ t('Subscription_bonus') }</font>*/}
                </div>
            </div>

            <PayPalScriptProvider options={{ 
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY,
                intent: "subscription",
                vault: true, 
            }}>
                <Plan setOptions={setOptions} authenticated={authenticated} user={user} sid={sid} language={language} notify={notify} 
                planProps={{Currency: "$", Plans: subscriptionPlans}} />
            </PayPalScriptProvider>

            <div class="row m-3">
                <p dangerouslySetInnerHTML={{ __html: t('Subscription_Plan_note') }} />
            </div>  
        </VpsHeader>
    );
}

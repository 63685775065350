const en = {
    VideoPlus_Studio: "VideoPlus Studio",
    Copyright: "© 2024 aiTransformer Technologies. All rights reserved.",
    PageNotFound: "Sorry, the page you are trying to visit does not exist.",
    app_headline0: "Simplify video transformation with AI and lower the barrier to creative video production",
    app_headline1: "Elevate your video creation with AI",
    app_headline2: "Transform videos with special effects. Generate video storybooks. Transcribe audios/videos. Make your videos/documents/images speak in 80 languages via subtitles.",
    app_highlight1: "Transform videos with special effects such as cartoons",
    app_highlight2: "Generate video storybooks of diverse genres and styles",
    app_highlight3: "Transcribe audios/videos with auto language detection",
    app_highlight4: "Make videos/documents/images speak in 80 languages via subtitles",
    app_cta_headline1: "Elevate your video creation with VideoPlus Studio",
    app_cta_headline2: "Get started with daily free credits",
    app_cta1: "Start for free",
    app_cta2: "Try it now",
    TTS: "Text To Speech",
    Samples: "Samples",
    Use_Cases: "Use Cases",
    Special_Use_Case: "Latest Sample Videos from Our YouTube Channel",
    Brands: "Partners / Customers",
    Contact_Us: "Contact Us",
    Mission: "Making AI work for everyone",
    Quick_Links: "Quick Links",
    Follow_Us: "Follow Us On",
    HOME: "Home",
    LOADING: "Loading...",
    SUB_TEXT: "Subtitle Text",
    OPEN_VIDEO: "Open a video file",
    open_video_info: "Supported video file types: mp4, flv, mkv, avi, rmvb, mov, webm, m4v. Maximum size: %{maxSize} MB.",
    open_video_extra_info: "The subtitles will be reset upon opening a new video, you can then enter subtitles, or use the button in the top toolbar to import subtitles from srt, ass, vtt or json file for editing.",
    OPEN_DOCUMENT: "Open a document or image file",
    open_document_info: "Supported document file types: doc, docx, ppt, pptx, xls, xlsx, pdf, rtf, csv, xml, html, txt, and all image types. Maximum size: %{maxSize} MB.",
    open_document_extra_info: "When opening a document, the document is divided into pages, every page is rendered as an image and the extracted text (if any) as its subtitle; when opening an image, if it's an animated GIF, every frame is rendered as an image, otherwise one image is rendered. The resulting video dimension will be the dimension of the first opened image, and other images larger than the first one will be resized proportionally to fit in.",
    OPEN_SUB: "Open a subtitle file",
    open_sub_info: "Supported subtitle file types: vtt, srt, ass, json. Maximum size: 1MB.",
    IMPORT_SUB: "Import subtitles from srt, ass, vtt or json file",
    toggle_theme: "Toggle light/dark theme",
    TRANSLATING: "Translating...",
    TRANSLAT_SUCCESS: "Translation succeeded",
    //DECODE_START: "Start decoding audio",
    //DECODE_SUCCESS: "Decoding audio succeeded",
    //DECODE_ERROR: "Decoding audio failed",
    VIDEO_EXT_ERR: "Unable to open this video format",
    SUB_EXT_ERR: "Unable to open this subtitle format",
    //MOBILE_TIP: "Please use computer access",
    //HOTKEY_01: "Space: Play / Pause",
    //HOTKEY_02: "Ctrl + Z: Undo",
    SPLIT: "Split Subtitle",
    LOADING_FFMPEG: "Loading dependency...",
    //LOADING_FONT: "Loading font...",
    //LOADING_VIDEO: "Loading video...",
    //LOADING_SUB: "Loading subtitle...",
    //BURN_ERROR: "Burn subtitles failed",
    //BURN_START: "Start burn subtitles",
    //BURN_SUCCESS: "Burn subtitles succeeded",
    //OPEN_VIDEO_ERROR: "Please open the video first",
    NEW: "New",
    NEW_hint: "Start a new subtitle-to-speech task by opening a video, document or image file",
    OPEN: "Open",
    EXPORT: "Export",
    EXPORT_hint: "Save subtitles to a local file",
    Subtitle_Format: "Subtitle Format",
    Json_note: "Note: presenter data is only kept in json format",
    TRANSLATE: "Translate",
    TRANSLATE_hint: "Translate subtitles into another language",
    Target_Language: "Target Language",
    CLEAR: "Clear",
    CLEAR_hint: "Clear all subtitles",
    CLEAR_confirm: "All subtitles will be cleared and that cannot be undone, continue?",
    UNDO: "Undo",
    UNDO_hint: "Undo the last subtitles change",
    HELP: "Help",
    HELP_hint: "Help info and contact support",
    PRESENTER: "Presenter",
    PRESENTER_hint: "View/Edit presenters",
    ACCOUNT: "Account",
    ACCOUNT_hint: "View/Edit account info",
    TASK: "Task",
    TASK_hint: "Submit current task to process and view task history",
    PRICING: "Pricing",
    PRICING_hint: "View pricing and subscribe to a paid plan",
    LANGUAGE: "Language",
    LANGUAGE_hint: "Switch user interface language",
    Save_Gif_hint: "Save images as an animated GIF file",
    My_Things: "My Things",
    Submit_Task: "Submit Task",
    Video_Effect: "Video Effect",
    Storybook: "Storybook",
    Transcription: "Transcription",
    Subtitles: "Subtitle-To-Speech",
    Add_Subtitle: "Add subtitle below",
    Delete_Subtitle: "Delete subtitle",
    Merge_Subtitle: "Merge with next subtitle",
    Shift_Subtitles: "Shift this subtitle and all others below to solve current conflict",
    Change_Image: "Change image",
    Move_Subtitle_Up: "Move subtitle up",
    Move_Subtitle_Down: "Move subtitle down",
    Fit_Subtitle: "Fit duration of current subtitle with its presenter",
    Toggle_Input_Type: "Toggle Input Type",
    Text: "Text",
    Audio: "Audio",
    Audio_Type: "Type",
    Audio_File: "File (<%{maxSize}MB)",
    OR: "or",
    SIGN_IN: "Sign in",
    SIGN_OUT: "Sign out",
    SIGN_UP: "Sign up",
    RESET_PASSWORD: "Reset Password",
    Go_sign_in: "Go sign in",
    Welcome_back: "Welcome back, %{user}",
    GENERIC_ERROR_MESSAGE: "Something went wrong! Please try again later.",
    ERROR_MESSAGE_UNKNOWN_REASON: "Unexpected exception - %{reason}",
    ERROR_MESSAGE_MISSING_REQUIRED_FIELD: "The value is required for %{field}.",
    ERROR_MESSAGE_NO_VALID_FILE_FOUND: "No valid file is found, please make sure selected files are valid media files and size/length within limit.",
    ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE: "File is an invalid media file or size is too large: %{filename}",
    ERROR_MESSAGE_FACE_NOT_DETECTED: "Face is not detected in the file: %{filename}",
    Submit: "Submit",
    Confirm: "Confirm",
    sending: "sending...please wait",
    Sign_in_with: "Sign in with", 
    Name: "Name",
    Message: "Message",
    Attach_Image: "Attach image",
    Email: "Email",
    Password: "Password",
    Display_Name: "Display Name",
    Coupon: "Coupon",
    Forgot_password: "Forgot your password?",
    Reset_it: "Reset it",
    No_account: "Don't have an account?",
    Sign_in_to_edit_presenter: "Please sign in to view/edit your presenters", 
    Sign_in_to_edit_account: "Please sign in to view/edit your account", 
    Sign_in_to_edit_task: "Please sign in to view your task history", 
    Sign_in_to_subscribe: "Please sign in to subscribe to this plan", 
    signin_required_after_session_expired: "Your session is expired, please sign in again.",
    send_password_reset_link_message: "If we have an account with the email address you enter, a password reset link will be sent to the address.",
    New_Password: "New Password",
    Retype_Password: "Retype Password",
    Passwords_not_match: "New Password and Retype Password don't match, please check.",
    Send_Password_Reset_Link: "Send Password Reset Link",
    Resend_Password_Reset_Link: "Resend Password Reset Link",
    Password_reset_message: "Password is reset, you can now sign in with the new password.",
    Password_reset_email_sent_message: "Password reset email is sent, please check your mailbox and click the link to reset your password within one hour. If you don't receive the email, please review if the provided email address is correct and check if it's in the spam folder.",
    Activate_Account: "Activate Account",
    Activate_account_email_sent_message: "Account activation email is sent, please follow the instructions to activate your account within one hour. If you don't receive the email, please review if the provided email address is correct and check if it's in the spam folder.",
    Send_Account_Activation_Link: "Send Account Activation Link",
    Resend_Account_Activation_Link: "Resend Account Activation Link",
    Account_activated_message: "Account is activated, you can now sign in with the new account.",
    Account_activation_link_sent_message: "Account activation link is sent, please check your mailbox and click the link to activate your account within one hour.",
    Go_home: "Go to Homepage",
    Go_Presenter: "Go to Presenter",
    Search_by_Name: "Search by Name",
    Action: "Action",
    New_Presenter: "New Presenter",
    Edit_Presenter: "Edit Presenter",
    Save: "Save",
    Edit: "Edit",
    Copy: "Copy to New",
    Update: "Update",
    Delete: "Delete",
    Change: "Change",
    Cancel: "Cancel",
    Show_per_page: "Show %{pageSize} per page",
    page: "page",
    of: "of",
    First: "First",
    Last: "Last",
    confirm_to_delete_presenter: "Are you sure to delete this presenter?",
    Number: "Number",
    Gender: "Gender",
    Language: "Language",
    Voice_Id: "Voice Id",
    Voice_Style: "Voice Style",
    Style_Degree: "Style Degree",
    Clone_Voice: "Clone Voice",
    Voice_To_Clone: "Voice to Clone",
    Override_Film_Mode: "Override Film Mode",
    Override_Film_Mode_tip: "Ingore Film Mode even if it's set, never attempt to find a person in the image to speak the text/audio.",
    Use_Avatar: "Use Avatar",
    Avatar_Image: "Avatar Image",
    Use_Moving_Head: "Use Moving Head",
    Avatar_Shape: "Avatar Shape",
    Avatar_Location: "Avatar Location",
    Toggle_Show_Legend: "Show/Hide Legend",
    Avatar_Height: "Avatar Height",
    Avatar_Height_extra: "(as a percentage of video height)",
    Hide_Avatar_When_Silent: "Hide Avatar When Silent",
    Presenter_Name_tip: "Enter a unique name for the presenter",
    Clone_Voice_tip: "Select a voice good for cloning to clone your voice",
    Voice_To_Clone_tip: "Upload your audio to clone (size limit: %{maxSize} MB). Use a short clip of clear speech from one person with no background music or noise.",
    Use_Avatar_tip: "Use lip-synced avatar to speak the provided text",
    Use_Moving_Head_tip: "Use slightly moving head to make the avatar's speaking look more natural",
    Avatar_Location_tip: "Relative location of the avatar, can take up more space than the designated cell if the avatar's height is greater than the cell",
    Avatar_Height_tip: "Relative height of the avatar, as a percentage of the video height",
    Hide_Avatar_When_Silent_tip: "Don't show the avatar when not speaking",
    custom_avatar_option: "*** custom avatar (headshot image) ***",
    ERROR_MESSAGE_MISSING_CUSTOM_VOICE: "Please provide a valid file for Voice to Clone or uncheck the Clone Voice.",
    ERROR_MESSAGE_MISSING_CUSTOM_AVATAR: "Please provide a valid file for Avatar Image or uncheck the Use Avatar.",
    legendOfAvatarLocation: "Legend of Avatar Location",
    male: "male",
    female: "female",
    clonable_1: "ok for cloning",
    clonable_2: "good for cloning",
    Voice_Sample: "Voice Sample",
    English: "English",
    Hindi: "Hindi",
    Arabic: "Arabic",
    Portuguese: "Portuguese",
    French: "French",
    Bulgarian: "Bulgarian",
    Czech: "Czech",
    Danish: "Danish",
    Estonian: "Estonian",
    Irish: "Irish",
    Spanish: "Spanish",
    Ukrainian: "Ukrainian",
    Chinese: "Chinese",
    Dutch: "Dutch",
    German: "German",
    Japanese: "Japanese",
    Turkish: "Turkish",
    Italian: "Italian",
    Ewondo: "Ewondo",
    Hausa: "Hausa",
    Lingala: "Lingala",
    Twi: "Twi",
    Yoruba: "Yoruba",
    Hungarian: "Hungarian",
    Greek: "Greek",
    Finnish: "Finnish",
    Polish: "Polish",
    Croatian: "Croatian",
    Lithuanian: "Lithuanian",
    Latvian: "Latvian",
    Maltese: "Maltese",
    Romanian: "Romanian",
    Slovak: "Slovak",
    Slovenian: "Slovenian",
    Swedish: "Swedish",
    Catalan: "Catalan",
    Persian: "Persian",
    Bengali: "Bengali",
    Russian: "Russian",
    Urdu: "Urdu",
    Indonesian: "Indonesian",
    Javanese: "Javanese",
    Telugu: "Telugu",
    Vietnamese: "Vietnamese",
    Marathi: "Marathi",
    Tamil: "Tamil",
    Gujarati: "Gujarati",
    Kannada: "Kannada",
    Maithili: "Maithili",
    Malayalam: "Malayalam",
    Burmese: "Burmese",
    Swahili: "Swahili",
    Sundanese: "Sundanese",
    Punjabi: "Punjabi",
    Thai: "Thai",
    Kurdish: "Kurdish",
    Malay: "Malay",
    Cebuano: "Cebuano",
    Tagalog: "Tagalog",
    Malagasy: "Malagasy",
    Chichewa: "Chichewa",
    Kinyarwanda: "Kinyarwanda",
    Somali: "Somali",
    Ilocano: "Ilocano",
    Kazakh: "Kazakh",
    Uyghur: "Uyghur",
    Haitian: "Haitian",
    Khmer: "Khmer",
    Shona: "Shona",
    Tatar: "Tatar",
    Armenian: "Armenian",
    Tigrinya: "Tigrinya",
    Turkmen: "Turkmen",
    Hebrew: "Hebrew",
    Tajik: "Tajik",
    Lao: "Lao",
    Bambara: "Bambara",
    Tsonga: "Tsonga",
    Mongolian: "Mongolian",
    Welsh: "Welsh",
    Korean: "Korean",
    Adjust_Audio_Properties: "Adjust Audio Properties",
    Pitch: "Pitch",
    Speed: "Speed",
    Volume: "Volume",
    GENDER_OPTIONS_: "Undefined",
    GENDER_OPTIONS_male: "Male",
    GENDER_OPTIONS_female: "Female",
    AVATAR_SHAPE_OPTIONS_original: "Original",
    AVATAR_SHAPE_OPTIONS_original_rembg: "Original with background removed",
    AVATAR_SHAPE_OPTIONS_circle: "Circle",
    AVATAR_SHAPE_OPTIONS_square: "Square",
    AVATAR_SHAPE_OPTIONS_hexagon: "Hexagon",
    AVATAR_SHAPE_OPTIONS_cloud: "Cloud",
    AUDIO_OPTIONS_driving: "driving",
    AUDIO_OPTIONS_background: "background",
    Output_Language: "Output Language",
    OUTPUT_LANGUAGE_OPTIONS_auto: "auto detect",
    OUTPUT_LANGUAGE_OPTIONS_English: "English",
    New_Task: "Submit Subtitle-To-Speech Task",
    New_Video_Effect_Task: "Submit Video Effect Task",
    New_Transcription_Task: "Submit Transcription Task",
    New_Storybook_Task: "Submit Storybook Task",
    Video_STS: "TTS for Video",
    Image_STS: "TTS for Document/Image",
    Sign_in_to_submit_task: "Please sign in to submit the task",
    Task_History: "Task History",
    Task_History_hint: "View task history and download results",
    Voice_Samples: "Voice Samples",
    Voice_Samples_hint: "View the list of predefined voices and listen to speech samples",
    No_results: "No result found for the selected criteria",
    View_YouTube_channel: "Watch more sample videos in our YouTube channel",
    Search_by_Number: "Search by Number",
    Download: "Download",
    Status: "Status",
    Submit_Time: "Submit Time",
    Start_Time: "Start Time",
    Finish_Time: "Finish Time",
    Result_Video: "Results",
    Media: "Media",
    Story: "Text",
    Output_Video_Length: "Output Video Length",
    seconds: "seconds",
    deleted: "deleted",
    confirm_to_delete_task_files: "Are you sure to delete files related to task number %{number}?",
    Save_task_info: "This is to create a task to submit the video and subtitles you are editing to the server for processing, and the result video will include synthesized speech from subtitle text using the settings in selected presenters, it can also contain lip-synced avatars if the presenters use avatars. For easily editing the subtitles again, it's recommended to save the subtitles to a local file in json format before submitting the task, which can be done using the Export function. \
    The server will use the latest settings in presenters when it starts to process your task, so don't update the pertinent presenters before the task is finished, otherwise you may get unexpected results. You will be notified by email about the outcome after your task is processed. You can also find the task in your task history, check its status and download the result if available.",
    Save_task_instruction: "There are a few options for the task: The 'Keep Existing Audio' option is to define whether or not to keep the audio when the input video contains audio. The 'Burn Subtitles' option is to embed subtitles into the video with selected font size. You can also limit the output length, for example, to produce a short video to see if the presenters' settings are all right, before generating the full length video. The 'Description' is to describe what the task is about for your own reference.",
    Save_video_effect_task_info: "This is to create a task to upload a video with the selected effect to the server for processing, which will apply a cartoon or filter effect to the whole video, with the original audio intact. The result video resolution is SD for free users, and HD for subscribers.",
    Save_video_effect_task_instruction: "Optionally, you can limit the output length, for example, to produce a short video to see if the selected effect is desired, before generating the full length video. The 'Description' is to describe what the task is about for your own reference.",
    Save_transcription_task_info: "This is to create a task to transcribe audio/video to text.",
    Save_transcription_task_instruction: "Optionally, you can limit the output length, for example, to transcribe a part of audio to see if the transcription is fine, before transcribing the full length audio. The 'Description' is to describe what the task is about for your own reference.",
    Save_video_STS_task_info: "This is to create a task to submit the video and subtitles you are editing to the server for processing, and the result video will include synthesized speech from subtitle text using the settings in selected presenters, it can also contain lip-synced avatars if the presenters use avatars.",
    Save_image_STS_task_info: "This is to create a task to submit the document/images and subtitles you are editing to the server for processing, and the result video will include supplied audio or synthesized speech from subtitle text using the settings in selected presenters, it can also contain lip-synced avatars if the presenters use avatars.",
    Save_task_info_extra: "You will be notified by email about the outcome after your task is processed. You can also find the task in your task history, check its status and download the result if available.",
    Go_Task_History: "Go to Task History",
    Keep_Natural_Speed_of_Video_or_Speech: "Keep Natural Speed of Video or Speech",
    Adjust_speech_speed_to_fit_video: "Adjust speech speed to fit video",
    Adjust_video_speed_to_fit_speech: "Adjust video speed to fit speech",
    Keep_Existing_Audio: "Keep Existing Audio",
    Audio_Stems_to_Keep: "Audio Stems to Keep",
    Suppress_Underlying_Audio: "Suppress Underlying Audio",
    Use_Film_Mode: "Use Film Mode",
    Animate_Still_Images: "Animate Still Images",
    Burn_Subtitles: "Burn Subtitles",
    Font_Size: "Font Size",
    sample_text: "sample text",
    Output_Length: "Output Length",
    Duration_To_Transcribe: "Duration To Transcribe",
    Description: "Description",
    To_apply_effect: "To apply effect %{effect} to %{filename}",
    To_transcribe: "To transcribe %{filename}",
    Select_Video_File: "Select Video File (size limit: %{maxSize} MB)",
    Select_Effect: "Select Effect",
    Sample_Effect: "Sample Effect (before, after)",
    Color1: "Color 1",
    Color2: "Color 2",
    Intensity: "Intensity",
    Color1_tip: "Color for the first tone",
    Color2_tip: "Color for the second tone. If it's different from the first color, a gradient between the two colors will be overlayed to the image, otherwise just the color is overlayed.",
    Intensity_tip: "Color intensity in percentage",
    ERROR_MESSAGE_MISSING_VIDEO_FILE: "No video is found, please open a video file.",
    ERROR_MESSAGE_MISSING_IMAGE_FILE: "No image is found, please open a document/image file.",
    ERROR_MESSAGE_MISSING_SUBTITLES: "The subtitle is invalid, it should have at least one item.",
    ERROR_MESSAGE_INVALID_OUTPUT_LENGTH: "The output length is invalid, it should be between %{minLength} and %{maxLength}.",
    Select_Media_File: "Select Audio/Video File (size limit: %{maxSize} MB)",
    Output_Format: "Output Format",
    ERROR_MESSAGE_MISSING_MEDIA_FILE: "No media is found, please open an audio/video file.",
    Keep_Existing_Audio_tip: "Keep the audio of the input video if any",
    Audio_Stems_to_Keep_tip: "Keep the selected stem if the audio has multiple stems",
    Suppress_Underlying_Audio_tip: "Suppress existing audio whenever a presenter is speaking",
    Use_Film_Mode_tip: "Find a person in every image to speak the corresponding text/audio if possible, unless this setting is overridden by the Presenter.",
    Animate_Still_Images_tip: "Use Ken Burns panning and zooming effect to animate images that don't contain speaking avatars",
    Burn_Subtitles_tip: "Embed subtitles into the video with selected font size",
    Output_Length_tip: "The desired length of the result video. Reduce the length to produce a short video as a preview.",
    Duration_To_Transcribe_tip: "The desired duration to transcribe. Set a short duration to produce a partial transcription as a preview.",
    Storybook_Output_Length_tip: "The desired length of the result video. This just serves as a guidance, the actual length can be very different depending on the chatbot's output and the speaking speed of the selected presenter, which will be updated when the video is produced.",
    Image_STS_Output_Length_tip: "The desired length of the result video. The maximum length is an estimate by the text length and the speaking speed of the selected presenters, the actual length will be updated when the video is produced.",
    Description_tip: "Describe what the task is about for your own reference",
    Picture_Style_tip: "A picture is generated per sentence of the story, select a preset or custom style to generate the pictures.",
    Select_Presenter_tip: "Select a defined presenter to tell the story",
    Picture_Resolution: "Resolution of Generated Pictures",
    Picture_Resolution_tip: "Resolution of generated pictures, options include the recommended video resolutions of popular social medias",
    Generic_Topics: "Generic Topics",
    Howto_Videos: "How-to Videos",
    AUDIO_STEMS_OPTIONS_all: "All",
    AUDIO_STEMS_OPTIONS_vocals: "Vocals",
    AUDIO_STEMS_OPTIONS_instruments: "Instruments",
    Quick_Edits: "Quick Edits",
    Save_quick_edits_task_info: "This is to create a task to run quick edits of audio or video using the provided inputs. It's free to use without output length limits or watermarks.",
    Audio_Stems_to_Remove: "Audio Stems to Remove",
    Audio_Stems_to_Remove_tip: "Remove the selected stem if the audio has multiple stems",
    Select_New_Audio_File: "Select New Audio File",
    Speed_Adjustment: "Speed Adjustment",
    ERROR_MESSAGE_MISSING_NEW_AUDIO_FILE: "Please select the new audio file.",
    QUICK_EDITS_ACTION_OPTIONS_removeAudioStems: "Remove Audio Stems",
    QUICK_EDITS_ACTION_OPTIONS_removeAudioFromVideo: "Remove Audio from Video",
    QUICK_EDITS_ACTION_OPTIONS_addAudioToVideo: "Add Audio to Video",
    ADJUST_SPEED_OPTIONS_0: "No change",
    ADJUST_SPEED_OPTIONS_1: "Adjust audio speed to fit video length",
    ADJUST_SPEED_OPTIONS_2: "Adjust video speed to fit audio length",
    FAQ_title: "Frequently Asked Questions",
    FAQ1_question: "What can I do with VideoPlus Studio?",
    FAQ1_answer: "VideoPlus Studio simplifies video transformation with AI and lowers the barrier to creative video production. You can use it to apply different cartoon styles and filters to videos, generate video storybooks, as well as transcribe audio to text with automatically detected language and save the text to a subtitle file. Moreover, you can use it to add subtitles to videos, documents and images, and turn them into speeches in many languages. \
    A presenter can be selected for each subtitle, the presenter has properties for turning the text into speeches in certain language and voice, and an image (optional) for generating a lip-synced avatar to speak the text; the avatar has properties like shape, size and location to control how it's going to show in the video. See some sample videos and use cases on the <a href='/'>homepage</a>.",
    FAQ2_question: "So how do I start?",
    FAQ2_answer: "To perform some simple tasks such as applying special effects to videos, generating storybooks, and transcribing audios, you can find the menu items in the main menu, fill in a few fields and submit the task to process. To perform text-to-speech (TTS) tasks, you also need to define presenters and edit subtitles.\
    <ul><li>Task: A Task is a user object containing data for processing, such as the video and its subtitles, options to burn subtitles and limit the output length.</li> \
    <li>Subtitles: Subtitles are text representing the contents of the audio in a video, they must include information on when each line of text should appear and disappear. Subtitle editing for a video is based on timeline, where you specify the start and end time for every Subtitle. When working with a document or an image, a subtitle is set for each page/frame, its start and end time is calculated automatically by the actual time it takes to speak the text.</li> \
    <li>Presenter: The Subtitle has a Presenter property, that's used to turn text into speech. A Presenter is a user defined object that has a name and voice, optionally an avatar image of certain shape, size and location in the resulting video. By default each user has 2 preset presenters that can be modified. Presenters are your AI aides that speak your ideas.</li> \
    </ul>Start a new TTS task by opening a video/document/image file, then type in subtitles or open an existing file containing subtitles to edit. The app will try to extract text on each page when you open a document. For every subtitle select a presenter, adjust the text and the starting and ending position. Once you are done with editing, click the 'Submit Task' button in the top toolbar to submit the task to process. Download link to the resulting video can be found in the task history when available.",
    FAQ3_question: "How do I get the processed video? And is there a way to preview the result?",
    FAQ3_answer: "Once you submit a task, it'll be put in a queue to process. After your task is processed, you'll be notified by email about the outcome, then you can find the task on the <a href='/TaskHistory'>Task History</a> page, use the download button to download the resulting video. For a TTS task you'll know where each subtitle appears, as the subtitle text is synced with the video/document/image player, and the text is overlaid on the player at the appropriate location; \
    the speeches and lip-synced avatars need server power to produce, also the video can be lengthy, so there is no simple way to preview the final product, but you can listen to a sample for each voice on the <a href='/VoiceSamples'>Voice Samples</a> page, and submit a short TTS task using the presenter to test if your text sounds right with the selected voice and if your avatar can lip-sync properly. \
    To make sure the presenters work as expected, it's recommended to first submit a short task with limited output length, for example, several seconds, before submitting a task of full length. Once you get the result video, review it and adjust the subtitles and/or presenters if needed, repeat this process until you are satisfied.",
    FAQ4_question: "I have subtitles in one language, can I add them to my video in a different language?",
    FAQ4_answer: "Yes, you can translate the subtitles to a different language using the 'Translate' button in the top toolbar. The app supports %{count_languages} languages and %{count_voices} voices of varied genders and accents, you just need to define presenters with certain voices and use them in accordance with the language of subtitles. You can also clone your voice based on any predefined voice when defining a presenter.",
    FAQ5_question: "Tips about subtitle editing and different formats",
    FAQ5_answer: "The length limit of each subtitle text is %{max_text_size1} characters on the <a href='/VideoSubtitleToSpeech'>TTS for Video</a> page, and %{max_text_size2} characters on the <a href='/ImageSubtitleToSpeech'>TTS for Document/Image</a> page. You'll have to split longer text into multiple subtitles. In the top toolbar you can find a 'Undo' button to undo the last edit and a 'Clear' button to clear all subtitles, and you can always open a saved subtitle file to start over. In the subtitle editor you can find buttons to add/delete/merge subtitle, \
    a presenter select list and a button to fit duration of the subtitle with its presenter, the auto duration adjustment also happens when you switch presenter or edit the text. The auto duration adjustment is done using a simple statistics of the presenter, that allocates enough duration for the text to be spoken at the presenter's normal speaking speed (a shorter duration may lead to a faster speech), but you can change as appropriate. The subtitles can be exported to files in \
    several formats, the json format is specific to this app with presenter information, and other formats can be used in desktop or online video players such as YouTube. It's a good habit to save current subtitles to a json file before starting a new task or submitting current task, so that you can reload the subtitles to make adjustments if needed.",
    FAQ6_question: "About subscription plans",
    FAQ6_answer: "The main difference between various subscription plans (free or paid) are the number of presenters you can create and the total length of videos that you can submit for processing, see details on the <a href='/Pricing'>Pricing</a> page, where you can subscribe to a paid plan if you haven't done so. The balance of your number of presenters and length of video is shown in the 'Available Credits' section on the <a href='/Account'>Account</a> page.",
    FAQ11_question: "What can I do with VideoPlus Studio?",
    FAQ11_answer: "VideoPlus Studio simplifies video transformation with AI and lowers the barrier to creative video production. You can use it to apply different cartoon styles and filters to videos, generate video storybooks, as well as transcribe audio to text with automatically detected language and save the text to a subtitle file. Moreover, you can use it to add subtitles to videos, documents and images, and turn them into speeches in many languages. \
    A presenter can be selected for each subtitle, the presenter has properties for turning the text into speeches in certain language and voice, and an image (optional) for generating a lip-synced avatar to speak the text; the avatar has properties like shape, size and location to control how it's going to show in the video.",
    FAQ12_question: "Can I use VideoPlus Studio for free?",
    FAQ12_answer: "Yes, it's totally free to create/edit/translate/export subtitles. Besides, VideoPlus Studio has a free tier that allows users to explore all features with limited quota, for example, 30 seconds of video per day. However, paid subscriptions can give you much higher quotas per month.",
    FAQ13_question: "How does this app differ from other video editors on the market?",
    FAQ13_answer: "VideoPlus Studio stands out by combining subtitle editing with text to speech to make it easy to add voiceover to your videos at the precise locations. Along with the presenter per subtitle, audio transcription and text translation features, you can do multi-person, multilingual voiceover via subtitles and dub a movie all by yourself. \
    The same capacity can also be used for documents and images, so that you can generate videos to vividly present your ideas with AI voices and avatars in various languages.",
    FAQ14_question: "How many languages, voices and avatars do you support?",
    FAQ14_answer: "We currently support 80 languages, 458 predefined voices, and 528 predefined avatars. Plus you can use voices good for cloning to clone new voices, and custom avatars can be made with any headshot photos.",
    FAQ15_question: "Do I need special hardware or software to use the app?",
    FAQ15_answer: "No, VideoPlus Studio is fully web-based, you only need a device with internet access and a browser to use it, no installation is needed. When you submit a task, our servers will be hard at work to process it and notify you once it's done.",
    FAQ16_question: "Do I need prior video editing experience to use this app?",
    FAQ16_answer: "No, prior video editing experience is not required to use this app. Our intuitive and user-friendly interface allows anyone to create content, and we also have tutorial and how-to videos to show you the basic subtitle editing techniques and guide you through some typical use cases.",
    FAQ17_question: "What are the payment options?",
    FAQ17_answer: "We use PayPal to handle subscriptions securely. You can pay using your PayPal account or any supported debit and credit card.",
    FAQ18_question: "What if I only need to use the app for a short period?",
    FAQ18_answer: "We offer simple monthly subscriptions that will be renewed automatically until cancelled or paused. If you have a PayPal account, you can log into the account to cancel or pause your subscription at any time; or else contact us for assistance.",
    FAQ30_question: "About complementary aiTransformer plan for paid VideoPlus Studio subscriptions",
    FAQ30_answer: "During a promotional period, a user subscribed to a paid VideoPlus Studio plan will be granted access to the services of the aiTransformer plan of the same type, with the same active period. For example, if you subscribe to a VideoPlus Studio Pro plan, you will be able to use aiTransformer tools to produce results in accordance to an aiTransformer Pro plan, that will be active as long as your VideoPlus Studio plan is active. \
    To enjoy the complementary aiTransformer plan, you need to sign in to the aiTransformer app with the same email you used for the VideoPlus Studio plan.",
    Questions_suggestions: "More questions or suggestions?",
    Contact_Support: "Contact Support",
    Privacy_Policy: "Privacy Policy",
    Terms_of_Service: "Terms of Service",
    SUPPORT: "Support",
    Support_info: "If you have any questions or suggestions, please contact us by Email at support@videoplus.studio or the support form below, we'll get back to you as soon as possible.",
    ERROR_MESSAGE_FILE_INVALID_AND_NOT_TO_BE_INCLUDED: "The file %{name} is an invalid media file or size is too large and won't be included for submission.",
    Subscription_Plan: "Subscription Plan",
    Available_Credits: "Available Credits (reset in %{num} %{unit})",
    Other_Tasks: "Other Tasks",
    Extra_Credits: "Extra Credits",
    current: "current",
    next: "next",
    days: "days",
    hours: "hours",
    minutes: "minutes",
    day: "day",
    month: "month",
    edit_subtitles: "create/edit/translate/export subtitles",
    total_presenters: "total number of presenters: %{num}",
    total_video_length_for_transcription: "total video length for transcription: %{num} minutes / %{duration}",
    total_video_length_for_other_tasks: "total video length for other tasks: %{num} minutes / %{duration}",
    results_with_logo: "aiTransformer logo added in the processed videos",
    results_without_logo: "no logo added in the processed videos",
    results_kept_for_one_day: "result files are kept for one day",
    results_kept_for_ten_days: "result files are kept for ten days",
    Pricing_description: "Free to try, upgrade to a paid plan if you need a higher quota; simple monthly subscription, no need to commit for long term and cancel at any time",
    Select_Plan: "Select Plan",
    login_and_subscribe: "Please sign in then subscribe to the plan!",
    current_plan: "This is your current plan",
    next_plan: "This is your next plan",
    Subscription_Plan_note: "* Note: If you don't find a plan meeting your needs, please contact us to discuss other options. If you have subscribed to a plan and wish to cancel it, you must do so via your PayPal account to stop the recurring payment (see <a href='https://www.wikihow.com/Cancel-a-PayPal-Subscription' target='_blank' rel='noreferrer'>this post</a> for step by step instructions). \
    It is important to bear in mind that all payments are final and non-refundable, so if you cancel your subscription, it will continue to be active until the current subscription period ends. In the event that you wish to switch to a different plan, you must first cancel the existing plan, wait until the subscription period has ended, and then subscribe to the new plan. If you require an alternative approach, please do not hesitate to contact us for further assistance.",
    Subscription_successful: "Your subscription is successful, please sign out and then sign in to enjoy the new plan.",
    Subscription_bonus: "Subscribe to a paid plan now you will also get an aiTransformer paid plan for free.",
    slide_num: "slide %{num}",
    Help_videos_info: "Welcome to VideoPlus Studio! Following how-to videos show the typical usages of this app. In fact, these videos themselves are explainer videos created using this app.",
    Show_initial_screen: "Show this as the initial screen",
    Save_storybook_task_info: "This is to create a task to generate a video storybook using the provided inputs.",
    Story_Generation_Method: "Story Generation Method",
    Custom_Generation_Method_hint: "Enter your story lines to generate voice and prompts enclosed with [] to generate pictures. Sentences should end with period (.). A picture will be generated per sentence, by default the sentence will be used as the prompt, but if you'd like to use a custom prompt (enclosed with []), put it before the end of the sentence, for example, sentence 1 [picture prompt 1]. sentence 2 [picture prompt 2].",
    Story_Genre: "Story Genre",
    Picture_Style: "Style of Generated Pictures",
    Style_Sample: "Style Sample (prompt: Santa is coming to town)",
    Custom_Style_hint: "Enter the text for custom style (weights supported). You can specify where to put the prompt to be stylized with a placeholder [prompt], e.g. breathtaking [prompt] by Anna Dittmann. If no placeholder is present, the style text will be appended to the prompt.",
    Storyteller_Properties: "Storyteller Properties",
    ERROR_MESSAGE_MISSING_PROMPT: "Generation Method error: please enter a prompt.",
    ERROR_MESSAGE_MISSING_OR_INVALID_URL: "Generation Method error: please enter a valid link to a webpage.",
    ERROR_MESSAGE_MISSING_DOCUMENT_FILE: "Generation Method error: please select a document file.",
    ERROR_MESSAGE_MISSING_CUSTOM_STORY: "Generation Method error: please enter your story.",
    GENERATION_METHOD_OPTIONS_prompt: "Generate story from a prompt (enter the prompt)",
    GENERATION_METHOD_OPTIONS_url: "Generate story based on the contents in a web page (paste the URL)",
    GENERATION_METHOD_OPTIONS_document: "Generate story based on the contents in a document (upload the document, max size: 10 MB)",
    GENERATION_METHOD_OPTIONS_custom: "Generate voice with supplied text and pictures with custom prompts (enter the custom story)",
    STORY_GENRE_OPTIONS_: "undefined",
    "STORY_GENRE_OPTIONS_picture books": "picture books (children ages 0-5)",
    "STORY_GENRE_OPTIONS_early chapter books": "early chapter books (children ages 6-8)",
    "STORY_GENRE_OPTIONS_middle grade novels": "middle grade novels (children ages 9-12)",
    "STORY_GENRE_OPTIONS_young adult fiction": "young adult fiction",
    "STORY_GENRE_OPTIONS_realist literature": "realist literature",
    "STORY_GENRE_OPTIONS_literary fiction": "literary fiction",
    "STORY_GENRE_OPTIONS_science fiction": "science fiction",
    STORY_GENRE_OPTIONS_fantasy: "fantasy",
    "STORY_GENRE_OPTIONS_mystery/thriller": "mystery/thriller",
    STORY_GENRE_OPTIONS_romance: "romance",
    STORY_GENRE_OPTIONS_horror: "horror",
    "STORY_GENRE_OPTIONS_historical fiction": "historical fiction",
    STORY_GENRE_OPTIONS_adventure: "adventure",
    STORY_GENRE_OPTIONS_drama: "drama",
    "STORY_GENRE_OPTIONS_comedy/satire": "comedy/satire",
    "STORY_GENRE_OPTIONS_TL;DR": "TL;DR",
    "STORY_GENRE_OPTIONS_first person": "first person",
    STORY_OUTPUT_OPTIONS_video: "Video and story text",
    STORY_OUTPUT_OPTIONS_gif: "GIF image and subtitle json (suitable as input of TTS for Document/Image)",
    Select_Presenter: "Select Presenter",
    default_storybook_description: "To generate storybook with method: %{method}, genre: %{genre}, style: %{style}",
    Videoplus_Studio_headline0: "Free subtitle editor & translator for videos/documents/images. Subtitle to speech and text to video with customizable voices and avatars for lifelike presentations.",
    Videoplus_Studio_headline: "Make your videos/documents/images speak in 80 languages via subtitles",
    Videooplus_Studio_button_text: "Try for free",
    Videooplus_Studio_specials: "<strong>VideoPlus Studio also supports video effects like cartoon styles and filters, as explained in the how-to videos. Try it now totally free! Just sign in to <a href='https://videoplus.studio' target='_blank'>VideoPlus Studio</a>, open the Account window and update your account with the coupon code 'feb24zeniteq', you'll get one month free of the Lite plan, enough for generating 125 minutes of videos. This coupon is valid during February 2024.</strong>",
    Videoplus_Studio_subline: "Produce Engaging Videos In-House with Subtitle Editing – No Voice-Over Hassles!",
    Videoplus_Studio_highlights: "supports %{count_languages} languages, %{count_voices} predefined voices + voice cloning, %{count_avatars} predefined avatars + custom avatars",
    Videoplus_Studio_highlights_1: " languages, ",
    Videoplus_Studio_highlights_2: " predefined voices + voice cloning, ",
    Videoplus_Studio_highlights_3: " predefined avatars + custom avatars",
    Videooplus_Studio_Tutorial: "VideoPlus Studio Tutorial",
    Videooplus_Studio_demo_video: "VideoPlus Studio demo video",
    Product_Service_Demos: "Product & Service Demos",
    Product_Service_Demos_description: "Perfect tool for demonstrating software products and creating explainer videos for online services - just record the screen showcasing how the software or service works, then add presenters (voice + avatar) to the screen recording, like how we created the aiTransformer tutorial videos in English, French and Spanish, presented by a group of avatars of varied genders and accents at different locations.",
    Sales_Marketing: "Sales & Marketing",
    Sales_Marketing_description: "Revolutionize virtual sales and marketing by offering a unique personal touch – users can incorporate their own pictures as avatars in videos. This feature adds authenticity, fostering stronger connections with the audience. Sales professionals can humanize pitches, while marketers can enhance brand authenticity by showcasing team members.",
    Podcast_Helper: "Podcast Helper",
    Podcast_Helper_description: "Transform the podcasting experience by enabling creators to incorporate AI aides. The ability to add AI-presenters with diverse voices and avatars not only adds a unique flair to the podcast but also keeps the audience captivated. Podcasters can utilize this feature to simulate conversations, introduce topics, or even provide additional insights, making their podcasts more entertaining and informative.",
    Video_Transformer: "Video Transformer",
    Video_Transformer_description: "Transform videos with special cartoon and filter effects, apply the effect frame by frame while keeping the auto intact, resulting in consistent style transfer every time.",
    Storybook_Maker: "Storybook Maker",
    Storybook_Maker_description: "Automate video storybook creation by combining large language model (LLM), image generation, and text-to-speech (TTS) technologies. Starting from simple inputs like a prompt, URL or document, it generates a story and accompanying pictures, assembles them into a video with a personalized presenter telling the story using a voice you choose.",
    Multilingual_More: "Multilingual & More",
    Multilingual_More_description: "Expand audience reach by translating subtitles into different languages and presenting content in a language other than the original, effectively breaking language barriers and reaching a global audience. Whether for business presentations, educational materials, or creative content, VideoPlus Studio's unique features enhance accessibility and ensure a truly immersive multilingual experience for viewers.",
    More_Use_Cases: "More Use Cases",
    Use_Case1_title: "Tutorial Reinvention",
    Use_Case1: "Repurpose existing video tutorials by adding new commentary, insights, or explanations in different languages. Enhance educational content without reshooting the entire video.",
    Use_Case2_title: "Narrative Exploration",
    Use_Case2: "Create narrative-driven content by using diverse voices and avatars to explain complex concepts or tell stories. This is particularly useful for educational channels or businesses aiming to simplify intricate topics.",
    Use_Case3_title: "Virtual Product Demos",
    Use_Case3: "Develop virtual product demonstrations without being on camera. Presenters can articulate product features, benefits, and usage, making it ideal for businesses in e-commerce or tech industries.",
    Use_Case4_title: "Language Learning Support",
    Use_Case4: "Facilitate language learning by providing video content with translated subtitles and corresponding spoken language, providing a new way of learning for language educators and learners.",
    Use_Case5_title: "Regular Podcast Enhancements",
    Use_Case5: "Continuously enhance podcasts by integrating AI-presenters to introduce segments, provide updates, or engage in conversations. This brings variety to the podcast format and keeps the audience engaged.",
    Use_Case6_title: "Content Localization & Consistent Branding",
    Use_Case6: "Regularly localize content by translating subtitles and adapting the spoken language to reach diverse audiences. Ensure consistent branding across different language markets by using VideoPlus Studio to create promotional videos with translated subtitles and spoken content.",
    Use_Case7_title: "Regular Sales Pitches",
    Use_Case7: "Sales teams can use VideoPlus Studio for creating regular, personalized sales pitches. The ability to add diverse avatars and voices adds a unique touch to each pitch.",
    Use_Case8_title: "Business Presentations and Proposals",
    Use_Case8: "Enhance business documents and presentations by adding voiceovers and avatars. This feature can make sales pitches, training materials, and client proposals more engaging and memorable, capturing the audience's attention and conveying information effectively.",
    Use_Case9_title: "Educational Materials and Tutorials",
    Use_Case9: "Transform educational documents like PowerPoint presentations, PDFs, or HTML content into interactive tutorials. Users can add voiceovers and avatars to explain complex concepts, making learning more accessible and engaging for students of all ages.",
    Use_Case10_title: "Cultural and Historical Exhibits",
    Use_Case10: "Develop interactive exhibits or presentations showcasing cultural and historical content. Use text documents and images to create informative displays, adding avatars to narrate stories, share insights, or provide commentary, enhancing the visitor's experience.",
    Use_Case11_title: "Interactive Greeting Cards",
    Use_Case11: "Create personalized greeting cards where each page features a dynamic avatar delivering a customized message. Users can upload their designs or choose from templates, adding a unique touch to special occasions like birthdays, anniversaries, or holidays.",
    Use_Case12_title: "Engaging Storybooks for Children",
    Use_Case12: "Develop interactive storybooks where each page comes alive with a talking avatar narrating the story. This feature can enhance children's engagement with storytelling, making learning more fun and interactive.",
    Use_Case13_title: "Virtual Tours and Guides",
    Use_Case13: "Create interactive virtual tours or guides using images and text documents. Add a presenter to guide viewers through each section, providing information, explanations, or commentary to enhance the viewer's experience and understanding.",
    Use_Case14_title: "Personalized Multimedia Projects",
    Use_Case14: "Combine text, images, and voiceovers to create personalized multimedia projects such as portfolios, showcases, or digital scrapbooks. Add avatars to narrate content, share insights, or provide commentary, making the project more engaging and interactive.",
    Use_Case15_title: "Multilingual Transcription and Movie Dubbing",
    Use_Case15: "Transcribe audio/video with auto language detection, facilitating efficient localization and translation. Dub movies into multiple languages effortlessly as each subtitle can have its own presenter, allowing for seamless voice-over in different languages. Ideal for content creators seeking global distribution and audience engagement.",
    Use_Case16_title: "Video Style Transfer with Cartoon and Filter Effects",
    Use_Case16: "Enhance videos with consistent cartoon and filter effects while preserving the original contents. Apply special effects frame by frame to maintain visual coherence throughout the video. Ideal for video production teams seeking to elevate their footage with creative enhancements without compromising its integrity.",
    Tutorials: "Tutorials",
    tutorial1_title: "How to apply cartoon and filter effects to videos",
    tutorial1_summary: "<ol><li>Click the 'Video Effect' button in the menu.</li> \
    <li>Select your video, and a cartoon or filter effect you like.</li> \
    <li>Click 'Submit' and record the task number, then wait until you get an email about the outcome of the task, or check in the task history for its status, check back if not ready.</li> \
    <li>Find the task and download the resulting video file when available. Your video will be applied frame by frame with the selected effect, while the original audio is kept intact.</li></ol>",
    tutorial2_title: "How to transcribe audios/videos",
    tutorial2_summary: "<ol><li>Click the 'Transcription' button in the menu.</li> \
    <li>Select your audio/video, and the output language and format.</li> \
    <li>Click 'Submit' and record the task number, then wait until you get an email about the outcome of the task, or check in the task history for its status, check back if not ready.</li> \
    <li>Find the task and download the resulting text file when available.</li> \
    <li>If you selected a video, the audio in the video will be used to transcribe to text. If you selected srt or vtt output format, the resulting file will be a subtitle file ready for editing in TTS for video or document/image.</li></ol>",
    tutorial3_title: "How to make dynamic explainer videos from recordings without voice",
    tutorial3_summary: "Suppose you learned some interesting tricks and want to create an explainer video to share with the world, you'd like to put yourself in the video but you're uncomfortable speaking in front of a camera, VideoPlus Studio can solve the dilemma.<br/> \
    If your story happens in the real world, like an event, making a dish, repairing a tool, etc., just use a camcorder or your phone to record what's happening; if it's something in the virtual world, you can easily find a screen recording tool to record what you do on the screen. \
    Once you have the recording, save it as a MP4 video. While recording you don't have to decide what to talk about, because later you can open the video in VideoPlus Studio, play it and write down what you want to talk as subtitles according to the scene. \
    In the app you can define a presenter with a voice you like, clone your own voice if desired, and upload your image for the presenter's avatar; then select the presenter for your subtitles, the app will turn the subtitles into speech spoken by the avatar and add these to the video, \
    resulting in a video presented by you even if the you are not in the original video at all.<br/> \
    Learn how to create an explainer video about learning ComfyUI - a fancy tool to generate images using Stable Diffusion in <a href='%{post_url}' target='_blank'>this step-by-step tutorial</a>.",
    tutorial4_title: "How to turn documents into dynamic presentations",
    tutorial4_summary: "Can you turn a plain old PowerPoint presentation into an interesting online course? Let's try the following: \
    <ol><li>Use an attractive AI voice to narrate the contents;</li> \
    <li>Add some sound effects;</li> \
    <li>Ask a chatbot to generate some fun question/answer pairs according to the contents;</li> \
    <li>Add question/answer slides presented by avatars;</li> \
    <li>Assemble everything together and generate a video.</li></ol> \
    Learn how to turn your static content into dynamic presentations without the need for voice recording in <a href='%{post_url}' target='_blank'>this step-by-step tutorial</a>.",
    tutorial5_title: "How to dub a movie all by yourself",
    tutorial5_summary: "Movie dubbing is not a simple task, it usually requires a team of professionals for translation, voiceover recording, sound mixing and editing, etc. VideoPlus Studio can detect the speaking language of the audio in a video, transcribe the audio to text and save it to a subtitle file. \
    Along with a translation utility and a flexible subtitle editor that can define a presenter for every line of the subtitles, you can dub a movie all by yourself - the process closely resembles how it's done professionally, but without the hassle of using real voice actors, recording, etc. \
    <br/>Learn how to dub the fictive 'Back To The Future 4' movie trailer in English to French in <a href='%{post_url}' target='_blank'>this step-by-step tutorial</a>.",
    tutorial6_title: "How to combine pictures and words into story-telling videos",
    tutorial6_summary: "\"A picture is worth a thousand words.\" Pictures and words can both tell stories, but combining them into a video can tell a more vivid story and bring static contents to life. We all like storybooks as kids. Words with pictures are more appealing and easy to recall. \
    Now with generative AI, pictures can be generated from text, and text can be turned into speech spoken by an avatar. <br/>Learn how to transform an article into a dynamic story-telling video in <a href='%{post_url}' target='_blank'>this step-by-step tutorial</a>.",
    tutorial7_title: "Crafting personalized stories with AI: a look into the multimodal Storybook maker",
    tutorial7_summary: "The art of storytelling has evolved throughout history, from oral traditions to written narratives, and now into the digital age. The Storybook Maker is a tool seen as the next step in this progression. It was developed with the aim of automating the creation of personalized stories, \
    incorporating multimodal learning to enhance the storytelling experience. <br/>Learn the intricacies and tricks of the Storybook tool in <a href='%{post_url}' target='_blank'>this deep dive article</a>.",
    Mobile_Apps: "Mobile Apps",
    Mobile_Apps_info: "We've also developed some mobile apps as follows. These apps are free to use without any cost or restrictions. No user data collection, no logins, your creations are yours to keep, without any branding. We hope you enjoy them, and your feedback is welcome!",
    OfflineTextRecognizer: "Offline Text Recognizer",
    OfflineTextRecognizer_description: "Effortlessly extract text from images, even without an internet connection",
    RussianBlock: "Russian Block",
    RussianBlock_description: "Rediscover the classic fun of Tetris in endless mode",
    aiTransformerBackgroundEditor: "aiTransformer Background Editor",
    aiTransformerBackgroundEditor_description: "Easily remove image background, get creative replacing background with a new one",
    aiTransformerCartoonizer: "aiTransformer Cartoonizer",
    aiTransformerCartoonizer_description: "Transform real-world pictures into cartoon-style images",
    aiTransformerEnhancer: "aiTransformer Enhancer",
    aiTransformerEnhancer_description: "Enhance photos for a clearer, sharper and more professional look",
    aiTransformerFilter: "aiTransformer Filter",
    aiTransformerFilter_description: "Add some flair to your photos with 50+ filters, including Sepia, Emboss and more",
    aiTransformerMultiStylizer: "aiTransformer MultiStylizer",
    aiTransformerMultiStylizer_description: "A creative approach to image styling: applying multiple styles to a single image",
    aiTransformerSketcher: "aiTransformer Sketcher",
    aiTransformerSketcher_description: "Create different styles of sketches from pictures, simulating hand drawings",
    music_video_info: "With help of the VideoPlus Studio app, following is a music video created using some pictures transformed with aiTransformer tools, with a generated song about those tools added.",
};

const es = {
    VideoPlus_Studio: "VideoPlus Studio",
    Copyright: "© 2024 aiTransformer Technologies. Todos los derechos reservados.",
    PageNotFound: "Lo sentimos, la página que estás intentando visitar no existe.",
    app_headline0: "Simplifique la transformación de vídeo con IA y reduzca la barrera a la producción de vídeo creativo",
    app_headline1: "Mejora tu creación de videos con IA",
    app_headline2: "Transforma vídeos con efectos especiales. Generar libros de cuentos en vídeo. Haz que tus vídeos/documentos/imágenes hablen en 80 idiomas mediante subtítulos.",
    app_highlight1: "Transforma vídeos con efectos especiales como dibujos animados",
    app_highlight2: "Genera libros de cuentos en vídeo de diversos géneros y estilos.",
    app_highlight3: "Transcribe audios/videos con detección automática de idioma",
    app_highlight4: "Haz que vídeos/documentos/imágenes hablen en 80 idiomas mediante subtítulos",
    app_cta_headline1: "Mejora tu creación de videos con VideoPlus Studio",
    app_cta_headline2: "Comience con créditos gratuitos diarios",
    app_cta1: "Empieza gratis",
    app_cta2: "Pruebalo ahora",
    TTS: "Texto a voz",
    Samples: "Muestras",
    Use_Cases: "Casos de uso",
    Special_Use_Case: "Últimos vídeos de muestra de nuestro canal de YouTube",
    Brands: "Socios / Clientes",
    Contact_Us: "Contacta con nosotros",
    Mission: "Hacer que la IA funcione para todos",
    Quick_Links: "Enlaces rápidos",
    Follow_Us: "Siga con nosotros",
    HOME: "Principal",
    LOADING: "Cargando...",
    SUB_TEXT: "Texto de subtítulo",
    OPEN_VIDEO: "Abrir un archivo de vídeo",
    open_video_info: "Tipos de archivos de vídeo admitidos: mp4, flv, mkv, avi, rmvb, mov, webm, m4v. Tamaño máximo: %{maxSize} MB.",
    open_video_extra_info: "Los subtítulos se restablecerán al abrir un nuevo video, luego puede ingresar subtítulos o usar el botón en la barra de herramientas superior para importar subtítulos desde archivos srt, ass, vtt o json para editarlos.",
    OPEN_DOCUMENT: "Abrir un documento o archivo de imagen",
    open_document_info: "Tipos de archivos de presentación admitidos: todos los tipos comunes de documentos e imágenes. Tamaño máximo: %{maxSize} MB.",
    open_document_extra_info: "Al abrir un documento, el documento se divide en páginas, cada página se representa como una imagen y el texto extraído (si lo hay) como subtítulo; Al abrir una imagen, si es un GIF animado, cada cuadro se representa como una imagen; de lo contrario, se representa una imagen. La dimensión del vídeo resultante será la dimensión de la primera imagen abierta, y otras imágenes más grandes que la primera cambiarán de tamaño proporcionalmente para que quepan.",
    OPEN_SUB: "Abrir un archivo de subtítulos",
    open_sub_info: "Tipos de archivos de subtítulos admitidos: vtt, srt, ass, json. Tamaño máximo: 1MB.",
    IMPORT_SUB: "Importar subtítulos desde archivos srt, ass, vtt o json",
    toggle_theme: "Alternar tema claro/oscuro",
    TRANSLATING: "Traductorio...",
    TRANSLAT_SUCCESS: "La traducción fue exitosa",
    VIDEO_EXT_ERR: "No se puede abrir este formato de vídeo",
    SUB_EXT_ERR: "No se puede abrir este formato de subtítulos",
    SPLIT: "Subtítulo dividido",
    LOADING_FFMPEG: "Cargando dependencia...",
    NEW: "Nuevo",
    NEW_hint: "Inicie una nueva tarea de subtítulos a voz abriendo un video, documento o archivo de imagen",
    OPEN: "Abierto",
    EXPORT: "Exportar",
    EXPORT_hint: "Guardar subtítulos en un archivo local",
    Subtitle_Format: "Formato de subtítulos",
    Json_note: "Nota: los datos del presentador solo se guardan en formato json",
    TRANSLATE: "Traducir",
    TRANSLATE_hint: "Traducir subtítulos a otro idioma",
    Target_Language: "Lengua de llegada",
    CLEAR: "Borrar",
    CLEAR_hint: "Borrar todos los subtítulos",
    CLEAR_confirm: "Todos los subtítulos se borrarán y eso no se podrá deshacer, ¿continuar?",
    UNDO: "Deshacer",
    UNDO_hint: "Deshacer el último cambio",
    HELP: "Ayuda",
    HELP_hint: "Información de ayuda y soporte de contacto",
    PRESENTER: "Presentador",
    PRESENTER_hint: "Ver/Editar presentadores",
    ACCOUNT: "Cuenta",
    ACCOUNT_hint: "Ver/Editar información de la cuenta",
    TASK: "Tarea",
    TASK_hint: "Enviar la tarea actual para procesar y ver el historial de tareas",
    PRICING: "Precios",
    PRICING_hint: "Ver precios y suscríbete a un plan pago",
    LANGUAGE: "Idioma",
    LANGUAGE_hint: "Cambiar idioma de la interfaz de usuario",
    Save_Gif_hint: "Guarde imágenes como un archivo GIF animado",
    My_Things: "Mis cosas",
    Submit_Task: "Enviar tarea",
    Video_Effect: "Efecto de vídeo",
    Storybook: "Libro de cuentos",
    Transcription: "Transcripción",
    Subtitles: "Subtítulo a voz",
    Add_Subtitle: "Agregar subtítulo",
    Delete_Subtitle: "Eliminar subtítulo",
    Merge_Subtitle: "Fusionar con el siguiente subtítulo",
    Shift_Subtitles: "Cambie este subtítulo y todos los demás a continuación para resolver el conflicto actual",
    Change_Image: "Cambiar imagen",
    Move_Subtitle_Up: "Subir subtítulos",
    Move_Subtitle_Down: "Mover subtítulo hacia abajo",
    Fit_Subtitle: "Ajustar la duración del subtítulo actual con su presentador",
    Text: "Texto",
    Audio: "Audio",
    Audio_Type: "Tipo",
    Audio_File: "Archivo (<%{maxSize}MB)",
    OR: "o",
    SIGN_IN: "Iniciar sesión",
    SIGN_OUT: "Desconectar",
    SIGN_UP: "Inscribirse",
    RESET_PASSWORD: "Restablecer la contraseña",
    Go_sign_in: "Ir a iniciar sesión",
    Welcome_back: "Bienvenido de nuevo, %{user}",
    GENERIC_ERROR_MESSAGE: "¡Algo salió mal! Por favor, inténtelo de nuevo más tarde.",
    ERROR_MESSAGE_UNKNOWN_REASON: "Excepción inesperada - %{reason}",
    ERROR_MESSAGE_MISSING_REQUIRED_FIELD: "El valor es obligatorio para %{field}.",
    ERROR_MESSAGE_NO_VALID_FILE_FOUND: "No se encontró ningún archivo válido; asegúrese de que los archivos seleccionados sean archivos multimedia válidos y que su tamaño y longitud estén dentro del límite.",
    ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE: "El archivo es un archivo multimedia no válido o el tamaño es demasiado grande: %{filename}",
    ERROR_MESSAGE_FACE_NOT_DETECTED: "La cara no se detecta en el archivo: %{filename}",
    Submit: "Entregar",
    Confirm: "Confirmar",
    sending: "enviando... por favor espera",
    Sign_in_with: "Inicia sesión con", 
    Name: "Nombre",
    Email: "Correo electrónico",
    Message: "Mensaje",
    Attach_Image: "Adjuntar imagen",
    Display_Name: "Nombre para mostrar",
    Coupon: "Cupón",
    Password: "Contraseña",
    Forgot_password: "¿Olvidaste tu contraseña?",
    Reset_it: "Reinicialo",
    No_account: "¿No tienes una cuenta?",
    New_Password: "Nueva contraseña",
    Retype_Password: "Reescribir contraseña",
    Resend_Password_Reset_Link: "Reenviar enlace de restablecimiento de contraseña",
    Passwords_not_match: "Nueva contraseña y Reescribir contraseña no coinciden, verifique.",
    Password_reset_message: "Se restableció la contraseña, ahora puede iniciar sesión con la nueva contraseña.",
    Password_reset_email_sent_message: "Se envía un correo electrónico para restablecer la contraseña, verifique su buzón y haga clic en el enlace para restablecer su contraseña dentro de una hora. Si no recibe el correo electrónico, revise si la dirección de correo electrónico proporcionada es correcta y verifique si está en la carpeta de correo no deseado.",
    Send_Password_Reset_Link: "Enviar enlace de restablecimiento de contraseña",
    send_password_reset_link_message: "Si tenemos una cuenta con la dirección de correo electrónico que ingresaste, se enviará un enlace de restablecimiento de contraseña a la dirección.",
    Activate_Account: "Activar la cuenta",
    Send_Account_Activation_Link: "Enviar enlace de activación de cuenta",
    Resend_Account_Activation_Link: "Reenviar enlace de activación de cuenta",
    Account_activated_message: "La cuenta está activada, ahora puede iniciar sesión con la nueva cuenta.",
    Account_activation_link_sent_message: "Se envía el enlace de activación de cuenta, verifique su buzón y haga clic en el enlace para activar su cuenta dentro de una hora.",
    Activate_account_email_sent_message: "Se envía un correo electrónico de bienvenida a {email}, siga las instrucciones para activar su cuenta dentro de una hora. Si no recibe el correo electrónico, revise si la dirección de correo electrónico proporcionada es correcta y verifique si está en la carpeta de correo no deseado.",
    Sign_in_to_edit_presenter: "Por favor inicia sesión para ver/editar tus presentadores", 
    Sign_in_to_edit_account: "Inicie sesión para ver/editar su cuenta", 
    Sign_in_to_edit_task: "Inicie sesión para ver su historial de tareas", 
    Sign_in_to_subscribe: "Por favor inicia sesión para suscribirte a este plan", 
    signin_required_after_session_expired: "Su sesión ha caducado, inicie sesión nuevamente.",
    Go_home: "Ir a la página de inicio",
    Go_Presenter: "Ir al presentador",
    Search_by_Name: "Buscar por nombre",
    Action: "Acción",
    New_Presenter: "Nuevo presentador",
    Edit_Presenter: "Editar presentador",
    Save: "Ahorrar",
    Edit: "Editar",
    Copy: "Copiar a nuevo",
    Update: "Actualizar",
    Delete: "Borrar",
    Change: "Cambiar",
    Cancel: "Cancelar",
    Show_per_page: "Mostrar %{pageSize} por página",
    page: "página",
    of: "de",
    First: "Primera",
    Last: "Última",
    confirm_to_delete_presenter: "¿Estás seguro de eliminar este presentador?",
    Number: "Número",
    Gender: "Género",
    Language: "Idioma",
    Voice_Id: "Identificación de voz",
    Voice_Style: "Estilo de voz",
    Style_Degree: "Grado de estilo",
    Clone_Voice: "Clonar voz",
    Voice_To_Clone: "Voz para clonar",
    Override_Film_Mode: "Anular el modo de película",
    Override_Film_Mode_tip: "Ingrese al modo de película, incluso si está configurado, nunca intente encontrar una persona en la imagen para decir el texto/audio.",
    Use_Avatar: "Usar Avatar",
    Avatar_Image: "Imagen de Avatar",
    Use_Moving_Head: "Usar cabeza móvil",
    Avatar_Shape: "Forma de avatar",
    Avatar_Location: "Ubicación del avatar",
    Toggle_Show_Legend: "Mostrar/Ocultar leyenda",
    Avatar_Height: "Altura del avatar",
    Avatar_Height_extra: "(como porcentaje de la altura del video)",
    Hide_Avatar_When_Silent: "Ocultar avatar cuando esté en silencio",
    Presenter_Name_tip: "Ingrese un nombre único para el presentador",
    Clone_Voice_tip: "Seleccione una voz buena para clonar para clonar su voz",
    Voice_To_Clone_tip: "Sube tu audio para clonarlo (límite de tamaño: %{maxSize} MB). Utilice un fragmento breve de discurso claro de una persona sin música ni ruido de fondo.",
    Use_Avatar_tip: "Utilice un avatar sincronizado con los labios para pronunciar el texto proporcionado",
    Use_Moving_Head_tip: "Utilice un ligero movimiento de la cabeza para que el habla del avatar parezca más natural",
    Avatar_Location_tip: "La ubicación relativa del avatar puede ocupar más espacio que la celda designada si la altura del avatar es mayor que la celda",
    Avatar_Height_tip: "Altura relativa del avatar, como porcentaje de la altura del vídeo",
    Hide_Avatar_When_Silent_tip: "No mostrar el avatar cuando no hables",
    custom_avatar_option: "*** avatar personalizado (imagen de retrato) ***",
    ERROR_MESSAGE_MISSING_CUSTOM_VOICE: "Proporcione un archivo válido para Voz para clonar o desmarque Clonar voz.",
    ERROR_MESSAGE_MISSING_CUSTOM_AVATAR: "Proporcione un archivo válido para la imagen de avatar o desmarque Usar avatar.",
    legendOfAvatarLocation: "Leyenda de la ubicación de Avatar",
    male: "masculino",
    female: "hembra",
    clonable_1: "bien para la clonación",
    clonable_2: "bueno para la clonación",
    Voice_Sample: "Muestra de voz",
    English: "Inglés",
    Hindi: "Hindi",
    Arabic: "Árabe",
    Portuguese: "Portugués",
    French: "Francés",
    Bulgarian: "Búlgaro",
    Czech: "Checo",
    Danish: "Danés",
    Estonian: "Estonio",
    Irish: "Irlandés",
    Spanish: "Español",
    Ukrainian: "Ucranio",
    Chinese: "Chino",
    Dutch: "Holandés",
    German: "Alemán",
    Japanese: "Japonés",
    Turkish: "Turco",
    Italian: "Italiano",
    Ewondo: "Ewondo",
    Hausa: "Hausa",
    Lingala: "Lingala",
    Twi: "Twi",
    Yoruba: "Yoruba",
    Hungarian: "Húngaro",
    Greek: "Griego",
    Finnish: "Finlandés",
    Polish: "Polaco",
    Croatian: "Croata",
    Lithuanian: "Lituano",
    Latvian: "Letón",
    Maltese: "Maltés",
    Romanian: "Rumano",
    Slovak: "Eslovaco",
    Slovenian: "Esloveno",
    Swedish: "Sueco",
    Catalan: "Catalán",
    Persian: "Persa",
    Bengali: "Bengalí",
    Russian: "Rusa",
    Urdu: "Urdu",
    Indonesian: "Indonesio",
    Javanese: "Javanés",
    Telugu: "Telugu",
    Vietnamese: "Vietnamita",
    Marathi: "Marathi",
    Tamil: "Tamil",
    Gujarati: "Gujarati",
    Kannada: "Kannada",
    Maithili: "Maithili",
    Malayalam: "Malayalam",
    Burmese: "Birmano",
    Swahili: "Suajili",
    Sundanese: "Sundanés",
    Punjabi: "Punjabi",
    Thai: "Tailandés",
    Kurdish: "Kurda",
    Malay: "Malayo",
    Cebuano: "Cebuano",
    Tagalog: "Tagalo",
    Malagasy: "Malgache",
    Chichewa: "Chichewa",
    Kinyarwanda: "Kinyarwanda",
    Somali: "Somali",
    Ilocano: "Ilocana",
    Kazakh: "Kazajo",
    Uyghur: "Uigur",
    Haitian: "Haitiano",
    Khmer: "Jemer",
    Shona: "Shona",
    Tatar: "Tártara",
    Armenian: "Armenio",
    Tigrinya: "Tigrinya",
    Turkmen: "Turco",
    Hebrew: "Hebreo",
    Tajik: "Tayiko",
    Lao: "Laosiano",
    Bambara: "Bambara",
    Tsonga: "Tsonga",
    Mongolian: "Mongol",
    Welsh: "Galés",
    GENDER_OPTIONS_: "indefinido",
    GENDER_OPTIONS_male: "Masculino",
    GENDER_OPTIONS_female: "Femenina",
    Adjust_Audio_Properties: "Ajustar las propiedades de audio",
    Pitch: "Paso",
    Speed: "Velocidad",
    Volume: "Volumen",
    AVATAR_SHAPE_OPTIONS_original: "Original",
    AVATAR_SHAPE_OPTIONS_original_rembg: "Original sin fondo",
    AVATAR_SHAPE_OPTIONS_circle: "Círculo",
    AVATAR_SHAPE_OPTIONS_square: "Cuadrado",
    AVATAR_SHAPE_OPTIONS_hexagon: "Hexágono",
    AVATAR_SHAPE_OPTIONS_cloud: "Nube",
    AUDIO_OPTIONS_driving: "conduciendo",
    AUDIO_OPTIONS_background: "fondo",
    Output_Language: "Idioma de salida",
    OUTPUT_LANGUAGE_OPTIONS_auto: "detección automática",
    OUTPUT_LANGUAGE_OPTIONS_English: "Inglés",
    New_Task: "Enviar tarea de subtítulos a voz",
    New_Video_Effect_Task: "Enviar tarea de efecto de vídeo",
    New_Transcription_Task: "Enviar tarea de transcripción",
    New_Storybook_Task: "Enviar tarea de libro de cuentos",
    Video_STS: "TTS para vídeo",
    Image_STS: "TTS para documento/imagen",
    Sign_in_to_submit_task: "Por favor inicia sesión para enviar la tarea",
    Task_History: "Historial de tareas",
    Task_History_hint: "Ver el historial de tareas y descargar resultados",
    Voice_Samples: "Muestras de voz",
    Voice_Samples_hint: "Ver la lista de voces predefinidas y escuchar muestras de voz",
    No_results: "No se encontró ningún resultado para los criterios seleccionados",
    View_YouTube_channel: "Mira más vídeos de muestra en nuestro canal de YouTube",
    Search_by_Number: "Buscar por número",
    Download: "Descargar",
    Status: "Estado",
    Submit_Time: "Hora de presentación",
    Start_Time: "Hora de inicio",
    Finish_Time: "Hora de finalización",
    Result_Video: "Resultados",
    Media: "Medios",
    Story: "Texto",
    Output_Video_Length: "Duración del vídeo de salida",
    seconds: "segundos",
    deleted: "eliminado",
    confirm_to_delete_task_files: "¿Está seguro de eliminar archivos relacionados con la tarea número %{number}?",
    Save_task_info: "Esto es para crear una tarea para enviar el video y los subtítulos que está editando al servidor para su procesamiento, y el video resultante incluirá voz sintetizada a partir del texto de los subtítulos usando la configuración de los presentadores seleccionados; también puede contener avatares sincronizados con los labios si los presentadores Usa avatares. Para volver a editar fácilmente los subtítulos, se recomienda guardarlos en un archivo local en formato json antes de enviar la tarea, \
    lo que se puede hacer usando la función Exportar. El servidor utilizará la configuración más reciente en los presentadores cuando comience a procesar su tarea, así que no actualice los presentadores pertinentes antes de que finalice la tarea; de lo contrario, puede obtener resultados inesperados. Se le notificará por correo electrónico sobre el resultado después de que se procese su tarea. También puedes encontrar la tarea en tu historial de tareas, verificar su estado y descargar el resultado si está disponible.",
    Save_task_instruction: "Hay algunas opciones para la tarea: La opción 'Mantener audio existente' es definir si se conserva o no el audio cuando el video de entrada contiene audio. La opción 'Grabar subtítulos' sirve para incrustar subtítulos en el vídeo con el tamaño de fuente seleccionado. También puede limitar la duración de la salida, por ejemplo, para producir un vídeo corto y ver si la configuración de los presentadores está bien, antes de generar el vídeo completo. La 'Descripción' describe de qué se trata la tarea para su propia referencia.",
    Save_video_effect_task_info: "Esto es para crear una tarea para cargar un video con el efecto seleccionado al servidor para su procesamiento, que aplicará una caricatura o un efecto de filtro a todo el video, con el audio original intacto. La resolución de vídeo resultante es SD para usuarios gratuitos y HD para suscriptores.",
    Save_video_effect_task_instruction: "Opcionalmente, puede limitar la duración de salida, por ejemplo, para producir un vídeo corto y ver si se desea el efecto seleccionado, antes de generar el vídeo completo. La 'Descripción' describe de qué se trata la tarea para su propia referencia.",
    Save_transcription_task_info: "Esto es para crear una tarea para transcribir audio a texto.",
    Save_transcription_task_instruction: "Opcionalmente, puede limitar la duración de la salida, por ejemplo, para transcribir una parte del audio y ver si la transcripción está bien, antes de transcribir el audio completo. La 'Descripción' describe de qué se trata la tarea para su propia referencia.",
    Save_video_STS_task_info: "Esto es para crear una tarea para enviar el video y los subtítulos que está editando al servidor para su procesamiento, y el video resultante incluirá voz sintetizada a partir del texto de los subtítulos usando la configuración de los presentadores seleccionados; también puede contener avatares sincronizados con los labios si los presentadores Usa avatares.",
    Save_image_STS_task_info: "Esto es para crear una tarea para enviar el documento/imágenes y los subtítulos que está editando al servidor para su procesamiento, y el video resultante incluirá audio suministrado o voz sintetizada del texto de los subtítulos usando la configuración de los presentadores seleccionados, también puede contener labios. avatares sincronizados si los presentadores usan avatares.",
    Save_task_info_extra: "Se le notificará por correo electrónico sobre el resultado después de que se procese su tarea. También puedes encontrar la tarea en tu historial de tareas, comprobar su estado y descargar el resultado si está disponible.",
    Go_Task_History: "Ir al historial de tareas",
    Keep_Natural_Speed_of_Video_or_Speech: "Mantenga la velocidad natural del video o del habla",
    Adjust_speech_speed_to_fit_video: "Ajustar la velocidad del habla para que se adapte al video",
    Adjust_video_speed_to_fit_speech: "Ajuste la velocidad del video para que se adapte al habla",
    Keep_Existing_Audio: "Mantener audio existente",
    Audio_Stems_to_Keep: "Temas de audio para conservar",
    Suppress_Underlying_Audio: "Suprimir el audio existente",
    Use_Film_Mode: "Utilice el modo Película",
    Animate_Still_Images: "Animar imágenes fijas",
    Burn_Subtitles: "Grabar subtítulos",
    Font_Size: "Tamaño de fuente",
    sample_text: "texto de ejemplo",
    Output_Length: "Longitud de salida",
    Description: "Descripción",
    To_apply_effect: "Para aplicar el efecto %{effect} a %{filename}",
    To_transcribe: "Para transcribir %{filename}",
    Select_Video_File: "Seleccione un archivo de vídeo (límite de tamaño: %{maxSize} MB)",
    Select_Effect: "Seleccionar efecto",
    Sample_Effect: "Efecto de muestra (antes, después)",
    Color1: "Color 1",
    Color2: "Color 2",
    Intensity: "Intensidad",
    Color1_tip: "Color para el primer tono",
    Color2_tip: "Color para el segundo tono. Si es diferente del primer color, se superpondrá un degradado entre los dos colores en la imagen; de lo contrario, solo se superpondrá el color.",
    Intensity_tip: "Intensidad de color en porcentaje",
    ERROR_MESSAGE_MISSING_VIDEO_FILE: "No se encontró ningún video, abra un archivo de video.",
    ERROR_MESSAGE_MISSING_IMAGE_FILE: "No se encontró ninguna imagen, abra un archivo de documento/imagen.",
    ERROR_MESSAGE_MISSING_SUBTITLES: "El subtítulo no es válido, debería tener al menos un elemento.",
    ERROR_MESSAGE_INVALID_OUTPUT_LENGTH: "La longitud de salida no es válida; debe estar entre %{minLength} y %{maxLength}.",
    Select_Media_File: "Seleccione un archivo de audio/vídeo (límite de tamaño: %{maxSize} MB)",
    Output_Format: "Formato de salida",
    ERROR_MESSAGE_MISSING_MEDIA_FILE: "No se encontraron medios, abra un archivo de audio/vídeo.",
    Keep_Existing_Audio_tip: "Conservar el audio del vídeo de entrada, si lo hubiera.",
    Audio_Stems_to_Keep_tip: "Mantenga la raíz seleccionada si el audio tiene varias raíces",
    Suppress_Underlying_Audio_tip: "Suprimir el audio existente cada vez que un presentador habla",
    Use_Film_Mode_tip: "Encuentre una persona en cada imagen para que pronuncie el texto/audio correspondiente si es posible, a menos que el presentador anule esta configuración.",
    Animate_Still_Images_tip: "Utilice el efecto de zoom y panorámica de Ken Burns para animar imágenes que no contengan avatares parlantes",
    Burn_Subtitles_tip: "Insertar subtítulos en el video con el tamaño de fuente seleccionado",
    Output_Length_tip: "La duración deseada del vídeo resultante. Reduzca la duración para producir un vídeo corto como vista previa.",
    Duration_To_Transcribe_tip: "La duración deseada para transcribir. Establezca una duración breve para producir una transcripción parcial como vista previa.",
    Storybook_Output_Length_tip: "La duración deseada del vídeo resultante. Esto solo sirve como guía, la duración real puede ser muy diferente dependiendo de la salida del chatbot y la velocidad de habla del presentador seleccionado, que se actualizará cuando se produzca el vídeo.",
    Image_STS_Output_Length_tip: "La duración deseada del vídeo resultante. La duración máxima es una estimación de la longitud del texto y la velocidad de habla de los presentadores seleccionados; la duración real se actualizará cuando se produzca el vídeo.",
    Description_tip: "Describe de qué se trata la tarea para tu propia referencia.",
    Picture_Style_tip: "Se genera una imagen por oración de la historia, seleccione un estilo preestablecido o personalizado para generar las imágenes.",
    Select_Presenter_tip: "Seleccione un presentador definido para contar la historia.",
    Picture_Resolution: "Resolución de imágenes generadas",
    Picture_Resolution_tip: "Resolución de imágenes generadas, las opciones incluyen las resoluciones de video recomendadas de las redes sociales populares",
    Generic_Topics: "Temas genéricos",
    Howto_Videos: "Videos de' cómo hacer",
    AUDIO_STEMS_OPTIONS_all: "Todo",
    AUDIO_STEMS_OPTIONS_vocals: "Voz",
    AUDIO_STEMS_OPTIONS_instruments: "Instrumentos",
    Quick_Edits: "Ediciones rápidas",
    Save_quick_edits_task_info: "Se trata de crear una tarea para ejecutar ediciones rápidas de audio o video utilizando las entradas proporcionadas. Es de uso gratuito sin límites de longitud de salida ni marcas de agua.",
    Audio_Stems_to_Remove: "Pistas de audio para eliminar",
    Audio_Stems_to_Remove_tip: "Eliminar el tema seleccionado si el audio tiene varios temas",
    Select_New_Audio_File: "Seleccionar nuevo archivo de audio",
    Speed_Adjustment: "Ajuste de velocidad",
    ERROR_MESSAGE_MISSING_NEW_AUDIO_FILE: "Seleccione el nuevo archivo de audio.",
    QUICK_EDITS_ACTION_OPTIONS_removeAudioStems: "Eliminar pistas de audio",
    QUICK_EDITS_ACTION_OPTIONS_removeAudioFromVideo: "Quitar audio del vídeo",
    QUICK_EDITS_ACTION_OPTIONS_addAudioToVideo: "Agregar audio al vídeo",
    ADJUST_SPEED_OPTIONS_0: "Sin cambios",
    ADJUST_SPEED_OPTIONS_1: "Ajustar la velocidad del audio para que se ajuste a la duración del vídeo",
    ADJUST_SPEED_OPTIONS_2: "Ajustar la velocidad del video para que se ajuste a la duración del audio",
    FAQ_title: "Preguntas frecuentes",
    FAQ1_question: "¿Qué puedo hacer con VideoPlus Studio?",
    FAQ1_answer: "VideoPlus Studio simplifica la transformación de videos con IA y reduce la barrera a la producción de vídeo creativo. Puede usarlo para aplicar diferentes estilos de dibujos animados y filtros a videos, generar libros de cuentos en video, así como transcribir audio a texto con un idioma detectado automáticamente y guardar el texto en un archivo de subtítulos. Además, puedes usarlo para agregar subtítulos a videos, documentos e imágenes y convertirlos en discursos en muchos idiomas. \
    Se puede seleccionar un presentador para cada subtítulo, el presentador tiene propiedades para convertir el texto en discursos en cierto idioma y voz, y una imagen (opcional) para generar un avatar sincronizado con los labios para pronunciar el texto; el avatar tiene propiedades como forma, tamaño y ubicación para controlar cómo se mostrará en el vídeo. Vea algunos vídeos de muestra y casos de uso en la página <a href='/'>inicio</a>.",
    FAQ2_question: "Entonces, ¿cómo empiezo?",
    FAQ2_answer: "Para realizar algunas tareas simples, como aplicar efectos especiales a videos, generar libros de cuentos y transcribir audios, puede encontrar los elementos del menú en el menú principal, completar algunos campos y enviar la tarea para procesar. Para realizar tareas de texto a voz (TTS), también necesita definir presentadores y editar subtítulos.\
    <ul><li>Tarea: Una tarea es un objeto de usuario que contiene datos para su procesamiento, como el video y sus subtítulos, opciones para grabar subtítulos y limitar la duración de salida. La ventana de tareas se abre con el botón 'Tarea' en el menú.</li> \
    <li>Subtítulos: Los subtítulos son textos que representan el contenido del audio de un video, deben incluir información de cuándo debe aparecer y desaparecer cada línea de texto. La edición de subtítulos de un vídeo se basa en una línea de tiempo, en la que se especifica el tiempo de inicio y de finalización de cada subtítulo. Cuando se trabaja con un documento o una imagen, se establece un subtítulo para cada página o fotograma; su hora de inicio y finalización se calcula automáticamente según el tiempo real que tarda en pronunciarse el texto.</li> \
    <li>Presentador: El Subtítulo tiene una propiedad Presentador, que se utiliza para convertir texto en voz. Un presentador es un objeto definido por el usuario que tiene un nombre y una voz, opcionalmente una imagen de avatar de cierta forma, tamaño y ubicación en el vídeo resultante. Por defecto cada usuario tiene 2 presentadores preestablecidos que pueden modificarse. Los presentadores son sus asistentes de IA que expresan sus ideas.</li> \
    </ul>Inicie una nueva tarea TTS abriendo un archivo de vídeo/documento/imagen, luego escriba los subtítulos o abra un archivo existente que contenga subtítulos para editar. La aplicación intentará extraer texto de cada página cuando abras un documento. \
    Para cada subtítulo seleccione un presentador, ajuste el texto y la posición inicial y final. Una vez que haya terminado con la edición, haga clic en el botón 'Enviar tarea' en la barra de herramientas superior para enviar la tarea a procesar. El enlace de descarga del vídeo resultante se puede encontrar en el historial de tareas cuando esté disponible.",
    FAQ3_question: "¿Cómo obtengo el video procesado? ¿Y hay alguna manera de obtener una vista previa del resultado?",
    FAQ3_answer: "Una vez que envíes una tarea, se pondrá en cola para procesarla. Una vez procesada tu tarea, recibirás una notificación por correo electrónico sobre el resultado; luego, podrás encontrar la tarea en la página <a href='/TaskHistory'>Historial de tareas</a>; usa el botón de descarga para descargar el video resultante. Para una tarea de TTS, sabrá dónde aparece cada subtítulo, ya que el texto del subtítulo está sincronizado con el reproductor de video/documento/imagen, y el texto se superpone en el reproductor en la ubicación adecuada; \
    los discursos y los avatares sincronizados con los labios necesitan energía del servidor para producirse, además el video puede ser largo, por lo que no hay una forma sencilla de obtener una vista previa del producto final, pero puedes escuchar una muestra de cada voz en la página <a href='/VoiceSamples'>Muestras de voz</a> y enviar una breve tarea TTS usando el presentador para probar si tu texto suena bien con la voz seleccionada y si tu avatar puede sincronizar los labios correctamente. \
    Para asegurarse de que los presentadores trabajen como se espera, se recomienda enviar primero una tarea breve con una duración de salida limitada, por ejemplo, varios segundos, antes de enviar una tarea de duración completa. Una vez que obtenga el video resultado, revíselo y ajuste los subtítulos y/o presentadores si es necesario, repita este proceso hasta que esté satisfecho.",
    FAQ4_question: "Tengo subtítulos en un idioma, ¿puedo agregarlos a mi video en otro idioma?",
    FAQ4_answer: "Sí, Puedes traducir los subtítulos a un idioma diferente usando el botón 'Traducir' en la barra de herramientas superior. La aplicación admite %{count_languages} idiomas y %{count_voices} voces de diversos géneros y acentos, solo necesita definir presentadores con ciertas voces y usarlos de acuerdo con el idioma de los subtítulos. También puedes clonar tu voz basándose en cualquier voz predefinida al definir un presentador.",
    FAQ5_question: "Consejos sobre edición de subtítulos y diferentes formatos",
    FAQ5_answer: "El límite de longitud de cada texto de subtítulo es de %{max_text_size1} caracteres en la página <a href='/VideoSubtitleToSpeech'>TTS para video</a>, y de %{max_text_size2} caracteres en la página <a href='/ImageSubtitleToSpeech'>TTS para documento/imagen</a>. Tendrás que dividir el texto más largo en varios subtítulos. En la barra de herramientas superior puedes encontrar un botón 'Deshacer' para deshacer la última edición y un botón 'Borrar' para borrar todos los subtítulos, y siempre puedes abrir un archivo de subtítulos guardado para empezar de nuevo. En el editor de subtítulos puede encontrar botones para agregar/eliminar/combinar subtítulos, \
    una lista de selección del presentador y un botón para ajustar la duración del subtítulo con su presentador, el ajuste automático de la duración también ocurre cuando cambia de presentador o edita el texto. El ajuste automático de la duración se realiza utilizando estadísticas simples del presentador, que asignan suficiente duración para que el texto se pronuncie a la velocidad de habla normal del presentador (una duración más corta puede dar lugar a un discurso más rápido), pero puede cambiar según corresponda. Los subtítulos se pueden exportar a archivos en \
    varios formatos, el formato json es específico de esta aplicación con información del presentador y otros formatos se puede utilizar en reproductores de video de escritorio o en línea como YouTube. Es un buen hábito guardar los subtítulos actuales en un archivo json antes de comenzar una nueva tarea o enviando la tarea actual, para que pueda recargar los subtítulos y hacer ajustes si es necesario.",
    FAQ6_question: "Acerca de los planes de suscripción",
    FAQ6_answer: "La principal diferencia entre los distintos planes de suscripción (gratuitos o pagos) es la cantidad de presentadores que puedes crear y la duración total de los videos que puedes enviar para su procesamiento. Consulta los detalles en la página <a href='/Pricing'>Precios</a>, donde puedes suscribirte a un plan pago si aún no lo has hecho. El saldo de la cantidad de presentadores y la duración del video se muestra en la sección 'Créditos disponibles' en la página <a href='/Account'>Cuenta</a>.",
    FAQ11_question: "¿Qué puedo hacer con VideoPlus Studio?",
    FAQ11_answer: "VideoPlus Studio simplifica la transformación de videos con IA y reduce la barrera a la producción de vídeo creativo. Puede usarlo para aplicar diferentes estilos de dibujos animados y filtros a videos, generar libros de cuentos en video, así como transcribir audio a texto con un idioma detectado automáticamente y guardar el texto en un archivo de subtítulos. Además, puedes usarlo para agregar subtítulos a videos, documentos e imágenes y convertirlos en discursos en muchos idiomas. \
    Se puede seleccionar un presentador para cada subtítulo, el presentador tiene propiedades para convertir el texto en discursos en cierto idioma y voz, y una imagen (opcional) para generar un avatar sincronizado con los labios para pronunciar el texto; el avatar tiene propiedades como forma, tamaño y ubicación para controlar cómo se mostrará en el vídeo.",
    FAQ12_question: "¿Puedo utilizar VideoPlus Studio gratis?",
    FAQ12_answer: "Sí, es totalmente gratis crear/editar/traducir/exportar subtítulos. Además, VideoPlus Studio tiene un nivel gratuito que permite a los usuarios explorar todas las funciones con una cuota limitada, por ejemplo, 30 segundos de vídeo por día. Sin embargo, las suscripciones pagas pueden ofrecerle cuotas mensuales mucho más altas.",
    FAQ13_question: "¿En qué se diferencia esta aplicación de otros editores de vídeo del mercado?",
    FAQ13_answer: "VideoPlus Studio se destaca por combinar la edición de subtítulos con texto a voz para facilitar la adición de voz en off a sus videos en las ubicaciones precisas. Junto con las funciones de presentador por subtítulo, transcripción de audio y traducción de texto, puede realizar locuciones multilingües y para varias personas a través de subtítulos y doblar una película usted mismo. \
    La misma capacidad también se puede utilizar para documentos e imágenes, de modo que pueda generar vídeos para presentar vívidamente sus ideas con voces y avatares de IA en varios idiomas.",
    FAQ14_question: "¿Cuántos idiomas, voces y avatares admites?",
    FAQ14_answer: "Actualmente admitimos 80 idiomas, 458 voces predefinidas y 528 avatares predefinidos. Además, puedes usar voces buenas para clonar para clonar nuevas voces, y se pueden crear avatares personalizados con cualquier foto de rostro.",
    FAQ15_question: "¿Necesito hardware o software especial para usar la aplicación?",
    FAQ15_answer: "No, VideoPlus Studio está completamente basado en web, solo necesita un dispositivo con acceso a Internet y un navegador para usarlo, no se necesita instalación. Cuando envíe una tarea, nuestros servidores trabajarán arduamente para procesarla y notificarle una vez finalizada.",
    FAQ16_question: "¿Necesito experiencia previa en edición de video para usar esta aplicación?",
    FAQ16_answer: "No, no se requiere experiencia previa en edición de video para usar esta aplicación. Nuestra interfaz intuitiva y fácil de usar permite que cualquiera cree contenido, y también tenemos tutoriales y videos instructivos para mostrarle las técnicas básicas de edición de subtítulos y guiarlo a través de algunos casos de uso típicos.",
    FAQ17_question: "¿Cuáles son las opciones de pago?",
    FAQ17_answer: "Utilizamos PayPal para gestionar las suscripciones de forma segura. Puede pagar utilizando su cuenta PayPal o cualquier tarjeta de débito y crédito admitida.",
    FAQ18_question: "¿Qué pasa si sólo necesito usar la aplicación por un período corto?",
    FAQ18_answer: "Ofrecemos suscripciones mensuales simples que se renovarán automáticamente hasta que se cancelen o se pongan en pausa. Si tiene una cuenta PayPal, puede iniciar sesión en la cuenta para cancelar o pausar su suscripción en cualquier momento; O bien, contáctenos para obtener ayuda.",
    Questions_suggestions: "¿Más preguntas o sugerencias?",
    Contact_Support: "Soporte de contacto",
    Privacy_Policy: "Política de privacidad",
    Terms_of_Service: "Términos de servicio",
    SUPPORT: "Soporte",
    Support_info: "Si tiene alguna pregunta o sugerencia, comuníquese con nosotros por correo electrónico a support@videoplus.studio o mediante el formulario de soporte a continuación, nos comunicaremos con usted lo antes posible.",
    ERROR_MESSAGE_FILE_INVALID_AND_NOT_TO_BE_INCLUDED: "El archivo %{name} es un archivo multimedia no válido o su tamaño es demasiado grande y no se incluirá para el envío.",
    Subscription_Plan: "Plan de suscripción",
    Available_Credits: "Créditos disponibles (restablecidos en %{num} %{unit})",
    Other_Tasks: "Otras tareas",
    Extra_Credits: "Créditos adicionales",
    current: "actual",
    next: "próximo",
    days: "días",
    hours: "horas",
    minutes: "minutos",
    day: "día",
    month: "mes",
    edit_subtitles: "crear/editar/traducir/exportar subtítulos",
    total_presenters: "número total de presentadores: %{num}",
    total_video_length_for_transcription: "duración total del vídeo para la transcripción: %{num} minutes / %{duration}",
    total_video_length_for_other_tasks: "duración total del vídeo para otras tareas: %{num} minutes / %{duration}",
    results_with_logo: "logotipo de aiTransformer agregado en los videos procesados",
    results_without_logo: "no se agregó ningún logotipo en los videos procesados",
    results_kept_for_one_day: "los archivos de resultados se conservan durante un día",
    results_kept_for_ten_days: "los archivos de resultados se conservan durante diez días",
    Pricing_description: "Pruébelo gratis, actualice a un plan pago si necesita una cuota más alta; Suscripción mensual simple, sin necesidad de comprometerse a largo plazo y cancelar en cualquier momento",
    Select_Plan: "Seleccionar plan",
    login_and_subscribe: "¡Inicia sesión y luego suscríbete al plan!",
    current_plan: "Este es tu plan actual",
    next_plan: "Este es tu próximo plan",
    Subscription_Plan_note: "* Nota: Si no encuentra un plan que satisfaga sus necesidades, comuníquese con nosotros para analizar otras opciones. Si se ha suscrito a un plan y desea cancelarlo, debe hacerlo a través de su cuenta de PayPal para detener el pago recurrente (consulte <a href='https://www.wikihow.com/Cancel-a-PayPal-Subscription' target='_blank' rel='noreferrer'>esta publicación</a> para obtener instrucciones paso a paso). \
    Es importante tener en cuenta que todos los pagos son definitivos y no reembolsables, por lo que si cancela su suscripción, seguirá activa hasta que finalice el período de suscripción actual. En caso de que desee cambiar a un plan diferente, primero debe cancelar el plan existente, esperar hasta que finalice el período de suscripción y luego suscribirse al nuevo plan. Si necesita un enfoque alternativo, no dude en contactarnos para obtener más ayuda.",
    Subscription_successful: "Su suscripción fue exitosa, cierre sesión y luego inicie sesión para disfrutar del nuevo plan.",
    Subscription_bonus: "Suscríbete a un plan pago ahora y también obtendrás un plan pago de aiTransformer gratis.",
    slide_num: "diapositiva %{num}",
    Help_videos_info: "¡Bienvenido a VideoPlus Studio! Los siguientes vídeos instructivos muestran los usos típicos de esta aplicación. De hecho, estos videos en sí son videos explicativos creados con esta aplicación.",
    Show_initial_screen: "Mostrar esto como la pantalla inicial",
    Save_storybook_task_info: "Esto es para crear una tarea para generar un libro de cuentos en video utilizando las entradas proporcionadas.",
    Story_Generation_Method: "Método de generación de historias",
    Custom_Generation_Method_hint: "Ingrese las líneas de su historia para generar voz y prompts entre [] para generar imágenes. Las oraciones deben terminar con punto (.). Se generará una imagen por oración; de forma predeterminada, la oración se usará como prompt, pero si desea usar un prompt personalizado (entre []), colóquelo antes del final de la oración, por ejemplo, oración. 1 [prompt de imagen 1]. oración 2 [prompt de imagen 2].",
    Story_Genre: "Género de la historia",
    Picture_Style: "Estilo de imágenes generadas",
    Style_Sample: "Muestra de estilo (prompt: Santa is coming to town)",
    Custom_Style_hint: "Ingrese el texto para un estilo personalizado (se admiten pesos). Puede especificar dónde colocar el prompt para estilizarlo con un marcador de posición [prompt], p. impresionante [prompt] de Anna Dittmann. Si no hay ningún marcador de posición, el texto del estilo se agregará al prompt.",
    Storyteller_Properties: "Propiedades del narrador",
    ERROR_MESSAGE_MISSING_PROMPT: "Error en el método de generación: ingrese un prompt.",
    ERROR_MESSAGE_MISSING_OR_INVALID_URL: "Error en el método de generación: introduzca un enlace válido a una página web.",
    ERROR_MESSAGE_MISSING_DOCUMENT_FILE: "Error en el método de generación: seleccione un archivo de documento.",
    ERROR_MESSAGE_MISSING_CUSTOM_STORY: "Error en el método de generación: ingresa tu historia.",
    GENERATION_METHOD_OPTIONS_prompt: "Generar historia a partir de un prompt (ingrese el prompt)",
    GENERATION_METHOD_OPTIONS_url: "Generar historia basada en los contenidos de una página web (pega la URL)",
    GENERATION_METHOD_OPTIONS_document: "Generar historia basada en el contenido de un documento (subir el documento, tamaño máximo: 10 MB)",
    GENERATION_METHOD_OPTIONS_custom: "Genere voz con texto e imágenes proporcionados con prompts personalizadas (ingrese la historia personalizada)",
    STORY_GENRE_OPTIONS_: "indefinido",
    "STORY_GENRE_OPTIONS_picture books": "libros ilustrados (niños de 0 a 5 años)",
    "STORY_GENRE_OPTIONS_early chapter books": "libros de primeros capítulos (niños de 6 a 8 años)",
    "STORY_GENRE_OPTIONS_middle grade novels": "novelas de grado medio (niños de 9 a 12 años)",
    "STORY_GENRE_OPTIONS_young adult fiction": "ficción para adultos jóvenes",
    "STORY_GENRE_OPTIONS_realist literature": "literatura realista",
    "STORY_GENRE_OPTIONS_literary fiction": "ficcion literaria",
    "STORY_GENRE_OPTIONS_science fiction": "ciencia ficción",
    STORY_GENRE_OPTIONS_fantasy: "fantasía",
    "STORY_GENRE_OPTIONS_mystery/thriller": "misterio/suspense",
    STORY_GENRE_OPTIONS_romance: "romance",
    STORY_GENRE_OPTIONS_horror: "horror",
    "STORY_GENRE_OPTIONS_historical fiction": "ficción histórica",
    STORY_GENRE_OPTIONS_adventure: "aventura",
    STORY_GENRE_OPTIONS_drama: "drama",
    "STORY_GENRE_OPTIONS_comedy/satire": "comedia/sátira",
    "STORY_GENRE_OPTIONS_TL;DR": "TL;DR",
    "STORY_GENRE_OPTIONS_first person": "primera persona",
    STORY_OUTPUT_OPTIONS_video: "Vídeo y texto de la historia",
    STORY_OUTPUT_OPTIONS_gif: "Imagen GIF y subtítulos json (adecuado como entrada de TTS para documento/imagen)",
    Select_Presenter: "Seleccionar presentador",
    default_storybook_description: "Para generar un libro de cuentos con método: %{method}, género: %{genre}, estilo: %{style}",
    Videoplus_Studio_headline0: "Editor y traductor de subtítulos gratuito para vídeos/documentos/imágenes. Subtítulos de voz y de texto a video con voces y avatares personalizables para presentaciones realistas.",
    Videoplus_Studio_headline: "Haz que tus vídeos/documentos/imágenes hablen en 80 idiomas mediante subtítulos",
    Videooplus_Studio_button_text: "Prueba gratis",
    Videooplus_Studio_specials: "<strong>VideoPlus Studio también admite efectos de vídeo como estilos de dibujos animados y filtros, como se explica en los vídeos instructivos. ¡Pruébalo ahora totalmente gratis! Simplemente inicie sesión en <a href='https://videoplus.studio' target='_blank'>VideoPlus Studio</a>, abra la ventana Cuenta y actualice su cuenta con el código de cupón 'feb24zeniteq', obtendrá Un mes gratis del plan Lite, suficiente para generar 125 minutos de vídeos. Este cupón es válido durante febrero de 2024.</strong>",
    Videoplus_Studio_subline: "Produzca videos atractivos internamente con edición de subtítulos: ¡sin problemas con la voz en off!",
    Videoplus_Studio_highlights: "admite %{count_languages} idiomas, %{count_voices} voces predefinidas + clonación de voces, %{count_avatars} avatares predefinidos + avatares personalizados",
    Videoplus_Studio_highlights_1: " idiomas, ",
    Videoplus_Studio_highlights_2: " voces predefinidas + clonación de voces, ",
    Videoplus_Studio_highlights_3: " avatares predefinidos + avatares personalizados",
    Videooplus_Studio_Tutorial: "Tutorial de VideoPlus Studio",
    Videooplus_Studio_demo_video: "Vídeo de demostración de VideoPlus Studio",
    Product_Service_Demos: "Demostraciones de productos y servicios",
    Product_Service_Demos_description: "Herramienta perfecta para demostrar productos de software y crear videos explicativos para servicios en línea: simplemente grabe la pantalla que muestra cómo funciona el software o servicio, luego agregue presentadores (voz + avatar) a la grabación de pantalla, como creamos los vídeos tutoriales de aiTransformer en inglés, francés y español, presentado por un grupo de avatares de variados géneros y acentos en diferentes lugares.",
    Sales_Marketing: "Ventas y Marketing",
    Sales_Marketing_description: "Revolucione las ventas y el marketing virtuales ofreciendo un toque personal único: los usuarios pueden incorporar sus propias imágenes como avatares en los vídeos. Esta característica agrega autenticidad y fomenta conexiones más fuertes con la audiencia. Los profesionales de ventas pueden humanizar las presentaciones, mientras que los especialistas en marketing pueden mejorar la autenticidad de la marca mostrando a los miembros del equipo.",
    Podcast_Helper: "Ayudante de podcasts",
    Podcast_Helper_description: "Transforme la experiencia del podcasting permitiendo a los creadores incorporar asistentes de IA. La capacidad de agregar presentadores de IA con diversas voces y avatares no solo agrega un estilo único al podcast sino que también mantiene cautivada a la audiencia. Los podcasters pueden utilizar esta función para simular conversaciones, presentar temas o incluso proporcionar información adicional, haciendo que sus podcasts sean más entretenidos e informativos.",
    Video_Transformer: "Transformador de vídeo",
    Video_Transformer_description: "Transforme videos con dibujos especiales y efectos de filtro, aplique el efecto cuadro por cuadro mientras mantiene el auto intacto, lo que resulta en una transferencia de estilo consistente en todo momento.",
    Storybook_Maker: "Creador de libros de cuentos",
    Storybook_Maker_description: "Automatice la creación de libros de cuentos en vídeo combinando tecnologías de modelo de lenguaje grande (LLM), generación de imágenes y conversión de texto a voz (TTS). A partir de entradas simples como un mensaje, una URL o un documento, genera una historia y las imágenes que la acompañan, las reúne en un video con un presentador personalizado que cuenta la historia usando la voz que usted elija.",
    Multilingual_More: "Multilingüe y más",
    Multilingual_More_description: "Amplíe el alcance de la audiencia traduciendo subtítulos a diferentes idiomas y presentando contenido en un idioma distinto al original, rompiendo efectivamente las barreras del idioma y llegando a una audiencia global. Ya sea para presentaciones comerciales, materiales educativos o contenido creativo, las características únicas de VideoPlus Studio mejoran la accesibilidad y garantizan una experiencia multilingüe verdaderamente inmersiva para los espectadores.",
    More_Use_Cases: "Más casos de uso",
    Use_Case1_title: "Reinvención tutorial",
    Use_Case1: "Reutilice los tutoriales en vídeo existentes agregando nuevos comentarios, ideas o explicaciones en diferentes idiomas. Mejore el contenido educativo sin volver a grabar todo el vídeo.",
    Use_Case2_title: "Exploración narrativa",
    Use_Case2: "Cree contenido narrativo utilizando diversas voces y avatares para explicar conceptos complejos o contar historias. Esto es particularmente útil para canales educativos o empresas que buscan simplificar temas complejos.",
    Use_Case3_title: "Demostraciones virtuales de productos",
    Use_Case3: "Desarrolla demostraciones virtuales de productos sin estar frente a la cámara. Los presentadores pueden articular las características, los beneficios y el uso del producto, lo que lo hace ideal para empresas de comercio electrónico o industrias tecnológicas.",
    Use_Case4_title: "Apoyo al aprendizaje de idiomas",
    Use_Case4: "Facilite el aprendizaje de idiomas proporcionando contenido de video con subtítulos traducidos y el idioma hablado correspondiente, brindando una nueva forma de aprendizaje para educadores y estudiantes de idiomas.",
    Use_Case5_title: "Mejoras periódicas en los podcasts",
    Use_Case5: "Mejore continuamente los podcasts integrando presentadores de IA para presentar segmentos, proporcionar actualizaciones o participar en conversaciones. Esto aporta variedad al formato del podcast y mantiene a la audiencia interesada.",
    Use_Case6_title: "Localización de contenido y marca consistente",
    Use_Case6: "Localice contenido periódicamente traduciendo subtítulos y adaptando el idioma hablado para llegar a audiencias diversas. Garantice una marca coherente en diferentes mercados lingüísticos utilizando VideoPlus Studio para crear vídeos promocionales con subtítulos traducidos y contenido hablado.",
    Use_Case7_title: "Argumentos de venta regulares",
    Use_Case7: "Los equipos de ventas pueden utilizar VideoPlus Studio para crear argumentos de venta personalizados y regulares. La capacidad de agregar diversos avatares y voces agrega un toque único a cada tono.",
    Use_Case8_title: "Presentaciones y Propuestas de Negocios",
    Use_Case8: "Mejore los documentos y presentaciones comerciales agregando voces en off y avatares. Esta característica puede hacer que los argumentos de venta, los materiales de capacitación y las propuestas de los clientes sean más atractivos y memorables, captando la atención de la audiencia y transmitiendo información de manera efectiva.",
    Use_Case9_title: "Materiales educativos y tutoriales",
    Use_Case9: "Mantenga al público interesado proporcionando actualizaciones o anuncios periódicos de una manera dinámica y visualmente atractiva, utilizando avatares y voces diversas para transmitir información.",
    Use_Case10_title: "Exposiciones culturales e históricas",
    Use_Case10: "Desarrollar exhibiciones o presentaciones interactivas que muestren contenido cultural e histórico. Utilice documentos de texto e imágenes para crear presentaciones informativas, agregando avatares para narrar historias, compartir ideas o proporcionar comentarios, mejorando la experiencia del visitante.",
    Use_Case11_title: "Tarjetas de felicitación interactivas",
    Use_Case11: "Cree tarjetas de felicitación personalizadas donde cada página presente un avatar dinámico que entrega un mensaje personalizado. Los usuarios pueden cargar sus diseños o elegir entre plantillas, agregando un toque único a ocasiones especiales como cumpleaños, aniversarios o días festivos.",
    Use_Case12_title: "Libros de cuentos atractivos para niños",
    Use_Case12: "Desarrolle libros de cuentos interactivos donde cada página cobre vida con un avatar parlante que narra la historia. Esta característica puede mejorar la participación de los niños en la narración, haciendo que el aprendizaje sea más divertido e interactivo.",
    Use_Case13_title: "Tours virtuales y guías",
    Use_Case13: "Cree recorridos virtuales interactivos o guías utilizando imágenes y documentos de texto. Agregue un presentador para guiar a los espectadores a través de cada sección, brindando información, explicaciones o comentarios para mejorar la experiencia y la comprensión del espectador.",
    Use_Case14_title: "Proyectos Multimedia Personalizados",
    Use_Case14: "Combine texto, imágenes y locuciones para crear proyectos multimedia personalizados, como portafolios, exhibiciones o álbumes de recortes digitales. Agregue avatares para narrar contenido, compartir ideas o proporcionar comentarios, haciendo que el proyecto sea más atractivo e interactivo.",
    Use_Case15_title: "Transcripción multilingüe y doblaje de películas",
    Use_Case15: "Transcribe audio/vídeo con detección automática de idioma, lo que facilita una localización y traducción eficientes. Copia películas a varios idiomas sin esfuerzo, ya que cada subtítulo puede tener su propio presentador, lo que permite una voz en off perfecta en diferentes idiomas. Ideal para creadores de contenido que buscan distribución global y participación de la audiencia.",
    Use_Case16_title: "Transferencia de estilo de video con dibujos animados y efectos de filtro",
    Use_Case16: "Mejore los videos con dibujos animados y efectos de filtro consistentes mientras conserva el contenido original. Aplique efectos especiales cuadro por cuadro para mantener la coherencia visual a lo largo del video. Ideal para equipos de producción de vídeo que buscan mejorar su metraje con mejoras creativas sin comprometer su integridad.",
    Tutorials: "Tutoriales",
    tutorial1_title: "Cómo aplicar efectos de dibujos animados y filtros a los vídeos",
    tutorial1_summary: "<ol><li>Haga clic en el botón 'Efecto de vídeo' en el menú.</li> \
    <li>Selecciona tu video y un efecto de caricatura o filtro que te guste.</li> \
    <li>Haga clic en 'Enviar' y registre el número de tarea, luego espere hasta recibir un correo electrónico sobre el resultado de la tarea, o verifique el historial de tareas para conocer su estado; vuelva a verificar si no está listo.</li> \
    <li>Busque la tarea y descargue el archivo de video resultante cuando esté disponible. Su video se aplicará cuadro por cuadro con el efecto seleccionado, mientras que el audio original se mantiene intacto.</li></ol>",
    tutorial2_title: "Cómo transcribir audios/videos",
    tutorial2_summary: "<ol><li>Haga clic en el botón 'Transcripción' en el menú.</li> \
    <li>Seleccione su audio/video y el idioma y formato de salida.</li> \
    <li>Haga clic en 'Enviar' y registre el número de tarea, luego espere hasta recibir un correo electrónico sobre el resultado de la tarea, o verifique el historial de tareas para conocer su estado; vuelva a verificar si no está listo.</li> \
    <li>Busque la tarea y descargue el archivo de texto resultante cuando esté disponible.</li> \
    <li>Si seleccionaste un video, se usará el audio del video para transcribirlo a texto. Si seleccionaste el formato de salida srt o vtt, el archivo resultante será un archivo de subtítulos listo para editar en TTS para video o documento/imagen.</li></ol>",
    tutorial3_title: "Cómo hacer vídeos explicativos dinámicos a partir de grabaciones sin voz",
    tutorial3_summary: "Supongamos que aprendiste algunos trucos interesantes y quieres crear un video explicativo para compartir con el mundo, te gustaría aparecer en el video pero no te sientes cómodo hablando frente a una cámara, VideoPlus Studio puede resolver el dilema.<br/> \
    Si tu historia sucede en el mundo real, como un evento, preparar un plato, reparar una herramienta, etc., simplemente usa una videocámara o tu teléfono para grabar lo que está sucediendo; si es algo en el mundo virtual, puedes encontrar fácilmente una herramienta de grabación de pantalla para grabar lo que haces en la pantalla. \
    Una vez que tengas la grabación, guárdala como video MP4. Mientras grabas no tienes que decidir de qué hablar, porque después puedes abrir el video en VideoPlus Studio, reproducirlo y escribir lo que quieras hablar como subtítulos según la escena. \
    En la aplicación puedes definir un presentador con una voz que te guste, clonar tu propia voz si lo deseas y cargar tu imagen para el avatar del presentador; luego selecciona el presentador para tus subtítulos, la aplicación convertirá los subtítulos en voz hablada por el avatar y los agregará al video, \
    dando como resultado un video presentado por usted incluso si no está en el video original en absoluto.<br/> \
    Aprenda a crear un video explicativo sobre cómo aprender ComfyUI, una herramienta sofisticada para generar imágenes usando Stable Diffusion en <a href='%{post_url}' target='_blank'>este tutorial paso a paso</a>.",
    tutorial4_title: "Cómo convertir documentos en presentaciones dinámicas",
    tutorial4_summary: "¿Puedes convertir una simple presentación de PowerPoint en un interesante curso en línea? Probemos lo siguiente: \
    <ol><li>Utilice una atractiva voz de IA para narrar el contenido;</li> \
    <li>Añade algunos efectos de sonido;</li> \
    <li>Pídale a un chatbot que genere algunos pares divertidos de preguntas y respuestas según el contenido;</li> \
    <li>Agregar diapositivas de preguntas y respuestas presentadas por avatares;</li> \
    <li>Reúne todo y genera un vídeo.</li></ol> \
    Aprenda a convertir su contenido estático en presentaciones dinámicas sin necesidad de grabación de voz en <a href='%{post_url}' target='_blank'>este tutorial paso a paso</a>.",
    tutorial5_title: "Cómo doblar una película por tu cuenta",
    tutorial5_summary: "El doblaje de películas no es una tarea sencilla, normalmente requiere de un equipo de profesionales para la traducción, grabación de locuciones, mezcla y edición de sonido, etc. VideoPlus Studio puede detectar el idioma del audio de un vídeo, transcribir el audio a texto y guardarlo en un archivo de subtítulos. \
    Junto con una utilidad de traducción y un editor de subtítulos flexible que puede definir un presentador para cada línea de los subtítulos, puedes doblar una película tú mismo: el proceso se parece mucho a cómo se hace profesionalmente, pero sin la molestia de usar actores de voz reales, grabaciones, etc. \
    <br/>Aprenda a doblar el tráiler ficticio de la película 'Regreso al futuro 4' del inglés al francés en <a href='%{post_url}' target='_blank'>este tutorial paso a paso</a>.",
    tutorial6_title: "Cómo combinar imágenes y palabras en vídeos narrativos",
    tutorial6_summary: "\"Una imagen vale mas que mil palabras.\" Tanto las imágenes como las palabras pueden contar historias, pero combinarlas en un video puede contar una historia más vívida y dar vida a los contenidos estáticos. A todos nos gustan los libros de cuentos cuando somos niños. Las palabras con imágenes son más atractivas y fáciles de recordar. \
    Ahora, con la IA generativa, se pueden generar imágenes a partir de texto, y el texto se puede convertir en voz hablada por un avatar. <br/>Aprenda a transformar un artículo en un video narrativo dinámico en <a href='%{post_url}' target='_blank'>este tutorial paso a paso</a>.",
    tutorial7_title: "Creación de historias personalizadas con IA: una mirada al creador de Storybook multimodal",
    tutorial7_summary: "El arte de contar historias ha evolucionado a lo largo de la historia, desde las tradiciones orales hasta las narraciones escritas y, ahora, hasta la era digital. Storybook Maker es una herramienta que se considera el siguiente paso en esta progresión. Fue desarrollada con el objetivo de automatizar la creación de historias personalizadas. \
    incorporando aprendizaje multimodal para mejorar la experiencia de contar historias. <br/>Aprenda las complejidades y los trucos de la herramienta Storybook en <a href='%{post_url}' target='_blank'>este artículo detallado</a>.",
    Mobile_Apps: "Aplicaciones móviles",
    Mobile_Apps_info: "También hemos desarrollado algunas aplicaciones móviles como las que se indican a continuación. Estas aplicaciones son gratuitas y no tienen ningún coste ni restricción. No se recopilan datos de usuario ni es necesario iniciar sesión. Tus creaciones son tuyas, sin ningún tipo de marca. ¡Esperamos que las disfrutes y agradecemos tus comentarios!",
    OfflineTextRecognizer: "Reconocedor de texto sin conexión",
    OfflineTextRecognizer_description: "Extraiga texto de imágenes sin esfuerzo, incluso sin conexión a Internet",
    RussianBlock: "Russian Block",
    RussianBlock_description: "Redescubre la diversión clásica del Tetris en modo infinito",
    aiTransformerBackgroundEditor: "aiTransformer Background Editor",
    aiTransformerBackgroundEditor_description: "Elimina fácilmente el fondo de una imagen y sé creativo reemplazando el fondo por uno nuevo",
    aiTransformerCartoonizer: "aiTransformer Cartoonizer",
    aiTransformerCartoonizer_description: "Transforma imágenes del mundo real en imágenes estilo dibujos animados.",
    aiTransformerEnhancer: "aiTransformer Enhancer",
    aiTransformerEnhancer_description: "Mejora las fotos para obtener un aspecto más claro, nítido y profesional.",
    aiTransformerFilter: "aiTransformer Filter",
    aiTransformerFilter_description: "Añade un poco de estilo a tus fotos con más de 50 filtros, incluidos Sepia, Relieve y más.",
    aiTransformerMultiStylizer: "aiTransformer MultiStylizer",
    aiTransformerMultiStylizer_description: "Un enfoque creativo para diseñar imágenes: aplicar múltiples estilos a una sola imagen",
    aiTransformerSketcher: "aiTransformer Sketcher",
    aiTransformerSketcher_description: "Crea diferentes estilos de bocetos a partir de imágenes, simulando dibujos a mano.",
    music_video_info: "Con la ayuda de la aplicación VideoPlus Studio, a continuación se muestra un video musical creado usando algunas imágenes transformadas con herramientas aiTransformer, con una canción generada sobre esas herramientas agregada.",
};

const fr = {
    VideoPlus_Studio: "VideoPlus Studio",
    Copyright: "© 2024 aiTransformer Technologies. Tous les droits sont réservés.",
    PageNotFound: "Désolé, la page que vous essayez de visiter n'existe pas.",
    app_headline0: "Simplifiez la transformation vidéo grâce à l'IA et réduisez les obstacles à la production vidéo créative",
    app_headline1: "Améliorez votre création vidéo avec l'IA",
    app_headline2: "Transformez des vidéos avec des effets spéciaux. Générez des livres d'histoires vidéo. Faites parler vos vidéos/documents/images en 80 langues via des sous-titres.",
    app_highlight1: "Transformez des vidéos avec des effets spéciaux tels que des dessins animés",
    app_highlight2: "Générez des livres d'histoires vidéo de divers genres et styles",
    app_highlight3: "Transcrivez des audios/vidéos avec détection automatique de la langue",
    app_highlight4: "Faites parler des vidéos/documents/images dans 80 langues via des sous-titres",
    app_cta_headline1: "Améliorez votre création vidéo avec le VideoPlus Studio",
    app_cta_headline2: "Commencez avec des crédits gratuits quotidiens",
    app_cta1: "Commencez gratuitement",
    app_cta2: "Essayez-le maintenant",
    TTS: "Texte pour parler",
    Samples: "Échantillons",
    Use_Cases: "Cas d'utilisation",
    Special_Use_Case: "Derniers exemples de vidéos de notre chaîne YouTube",
    Brands: "Partenaires / Clients",
    Contact_Us: "Nous contacter",
    Mission: "Faire en sorte que l’IA profite à tous",
    Quick_Links: "Liens rapides",
    Follow_Us: "Suivez-nous sur",
    HOME: "Accueil",
    LOADING: "Chargement...",
    SUB_TEXT: "Texte des sous-titres",
    OPEN_VIDEO: "Ouvrir un fichier vidéo",
    open_video_info: "Types de fichiers vidéo pris en charge : mp4, flv, mkv, avi, rmvb, mov, webm, m4v. Taille maximale : %{maxSize} Mo.",
    open_video_extra_info: "Les sous-titres seront réinitialisés lors de l'ouverture d'une nouvelle vidéo, vous pouvez ensuite saisir des sous-titres ou utiliser le bouton dans la barre d'outils supérieure pour importer des sous-titres à partir d'un fichier srt, ass, vtt ou json pour les éditer.",
    OPEN_DOCUMENT: "Ouvrir un document ou un fichier image",
    open_document_info: "Types de fichiers de présentation pris en charge : tous les types courants de documents et d’images. Taille maximale : %{maxSize} Mo.",
    open_document_extra_info: "Lors de l'ouverture d'un document, le document est divisé en pages, chaque page est rendue sous forme d'image et le texte extrait (le cas échéant) comme sous-titre ; lors de l'ouverture d'une image, s'il s'agit d'un GIF animé, chaque image est rendue sous forme d'image, sinon une image est rendue. La dimension vidéo résultante sera la dimension de la première image ouverte, et les autres images plus grandes que la première seront redimensionnées proportionnellement pour s'adapter.",
    OPEN_SUB: "Ouvrir un fichier de sous-titres",
    open_sub_info: "Types de fichiers de sous-titres pris en charge : vtt, srt, ass, json. Taille maximale : 1 Mo.",
    IMPORT_SUB: "Importer des sous-titres à partir d'un fichier srt, ass, vtt ou json",
    toggle_theme: "Basculer le thème clair/sombre",
    TRANSLATING: "Traduction en cours...",
    TRANSLAT_SUCCESS: "Traduction réussie",
    VIDEO_EXT_ERR: "Impossible d'ouvrir ce format vidéo",
    SUB_EXT_ERR: "Impossible d'ouvrir ce format de sous-titre",
    SPLIT: "Sous-titre divisé",
    LOADING_FFMPEG: "Chargement de la dépendance...",
    NEW: "Nouveau",
    NEW_hint: "Démarrez une nouvelle tâche de sous-titres en parole en ouvrant une vidéo, un document ou un fichier image",
    OPEN: "Ouvrir",
    EXPORT: "Exporter",
    EXPORT_hint: "Enregistrer les sous-titres dans un fichier local",
    Subtitle_Format: "Format des sous-titres",
    Json_note: "Remarque : les données du présentateur sont uniquement conservées au format json",
    TRANSLATE: "Traduire",
    TRANSLATE_hint: "Traduire les sous-titres dans une autre langue",
    Target_Language: "Langue cible",
    CLEAR: "Effacer",
    CLEAR_hint: "Effacer tous les sous-titres",
    CLEAR_confirm: "Tous les sous-titres seront effacés et cela ne pourra pas être annulé, continuer ?",
    UNDO: "Annuler",
    UNDO_hint: "Annuler la dernière modification",
    HELP: "Aide",
    HELP_hint: "Informations d'aide et contact du support",
    PRESENTER: "Présentateur",
    PRESENTER_hint: "Afficher/Modifier les présentateurs",
    ACCOUNT: "Compte",
    ACCOUNT_hint: "Afficher/Modifier les informations du compte",
    TASK: "Tâche",
    TASK_hint: "Soumettre la tâche en cours pour traiter et afficher l'historique des tâches",
    PRICING: "Tarifs",
    PRICING_hint: "Consultez les tarifs et abonnez-vous à un forfait payant",
    LANGUAGE: "Langue",
    LANGUAGE_hint: "Changer la langue de l'interface utilisateur",
    Save_Gif_hint: "Enregistrer les images sous forme de fichier GIF animé",
    My_Things: "Mes choses",
    Submit_Task: "Soumettre la tâche",
    Video_Effect: "Effet vidéo",
    Storybook: "Livre d'histoires",
    Transcription: "Transcription",
    Subtitles: "Sous-titres en parole",
    Add_Subtitle: "Ajouter un sous-titre",
    Delete_Subtitle: "Supprimer le sous-titre",
    Merge_Subtitle: "Fusionner avec le sous-titre suivant",
    Shift_Subtitles: "Déplacez ce sous-titre et tous les autres ci-dessous pour résoudre le conflit actuel",
    Change_Image: "Changer l'image",
    Move_Subtitle_Up: "Monter le sous-titre",
    Move_Subtitle_Down: "Descendre le sous-titre",
    Fit_Subtitle: "Ajuster la durée du sous-titre actuel avec son présentateur",
    Text: "Texte",
    Audio: "Audio",
    Audio_Type: "Taper",
    Audio_File: "Fichier (<%{maxSize}Mo)",
    OR: "ou",
    SIGN_IN: "Se connecter",
    SIGN_OUT: "se déconnecter",
    SIGN_UP: "S'inscrire",
    RESET_PASSWORD: "Réinitialiser le mot de passe",
    Go_sign_in: "Allez vous connecter",
    Welcome_back: "Content de te revoir, %{user}",
    GENERIC_ERROR_MESSAGE: "Quelque chose s'est mal passé ! Veuillez réessayer plus tard.",
    ERROR_MESSAGE_UNKNOWN_REASON: "Exception inattendue - %{reason}",
    ERROR_MESSAGE_MISSING_REQUIRED_FIELD: "La valeur est obligatoire pour %{field}.",
    ERROR_MESSAGE_NO_VALID_FILE_FOUND: "Aucun fichier valide n'est trouvé, veuillez vous assurer que les fichiers sélectionnés sont des fichiers multimédias valides et que leur taille/longueur est dans les limites.",
    ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE: "Le fichier est un fichier multimédia non valide ou sa taille est trop grande : %{filename}",
    ERROR_MESSAGE_FACE_NOT_DETECTED: "Le visage n'est pas détecté dans le fichier : %{filename}",
    Submit: "Soumettre",
    Confirm: "Confirmer",
    sending: "envoi...veuillez patienter",
    Sign_in_with: "Se connecter avec", 
    Name: "Nom",
    Email: "E-mail",
    Message: "Message",
    Attach_Image: "Joindre une image",
    Password: "Mot de passe",
    Display_Name: "Nom pour l'affichage",
    Coupon: "Coupon",
    Forgot_password: "Mot de passe oublié?",
    Reset_it: "Réinitialisez-le",
    No_account: "Vous n'avez pas de compte ?",
    New_Password: "Nouveau mot de passe",
    Retype_Password: "Retaper le mot de passe",
    Resend_Password_Reset_Link: "Renvoyer le lien de réinitialisation du mot de passe",
    Passwords_not_match: "Nouveau mot de passe et Retaper le mot de passe ne correspondent pas, veuillez vérifier.",
    Password_reset_message: "Le mot de passe est réinitialisé, vous pouvez maintenant vous connecter avec le nouveau mot de passe.",
    Password_reset_email_sent_message: "E-mail de réinitialisation du mot de passe est envoyé, veuillez vérifier votre boîte aux lettres et cliquez sur le lien pour réinitialiser votre mot de passe dans l'heure. Si vous ne recevez pas l'e-mail, veuillez vérifier si l'adresse e-mail fournie est correcte et vérifier si elle se trouve dans le dossier spam.",
    Send_Password_Reset_Link: "Envoyer le lien de réinitialisation du mot de passe",
    send_password_reset_link_message: "Si nous avons un compte avec l'adresse e-mail que vous entrez, un lien de réinitialisation du mot de passe sera envoyé à l'adresse.",
    Activate_Account: "Activer le compte",
    Send_Account_Activation_Link: "Envoyer le lien d'activation du compte",
    Resend_Account_Activation_Link: "Renvoyer le lien d'activation du compte",
    Account_activated_message: "Le compte est activé, vous pouvez maintenant vous connecter avec le nouveau compte.",
    Account_activation_link_sent_message: "Lien d'activation de compte est envoyé, veuillez vérifier votre boîte aux lettres et cliquez sur le lien pour activer votre compte dans l'heure.",
    Activate_account_email_sent_message: "Un e-mail de bienvenue est envoyé à {email}, veuillez suivre les instructions pour activer votre compte dans l'heure. Si vous ne recevez pas l'e-mail, veuillez vérifier si l'adresse e-mail fournie est correcte et vérifier si elle se trouve dans le dossier spam.",
    Sign_in_to_edit_presenter: "Veuillez vous connecter pour afficher/modifier vos présentateurs", 
    Sign_in_to_edit_account: "Veuillez vous connecter pour afficher/modifier votre compte", 
    Sign_in_to_edit_task: "Veuillez vous connecter pour afficher l'historique de vos tâches", 
    Sign_in_to_subscribe: "Veuillez vous connecter pour souscrire à ce plan", 
    signin_required_after_session_expired: "Votre session est expirée, veuillez vous reconnecter.",
    Go_home: "Aller à la page d'accueil",
    Go_Presenter: "Aller au présentateur",
    Search_by_Name: "Rechercher par nom",
    Action: "Action",
    New_Presenter: "Nouveau présentateur",
    Edit_Presenter: "Modifier le présentateur",
    Save: "Sauvegarder",
    Edit: "Modifier",
    Copy: "Copier vers le nouveau",
    Update: "Mise à jour",
    Delete: "Supprimer",
    Change: "Changement",
    Cancel: "Annuler",
    Show_per_page: "Afficher %{pageSize} par page",
    page: "page",
    of: "de",
    First: "Première",
    Last: "Dernière",
    confirm_to_delete_presenter: "Êtes-vous sûr de supprimer ce présentateur ?",
    Number: "Nombre",
    Gender: "Genre",
    Language: "Langue",
    Voice_Id: "Identifiant vocal",
    Voice_Style: "Style de voix",
    Style_Degree: "Degré de style",
    Clone_Voice: "Cloner la voix",
    Voice_To_Clone: "Voix à cloner",
    Override_Film_Mode: "Remplacer le mode film",
    Override_Film_Mode_tip: "Ingore Film Mode, même s'il est activé, n'essayez jamais de trouver une personne dans l'image pour prononcer le texte/l'audio.",
    Use_Avatar: "Utiliser un avatar",
    Avatar_Image: "Image d'avatar",
    Use_Moving_Head: "Utiliser la tête mobile",
    Avatar_Shape: "Forme d'avatar",
    Avatar_Location: "Emplacement de l'avatar",
    Toggle_Show_Legend: "Afficher/Masquer la légende",
    Avatar_Height: "Hauteur de l'avatar",
    Avatar_Height_extra: "(en pourcentage de la hauteur de la vidéo)",
    Hide_Avatar_When_Silent: "Masquer l'avatar en mode silencieux",
    Presenter_Name_tip: "Entrez un nom unique pour le présentateur",
    Clone_Voice_tip: "Sélectionnez une voix adaptée au clonage pour cloner votre voix",
    Voice_To_Clone_tip: "Téléchargez votre audio pour le cloner (limite de taille : %{maxSize} Mo). Utilisez un court extrait de discours clair d’une personne, sans musique de fond ni bruit.",
    Use_Avatar_tip: "Utilisez un avatar synchronisé sur les lèvres pour prononcer le texte fourni",
    Use_Moving_Head_tip: "Utilisez la tête légèrement mobile pour rendre le discours de l'avatar plus naturel",
    Avatar_Location_tip: "Emplacement relatif de l'avatar, peut prendre plus de place que la cellule désignée si la hauteur de l'avatar est supérieure à la cellule",
    Avatar_Height_tip: "Hauteur relative de l'avatar, en pourcentage de la hauteur de la vidéo",
    Hide_Avatar_When_Silent_tip: "Ne pas afficher l'avatar lorsque vous ne parlez pas",
    custom_avatar_option: "*** avatar personnalisé (image de tête) ***",
    ERROR_MESSAGE_MISSING_CUSTOM_VOICE: "Veuillez fournir un fichier valide pour Voix à cloner ou décocher la case Cloner la voix.",
    ERROR_MESSAGE_MISSING_CUSTOM_AVATAR: "Veuillez fournir un fichier valide pour l'image d'avatar ou décocher la case Utiliser un avatar.",
    legendOfAvatarLocation: "Légende de localisation de l'avatar",
    male: "mâle",
    female: "femme",
    clonable_1: "ok pour le clonage",
    clonable_2: "bon pour le clonage",
    Voice_Sample: "Échantillon de voix",
    English: "Anglais",
    Hindi: "Hindi",
    Arabic: "Arabe",
    Portuguese: "Portugais",
    French: "Français",
    Bulgarian: "Bulgare",
    Czech: "Tchèque",
    Danish: "Danois",
    Estonian: "Estonien",
    Irish: "Irlandais",
    Spanish: "Espagnol",
    Ukrainian: "Ukrainien",
    Chinese: "Chinois",
    Dutch: "Néerlandais",
    German: "Allemand",
    Japanese: "Japonais",
    Turkish: "Turc",
    Italian: "Italien",
    Ewondo: "Ewondo",
    Hausa: "Haoussa",
    Lingala: "Lingala",
    Twi: "Twi",
    Yoruba: "Yorouba",
    Hungarian: "Hongrois",
    Greek: "Grec",
    Finnish: "Finlandais",
    Polish: "Polonais",
    Croatian: "Croate",
    Lithuanian: "Lituanien",
    Latvian: "Letton",
    Maltese: "Maltais",
    Romanian: "Roumain",
    Slovak: "Slovaque",
    Slovenian: "Slovène",
    Swedish: "Suédois",
    Catalan: "Catalan",
    Persian: "Persan",
    Bengali: "Bengali",
    Russian: "Russe",
    Urdu: "Ourdou",
    Indonesian: "Indonésienne",
    Javanese: "Javanaise",
    Korean: "Coréenne",
    Telugu: "Telugu",
    Vietnamese: "Vietnamienne",
    Marathi: "Marathi",
    Tamil: "Tamoule",
    Gujarati: "Gujarati",
    Kannada: "Kannada",
    Maithili: "Maithili",
    Malayalam: "Malayalam",
    Burmese: "Birmane",
    Swahili: "Swahili",
    Sundanese: "Sundanaise",
    Punjabi: "Pendjabi",
    Thai: "Thaïlandaise",
    Kurdish: "Kurde",
    Malay: "Malaise",
    Cebuano: "Cebuano",
    Tagalog: "Tagalog",
    Malagasy: "Malgache",
    Chichewa: "Chichewa",
    Kinyarwanda: "Kinyarwanda",
    Somali: "Somalienne",
    Ilocano: "Ilocano",
    Kazakh: "Kazakhe",
    Uyghur: "Ouïghoure",
    Haitian: "Haïtienne",
    Khmer: "Khmère",
    Shona: "Shona",
    Tatar: "Tatare",
    Armenian: "Arménienne",
    Tigrinya: "Tigrinya",
    Turkmen: "Turkmène",
    Hebrew: "Hébraïque",
    Tajik: "Tadjike",
    Lao: "Laotienne",
    Bambara: "Bambara",
    Tsonga: "Tsonga",
    Mongolian: "Mongole",
    Welsh: "Galloise",
    GENDER_OPTIONS_: "Indéfini",
    GENDER_OPTIONS_male: "Mâle",
    GENDER_OPTIONS_female: "Femme",
    Adjust_Audio_Properties: "Ajuster les propriétés audio",
    Pitch: "Ton",
    Speed: "Vitesse",
    Volume: "Volume",
    AVATAR_SHAPE_OPTIONS_original: "Original",
    AVATAR_SHAPE_OPTIONS_original_rembg: "Original avec arrière-plan supprimé",
    AVATAR_SHAPE_OPTIONS_circle: "Cercle",
    AVATAR_SHAPE_OPTIONS_square: "Carré",
    AVATAR_SHAPE_OPTIONS_hexagon: "Hexagone",
    AVATAR_SHAPE_OPTIONS_cloud: "Nuage",
    AUDIO_OPTIONS_driving: "conduite",
    AUDIO_OPTIONS_background: "arrière-plan",
    Output_Language: "Langue de sortie",
    OUTPUT_LANGUAGE_OPTIONS_auto: "détection automatique",
    OUTPUT_LANGUAGE_OPTIONS_English: "Anglais",
    New_Task: "Soumettre tâche de sous-titre à la parole",
    New_Video_Effect_Task: "Soumettre tâche d'effet vidéo",
    New_Transcription_Task: "Soumettre tâche de transcription",
    New_Storybook_Task: "Soumettre tâche de livre d'histoires",
    Video_STS: "TTS pour la vidéo",
    Image_STS: "TTS pour document/image",
    Sign_in_to_submit_task: "Veuillez vous connecter pour soumettre la tâche",
    Task_History: "Historique des tâches",
    Task_History_hint: "Afficher l'historique des tâches et télécharger les résultats",
    Voice_Samples: "Échantillons de voix",
    Voice_Samples_hint: "Afficher la liste des voix prédéfinies et écouter des échantillons de parole",
    No_results: "Aucun résultat trouvé pour les critères sélectionnés",
    View_YouTube_channel: "Regardez plus d'exemples de vidéos sur notre chaîne YouTube",
    Search_by_Number: "Recherche par numéro",
    Download: "Télécharger",
    Status: "Statut",
    Submit_Time: "Heure de soumission",
    Start_Time: "Heure de début",
    Finish_Time: "Heure de fin",
    Result_Video: "Résultats",
    Media: "Médias",
    Story: "Texte",
    Output_Video_Length: "Longueur de la vidéo de sortie",
    seconds: "seconds",
    deleted: "supprimé",
    confirm_to_delete_task_files: "Êtes-vous sûr de supprimer les fichiers liés à la tâche numéro %{number} ?",
    Save_task_info: "Il s'agit de créer une tâche pour soumettre la vidéo et les sous-titres que vous éditez au serveur pour traitement, et la vidéo résultante inclura la parole synthétisée à partir du texte des sous-titres en utilisant les paramètres des présentateurs sélectionnés, elle peut également contenir des avatars synchronisés sur les lèvres si les présentateurs. utilisez des avatars. Pour éditer à nouveau facilement les sous-titres, il est recommandé de sauvegarder les sous-titres dans un fichier local au format json avant de soumettre la tâche, \
    ce qui peut être effectué à l'aide de la fonction Exporter. Le serveur utilisera les derniers paramètres des présentateurs lorsqu'il commencera à traiter votre tâche. Ne mettez donc pas à jour les présentateurs concernés avant la fin de la tâche, sinon vous pourriez obtenir des résultats inattendus. Vous serez informé par e-mail du résultat une fois votre tâche traitée. Vous pouvez également retrouver la tâche dans votre historique des tâches, vérifier son statut et télécharger le résultat s'il est disponible.",
    Save_task_instruction: "Il existe quelques options pour cette tâche : L'option « Conserver l'audio existant » permet de définir s'il faut ou non conserver l'audio lorsque la vidéo d'entrée contient de l'audio. L'option « Graver les sous-titres » permet d'intégrer des sous-titres dans la vidéo avec la taille de police sélectionnée. Vous pouvez également limiter la durée de sortie, par exemple, pour produire une courte vidéo afin de voir si les paramètres des présentateurs sont corrects, avant de générer la vidéo complète. La « Description » sert à décrire l'objet de la tâche pour votre propre référence.",
    Save_video_effect_task_info: "Il s'agit de créer une tâche pour télécharger une vidéo avec l'effet sélectionné sur le serveur pour traitement, qui appliquera un effet de dessin animé ou de filtre à l'ensemble de la vidéo, avec l'audio d'origine intact. La résolution vidéo résultante est SD pour les utilisateurs gratuits et HD pour les abonnés.",
    Save_video_effect_task_instruction: "En option, vous pouvez limiter la durée de sortie, par exemple, pour produire une courte vidéo afin de voir si l'effet sélectionné est souhaité, avant de générer la vidéo complète. La « Description » sert à décrire l'objet de la tâche pour votre propre référence.",
    Save_transcription_task_info: "Il s'agit de créer une tâche pour transcrire l'audio en texte.",
    Save_transcription_task_instruction: "En option, vous pouvez limiter la longueur de sortie, par exemple, pour transcrire une partie de l'audio pour voir si la transcription est correcte, avant de transcrire l'audio complète. La « Description » sert à décrire l'objet de la tâche pour votre propre référence.",
    Save_video_STS_task_info: "Il s'agit de créer une tâche pour soumettre la vidéo et les sous-titres que vous éditez au serveur pour traitement, et la vidéo résultante inclura la parole synthétisée à partir du texte des sous-titres en utilisant les paramètres des présentateurs sélectionnés, elle peut également contenir des avatars synchronisés sur les lèvres si les présentateurs. utilisez des avatars.",
    Save_image_STS_task_info: "Il s'agit de créer une tâche pour soumettre le document/les images et les sous-titres que vous modifiez au serveur pour traitement, et la vidéo résultante comprendra l'audio fourni ou la parole synthétisée à partir du texte des sous-titres en utilisant les paramètres des présentateurs sélectionnés, elle peut également contenir des lèvres. avatars synchronisés si les présentateurs utilisent des avatars.",
    Save_task_info_extra: "Vous serez informé par e-mail du résultat une fois votre tâche traitée. Vous pouvez également retrouver la tâche dans votre historique des tâches, vérifier son statut et télécharger le résultat s'il est disponible.",
    Go_Task_History: "Accédez à l'historique des tâches",
    Keep_Natural_Speed_of_Video_or_Speech: "Conservez la vitesse naturelle de la vidéo ou de la parole",
    Adjust_speech_speed_to_fit_video: "Ajuster la vitesse de la parole pour l'adapter à la vidéo",
    Adjust_video_speed_to_fit_speech: "Ajuster la vitesse de la vidéo pour l'adapter à la parole",
    Keep_Existing_Audio: "Conserver l'audio existant",
    Audio_Stems_to_Keep: "Tiges audio à conserver",
    Suppress_Underlying_Audio: "Supprimer l'audio existant",
    Burn_Subtitles: "Graver les sous-titres",
    Use_Film_Mode: "Utilisez le mode Film",
    Animate_Still_Images: "Animer des images fixes",
    Font_Size: "Taille de police",
    sample_text: "exemple de texte",
    Output_Length: "Longueur de sortie",
    Description: "Description",
    To_apply_effect: "Pour appliquer l'effet %{effect} à %{filename}",
    To_transcribe: "Pour transcrire %{filename}",
    Select_Video_File: "Sélectionnez le fichier vidéo (taille limite : %{maxSize} Mo)",
    Select_Effect: "Sélectionner un effet",
    Sample_Effect: "Exemple d'effet (avant, après)",
    Color1: "Couleur 1",
    Color2: "Couleur 2",
    Intensity: "Intensité",
    Color1_tip: "Couleur pour le premier ton",
    Color2_tip: "Couleur pour le deuxième ton. Si elle est différente de la première couleur, un dégradé entre les deux couleurs sera superposé à l'image, sinon seule la couleur est superposée.",
    Intensity_tip: "Intensité de couleur en pourcentage",
    ERROR_MESSAGE_MISSING_VIDEO_FILE: "Aucune vidéo n'est trouvée, veuillez ouvrir un fichier vidéo.",
    ERROR_MESSAGE_MISSING_IMAGE_FILE: "Aucune image n'est trouvée, veuillez ouvrir un fichier document/image.",
    ERROR_MESSAGE_MISSING_SUBTITLES: "Le sous-titre n'est pas valide, il doit contenir au moins un élément.",
    ERROR_MESSAGE_INVALID_OUTPUT_LENGTH: "La longueur de sortie n'est pas valide, elle doit être comprise entre %{minLength} et %{maxLength}.",
    Select_Media_File: "Sélectionnez un fichier audio/vidéo (taille limite : %{maxSize} Mo)",
    Output_Format: "Format de sortie",
    ERROR_MESSAGE_MISSING_MEDIA_FILE: "Aucun média n'est trouvé, veuillez ouvrir un fichier audio/vidéo.",
    Keep_Existing_Audio_tip: "Conservez l'audio de la vidéo d'entrée le cas échéant",
    Audio_Stems_to_Keep_tip: "Conserver la tige sélectionnée si l'audio comporte plusieurs tiges",
    Suppress_Underlying_Audio_tip: "Supprimer le son existant chaque fois qu'un présentateur parle",
    Use_Film_Mode_tip: "Trouvez une personne dans chaque image pour prononcer le texte/audio correspondant si possible, à moins que ce paramètre ne soit annulé par le présentateur.",
    Animate_Still_Images_tip: "Utilisez l'effet de panoramique et de zoom de Ken Burns pour animer des images qui ne contiennent pas d'avatars parlants",
    Burn_Subtitles_tip: "Intégrer des sous-titres dans la vidéo avec la taille de police sélectionnée",
    Output_Length_tip: "La durée souhaitée de la vidéo résultante. Réduisez la longueur pour produire une courte vidéo en guise d’aperçu.",
    Duration_To_Transcribe_tip: "La durée souhaitée pour retranscrire. Définissez une courte durée pour produire une transcription partielle en guise d'aperçu.",
    Storybook_Output_Length_tip: "La durée souhaitée de la vidéo résultante. Ceci sert juste à titre indicatif, la durée réelle peut être très différente en fonction du résultat du chatbot et de la vitesse de parole du présentateur sélectionné, qui sera mise à jour lors de la production de la vidéo.",
    Image_STS_Output_Length_tip: "La durée souhaitée de la vidéo résultante. La longueur maximale est une estimation en fonction de la longueur du texte et de la vitesse de parole des présentateurs sélectionnés, la durée réelle sera mise à jour lors de la production de la vidéo.",
    Description_tip: "Décrivez en quoi consiste la tâche pour votre propre référence",
    Picture_Style_tip: "Une image est générée par phrase de l'histoire, sélectionnez un style prédéfini ou personnalisé pour générer les images.",
    Select_Presenter_tip: "Sélectionnez un présentateur défini pour raconter l'histoire",
    Picture_Resolution: "Résolution des images générées",
    Picture_Resolution_tip: "Résolution des images générées, les options incluent les résolutions vidéo recommandées des médias sociaux populaires",
    Generic_Topics: "Sujets génériques",
    Howto_Videos: "Vidéos explicatives",
    AUDIO_STEMS_OPTIONS_all: "Tout",
    AUDIO_STEMS_OPTIONS_vocals: "Voix",
    AUDIO_STEMS_OPTIONS_instruments: "Instruments",
    Quick_Edits: "Modifications rapides",
    Save_quick_edits_task_info: "Il s'agit de créer une tâche permettant d'exécuter des modifications rapides d'audio ou de vidéo à l'aide des entrées fournies. Son utilisation est gratuite, sans limite de longueur de sortie ni filigrane.",
    Audio_Stems_to_Remove: "Tiges audio à supprimer",
    Audio_Stems_to_Remove_tip: "Supprimer la tige sélectionnée si l'audio comporte plusieurs tiges",
    Select_New_Audio_File: "Sélectionner un nouveau fichier audio",
    Speed_Adjustment: "Réglage de la vitesse",
    ERROR_MESSAGE_MISSING_NEW_AUDIO_FILE: "Veuillez sélectionner le nouveau fichier audio.",
    QUICK_EDITS_ACTION_OPTIONS_removeAudioStems: "Supprimer les tiges audio",
    QUICK_EDITS_ACTION_OPTIONS_removeAudioFromVideo: "Supprimer l'audio de la vidéo",
    QUICK_EDITS_ACTION_OPTIONS_addAudioToVideo: "Ajouter de l'audio à la vidéo",
    ADJUST_SPEED_OPTIONS_0: "Aucun changement",
    ADJUST_SPEED_OPTIONS_1: "Ajustez la vitesse de l'audio pour l'adapter à la longueur de la vidéo",
    ADJUST_SPEED_OPTIONS_2: "Ajuster la vitesse de la vidéo pour l'adapter à la longueur de l'audio",
    FAQ_title: "Questions fréquemment posées",
    FAQ1_question: "Que puis-je faire avec VideoPlus Studio ?",
    FAQ1_answer: "VideoPlus Studio simplifie la transformation vidéo grâce à l'IA et réduit les obstacles à la production vidéo créative. Vous pouvez l'utiliser pour appliquer différents styles de dessins animés et filtres aux vidéos, générer des livres d'histoires vidéo, ainsi que transcrire l'audio en texte avec une langue détectée automatiquement et enregistrer le texte dans un fichier de sous-titres. De plus, vous pouvez l'utiliser pour ajouter des sous-titres à des vidéos, des documents et des images, et les transformer en discours dans de nombreuses langues. \
    Un présentateur peut être sélectionné pour chaque sous-titre, le présentateur a des propriétés pour transformer le texte en discours dans certaines langues et voix, et une image (facultatif) pour générer un avatar synchronisé sur les lèvres pour prononcer le texte ; l'avatar a des propriétés telles que la forme, la taille et l'emplacement pour contrôler la façon dont il va s'afficher dans la vidéo. Découvrez quelques exemples de vidéos et de cas d'utilisation sur la page <a href='/'>accueil</a>.",
    FAQ2_question: "Alors comment commencer ?",
    FAQ2_answer: "Pour effectuer certaines tâches simples telles que l'application d'effets spéciaux aux vidéos, la génération de livres d'histoires et la transcription d'audios, vous pouvez trouver les éléments de menu dans le menu principal, remplir quelques champs et soumettre la tâche à traiter. Pour effectuer des tâches de synthèse vocale (TTS), vous devez également définir des présentateurs et modifier les sous-titres.\
    <ul><li>Tâche : Une tâche est un objet utilisateur contenant des données à traiter, tel que la vidéo et ses sous-titres, des options permettant de graver des sous-titres et de limiter la longueur de sortie. La fenêtre des tâches s'ouvre avec le bouton 'Tâche' dans le menu.</li> \
    <li>Sous-titres : Les sous-titres sont du texte représentant le contenu de l'audio dans une vidéo. Ils doivent inclure des informations sur le moment où chaque ligne de texte doit apparaître et disparaître. L'édition des sous-titres d'une vidéo est basée sur une chronologie, où vous spécifiez l'heure de début et de fin de chaque sous-titre. Lorsque vous travaillez avec un document ou une image, un sous-titre est défini pour chaque page/image, son heure de début et de fin est calculée automatiquement en fonction du temps réel nécessaire pour prononcer le texte.</li> \
    <li>Presenter : Le sous-titre possède une propriété Presenter, utilisée pour transformer le texte en parole. Un présentateur est un objet défini par l'utilisateur qui a un nom et une voix, éventuellement une image d'avatar d'une certaine forme, taille et emplacement dans la vidéo résultante. Par défaut chaque utilisateur dispose de 2 présentateurs prédéfinis modifiables. Les présentateurs sont vos assistants IA qui expriment vos idées.</li> \
    </ul>Démarrez une nouvelle tâche TTS en ouvrant un fichier vidéo/document/image, puis saisissez les sous-titres ou ouvrez un fichier existant contenant des sous-titres à modifier. L'application tentera d'extraire le texte de chaque page lorsque vous ouvrirez un document. \
    Pour chaque sous-titre, sélectionnez un présentateur, ajustez le texte ainsi que la position de début et de fin. Une fois que vous avez terminé l'édition, cliquez sur le bouton « Soumettre la tâche » dans la barre d'outils supérieure pour soumettre la tâche à traiter. Le lien de téléchargement vers la vidéo résultante peut être trouvé dans l’historique des tâches lorsqu’il est disponible.",
    FAQ3_question: "Comment puis-je obtenir la vidéo traitée ? et existe-t-il un moyen de prévisualiser le résultat ?",
    FAQ3_answer: "Une fois que vous avez soumis une tâche, elle sera mise en file d'attente pour être traitée. Une fois votre tâche traitée, vous serez averti par e-mail du résultat, vous pourrez ensuite retrouver la tâche sur la page <a href='/TaskHistory'>Historique des tâches</a>, utilisez le bouton de téléchargement pour télécharger la vidéo résultante. Pour une tâche TTS, vous saurez où chaque sous-titre apparaît, car le texte du sous-titre est synchronisé avec le lecteur vidéo/document/image, et le texte est superposé sur le lecteur à l'emplacement approprié ; \
    les discours et les avatars synchronisés sur les lèvres nécessitent la puissance du serveur pour être produits, la vidéo peut également être longue, il n'y a donc pas de moyen simple de prévisualiser le produit final, mais vous pouvez écouter un échantillon pour chaque voix sur la page <a href='/VoiceSamples'>Exemples de voix</a> et soumettre une courte tâche TTS à l'aide du présentateur pour tester si votre texte sonne bien avec la voix sélectionnée et si votre avatar peut synchroniser correctement les lèvres. \
    Pour vous assurer que les présentateurs travaillent comme prévu, il est recommandé de soumettre d'abord une tâche courte avec une durée de sortie limitée, par exemple plusieurs secondes, avant de soumettre une tâche de longueur complète. Une fois que vous obtenez la vidéo finale, examinez-la et ajustez les sous-titres et/ou les présentateurs si nécessaire, répétez ce processus jusqu'à ce que vous soyez satisfait.",
    FAQ4_question: "J'ai des sous-titres dans une langue, puis-je les ajouter à ma vidéo dans une autre langue ?",
    FAQ4_answer: "Oui, vous pouvez traduire les sous-titres dans une autre langue en utilisant le bouton « Traduire » dans la barre d'outils supérieure. L'application prend en charge %{count_languages} langues et %{count_voices} voix de genres et d'accents variés, il vous suffit de définir des présentateurs avec certaines voix et de les utiliser en fonction de la langue des sous-titres. Vous pouvez également cloner votre voix en fonction de n’importe quelle voix prédéfinie lors de la définition d’un présentateur.",
    FAQ5_question: "Conseils sur l'édition des sous-titres et les différents formats",
    FAQ5_answer: "La limite de longueur de chaque texte de sous-titre est de %{max_text_size1} caractères sur la page <a href='/VideoSubtitleToSpeech'>TTS pour la vidéo</a> et de %{max_text_size2} caractères sur la page <a href='/ImageSubtitleToSpeech'>TTS pour le document/l'image</a>. Vous devrez diviser le texte plus long en plusieurs sous-titres. Dans la barre d'outils supérieure, vous pouvez trouver un bouton « Annuler » pour annuler la dernière modification et un bouton « Effacer » pour effacer tous les sous-titres, et vous pouvez toujours ouvrir un fichier de sous-titres enregistré pour recommencer. Dans l'éditeur de sous-titres, vous pouvez trouver des boutons pour ajouter/supprimer/fusionner des sous-titres, \
    une liste de sélection du présentateur et un bouton pour ajuster la durée du sous-titre avec son présentateur, le réglage automatique de la durée se produit également lorsque vous changez de présentateur ou modifiez le texte. L'ajustement automatique de la durée s'effectue à l'aide d'une simple statistique du présentateur, qui alloue suffisamment de durée pour que le texte soit prononcé à la vitesse de parole normale du présentateur (une durée plus courte peut conduire à un discours plus rapide), mais vous pouvez la modifier selon vos besoins. Les sous-titres peuvent être exportés vers des fichiers sous plusieurs \
    formats, le format json est propre à cette application avec des informations sur le présentateur et d'autres formats peuvent être utilisés dans des lecteurs vidéo de bureau ou en ligne tels que YouTube. C'est une bonne habitude de sauvegarder les sous-titres actuels dans un fichier json avant de démarrer une nouvelle tâche ou en soumettant la tâche en cours, afin de pouvoir recharger les sous-titres pour effectuer des ajustements si nécessaire.",
    FAQ6_question: "À propos des plans d'abonnement",
    FAQ6_answer: "La principale différence entre les différents plans d'abonnement (gratuits ou payants) réside dans le nombre de présentateurs que vous pouvez créer et la durée totale des vidéos que vous pouvez soumettre pour traitement. Pour plus de détails, consultez la page <a href='/Pricing'>Pricing</a>, où vous pouvez souscrire à un plan payant si vous ne l'avez pas encore fait. Le solde de votre nombre de présentateurs et la durée de vos vidéos sont indiqués dans la section « Crédits disponibles » de la page <a href='/Account'>Compte</a>.",
    FAQ11_question: "Que puis-je faire avec VideoPlus Studio ?",
    FAQ11_answer: "VideoPlus Studio simplifie la transformation vidéo grâce à l'IA et réduit les obstacles à la production vidéo créative. Vous pouvez l'utiliser pour appliquer différents styles de dessins animés et filtres aux vidéos, générer des livres d'histoires vidéo, ainsi que transcrire l'audio en texte avec une langue détectée automatiquement et enregistrer le texte dans un fichier de sous-titres. De plus, vous pouvez l'utiliser pour ajouter des sous-titres à des vidéos, des documents et des images, et les transformer en discours dans de nombreuses langues. \
    Un présentateur peut être sélectionné pour chaque sous-titre, le présentateur a des propriétés pour transformer le texte en discours dans certaines langues et voix, et une image (facultatif) pour générer un avatar synchronisé sur les lèvres pour prononcer le texte ; l'avatar a des propriétés telles que la forme, la taille et l'emplacement pour contrôler la façon dont il va s'afficher dans la vidéo.",
    FAQ12_question: "Puis-je utiliser VideoPlus Studio gratuitement ?",
    FAQ12_answer: "Oui, créer/éditer/traduire/exporter des sous-titres est totalement gratuit. En outre, VideoPlus Studio propose un niveau gratuit qui permet aux utilisateurs d'explorer toutes les fonctionnalités avec un quota limité, par exemple 30 secondes de vidéo par jour. Cependant, les abonnements payants peuvent vous donner des quotas mensuels beaucoup plus élevés.",
    FAQ13_question: "En quoi cette application diffère-t-elle des autres éditeurs vidéo du marché ?",
    FAQ13_answer: "VideoPlus Studio se démarque en combinant l'édition de sous-titres avec la synthèse vocale pour faciliter l'ajout de voix off à vos vidéos à des emplacements précis. Outre les fonctionnalités de présentateur par sous-titre, de transcription audio et de traduction de texte, vous pouvez créer une voix off multi-personnes et multilingues via des sous-titres et doubler un film par vous-même. \
    La même capacité peut également être utilisée pour les documents et les images, afin que vous puissiez générer des vidéos pour présenter vos idées de manière vivante avec des voix et des avatars IA dans différentes langues.",
    FAQ14_question: "Combien de langues, de voix et d'avatars prenez-vous en charge ?",
    FAQ14_answer: "Nous prenons actuellement en charge 80 langues, 458 voix prédéfinies et 528 avatars prédéfinis. De plus, vous pouvez utiliser des voix adaptées au clonage pour cloner de nouvelles voix, et des avatars personnalisés peuvent être créés avec n'importe quelle photo de tête.",
    FAQ15_question: "Ai-je besoin d’un matériel ou d’un logiciel spécial pour utiliser l’application ?",
    FAQ15_answer: "Non, VideoPlus Studio est entièrement basé sur le Web, vous n'avez besoin que d'un appareil avec accès à Internet et d'un navigateur pour l'utiliser, aucune installation n'est nécessaire. Lorsque vous soumettez une tâche, nos serveurs travailleront dur pour la traiter et vous avertir une fois qu'elle sera terminée.",
    FAQ16_question: "Dois-je avoir une expérience préalable en montage vidéo pour utiliser cette application ?",
    FAQ16_answer: "Non, aucune expérience préalable en montage vidéo n'est requise pour utiliser cette application. Notre interface intuitive et conviviale permet à chacun de créer du contenu, et nous proposons également des didacticiels et des vidéos explicatives pour vous montrer les techniques de base d'édition de sous-titres et vous guider à travers quelques cas d'utilisation typiques.",
    FAQ17_question: "Quelles sont les options de paiement?",
    FAQ17_answer: "Nous utilisons PayPal pour gérer les abonnements en toute sécurité. Vous pouvez payer en utilisant votre compte PayPal ou toute carte de débit et de crédit prise en charge.",
    FAQ18_question: "Que se passe-t-il si je n'ai besoin d'utiliser l'application que pendant une courte période ?",
    FAQ18_answer: "Nous proposons des abonnements mensuels simples qui seront renouvelés automatiquement jusqu'à annulation ou mise en pause. Si vous disposez d'un compte PayPal, vous pouvez vous connecter au compte pour annuler ou suspendre votre abonnement à tout moment ; ou bien contactez-nous pour obtenir de l'aide.",
    Questions_suggestions: "Plus de questions ou de suggestions ?",
    Contact_Support: "Contactez le support",
    Privacy_Policy: "Politique de confidentialité",
    Terms_of_Service: "Conditions d'utilisation",
    SUPPORT: "Support",
    Support_info: "Si vous avez des questions ou des suggestions, veuillez nous contacter par e-mail à support@videoplus.studio ou via le formulaire d'assistance ci-dessous, nous vous répondrons dans les plus brefs délais.",
    ERROR_MESSAGE_FILE_INVALID_AND_NOT_TO_BE_INCLUDED: "Le fichier %{name} est un fichier multimédia non valide ou sa taille est trop grande et ne sera pas inclus pour la soumission.",
    Subscription_Plan: "Plan d'abonnement",
    Available_Credits: "Crédits disponibles (réinitialisés dans %{num} %{unit})",
    Other_Tasks: "Autres tâches",
    Extra_Credits: "Crédits supplémentaires",
    current: "actuel",
    next: "suivant",
    days: "jours",
    hours: "heures",
    minutes: "minutes",
    day: "jour",
    month: "mois",
    edit_subtitles: "créer/éditer/traduire/exporter des sous-titres",
    total_presenters: "nombre total de présentateurs : %{num}",
    total_video_length_for_transcription: "durée totale de la vidéo pour la transcription: %{num} minutes / %{duration}",
    total_video_length_for_other_tasks: "durée totale de la vidéo pour d'autres tâches: %{num} minutes / %{duration}",
    results_with_logo: "logo aiTransformer ajouté dans les vidéos traitées",
    results_without_logo: "aucun logo ajouté dans les vidéos traitées",
    results_kept_for_one_day: "les fichiers de résultats sont conservées une journée",
    results_kept_for_ten_days: "les fichiers de résultats sont conservées dix jours",
    Pricing_description: "Essai gratuit, passez à un forfait payant si vous avez besoin d'un quota plus élevé ; abonnement mensuel simple, pas besoin de s'engager sur du long terme et de résilier à tout moment",
    Select_Plan: "Sélectionnez le plan",
    login_and_subscribe: "Veuillez vous connecter puis souscrire au plan !",
    current_plan: "Ceci est votre plan actuel",
    next_plan: "Ceci est votre prochain plan",
    Subscription_Plan_note: "* Remarque : si vous ne trouvez pas de forfait répondant à vos besoins, veuillez nous contacter pour discuter d'autres options. Si vous avez souscrit à un forfait et souhaitez l'annuler, vous devez le faire via votre compte PayPal pour arrêter le paiement récurrent (consultez <a href='https://www.wikihow.com/Cancel-a-PayPal-Subscription' target='_blank' rel='noreferrer'>cet article</a> pour des instructions étape par étape). \
    Il est important de garder à l'esprit que tous les paiements sont définitifs et non remboursables, donc si vous annulez votre abonnement, il restera actif jusqu'à la fin de la période d'abonnement en cours. Si vous souhaitez passer à un autre forfait, vous devez d'abord annuler le forfait existant, attendre la fin de la période d'abonnement, puis souscrire au nouveau forfait. Si vous avez besoin d'une approche alternative, n'hésitez pas à nous contacter pour obtenir de l'aide.",
    Subscription_successful: "Votre abonnement est réussi, veuillez vous déconnecter puis vous connecter pour profiter du nouveau forfait.",
    Subscription_bonus: "Abonnez-vous à un forfait payant maintenant, vous obtiendrez également un forfait payant aiTransformer gratuitement.",
    slide_num: "diapositive %{num}",
    Help_videos_info: "Bienvenue dans VideoPlus Studio ! Les vidéos explicatives suivantes montrent les utilisations typiques de cette application. En fait, ces vidéos elles-mêmes sont des vidéos explicatives créées à l'aide de cette application.",
    Show_initial_screen: "Afficher ceci comme écran initial",
    Save_storybook_task_info: "Il s'agit de créer une tâche pour générer un livre d'histoires vidéo en utilisant les entrées fournies.",
    Story_Generation_Method: "Méthode de génération d'histoire",
    Custom_Generation_Method_hint: "Entrez vos intrigues pour générer de la voix et des prompts entourées de [] pour générer des images. Les phrases doivent se terminer par un point (.). Une image sera générée par phrase, par défaut la phrase sera utilisée comme prompt, mais si vous souhaitez utiliser une prompt personnalisée (entre []), placez-la avant la fin de la phrase, par exemple, phrase 1 [prompt d'image 1]. phrase 2 [prompt d'image 2].",
    Story_Genre: "Genre de l'histoire",
    Picture_Style: "Style des images générées",
    Style_Sample: "Échantillon de style (prompt: Santa is coming to town)",
    Custom_Style_hint: "Saisissez le texte pour le style personnalisé (poids pris en charge). Vous pouvez spécifier où placer au prompt à styliser avec un espace réservé [prompt], par ex. à couper le souffle [prompt] par Anna Dittmann. Si aucun espace réservé n’est présent, le texte de style sera ajouté au prompt.",
    Storyteller_Properties: "Propriétés du conteur",
    ERROR_MESSAGE_MISSING_PROMPT: "Erreur de méthode de génération : veuillez saisir un prompt.",
    ERROR_MESSAGE_MISSING_OR_INVALID_URL: "Erreur de méthode de génération : veuillez saisir un lien valide vers une page Web.",
    ERROR_MESSAGE_MISSING_DOCUMENT_FILE: "Erreur de méthode de génération : veuillez sélectionner un fichier de document.",
    ERROR_MESSAGE_MISSING_CUSTOM_STORY: "Erreur de méthode de génération : veuillez saisir votre histoire.",
    GENERATION_METHOD_OPTIONS_prompt: "Générer une histoire à partir d'un prompt (entrez le prompt)",
    GENERATION_METHOD_OPTIONS_url: "Générer une histoire basée sur le contenu d'une page Web (collez l'URL)",
    GENERATION_METHOD_OPTIONS_document: "Générer une histoire basée sur le contenu d'un document (télécharger le document, taille maximale : 10 Mo)",
    GENERATION_METHOD_OPTIONS_custom: "Générez de la voix avec le texte et les images fournis avec des prompts personnalisées (entrez l'histoire personnalisée)",
    STORY_GENRE_OPTIONS_: "indéfini",
    "STORY_GENRE_OPTIONS_picture books": "livres d'images (enfants de 0 à 5 ans)",
    "STORY_GENRE_OPTIONS_early chapter books": "livres des premiers chapitres (enfants âgés de 6 à 8 ans)",
    "STORY_GENRE_OPTIONS_middle grade novels": "romans de niveau intermédiaire (enfants de 9 à 12 ans)",
    "STORY_GENRE_OPTIONS_young adult fiction": "fiction pour jeunes adultes",
    "STORY_GENRE_OPTIONS_realist literature": "littérature réaliste",
    "STORY_GENRE_OPTIONS_literary fiction": "fiction littéraire",
    "STORY_GENRE_OPTIONS_science fiction": "science-fiction",
    STORY_GENRE_OPTIONS_fantasy: "fantaisie",
    "STORY_GENRE_OPTIONS_mystery/thriller": "mystère/thriller",
    STORY_GENRE_OPTIONS_romance: "romance",
    STORY_GENRE_OPTIONS_horror: "horreur",
    "STORY_GENRE_OPTIONS_historical fiction": "fiction historique",
    STORY_GENRE_OPTIONS_adventure: "aventure",
    STORY_GENRE_OPTIONS_drama: "drame",
    "STORY_GENRE_OPTIONS_comedy/satire": "comédie/satire",
    "STORY_GENRE_OPTIONS_TL;DR": "TL;DR",
    "STORY_GENRE_OPTIONS_first person": "première personne",
    STORY_OUTPUT_OPTIONS_video: "Vidéo et texte de l'histoire",
    STORY_OUTPUT_OPTIONS_gif: "Image GIF et sous-titre json (convient comme entrée de TTS pour document/image)",
    Select_Presenter: "Sélectionner le présentateur",
    default_storybook_description: "Pour générer un livre d'histoires avec la méthode : %{method}, le genre : %{genre}, le style : %{style}",
    Videoplus_Studio_headline0: "Éditeur et traducteur de sous-titres gratuits pour vidéos/documents/images. Sous-titres vocaux et texte vers vidéo avec voix et avatars personnalisables pour des présentations réalistes.",
    Videoplus_Studio_headline: "Faites parler vos vidéos/documents/images en 80 langues via les sous-titres",
    Videooplus_Studio_button_text: "Essayer gratuitement",
    Videooplus_Studio_specials: "<strong>VideoPlus Studio prend également en charge les effets vidéo tels que les styles de dessins animés et les filtres, comme expliqué dans les vidéos pratiques. Essayez-le maintenant totalement gratuitement ! Connectez-vous simplement à <a href='https://videoplus.studio' target='_blank'>VideoPlus Studio</a>, ouvrez la fenêtre Compte et mettez à jour votre compte avec le code promo « feb24zeniteq », vous obtiendrez un mois gratuit avec le forfait Lite, suffisant pour générer 125 minutes de vidéos. Ce coupon est valable courant février 2024.</strong>",
    Videoplus_Studio_subline: "Produisez des vidéos engageantes en interne avec l’édition des sous-titres – Pas de soucis de voix off !",
    Videoplus_Studio_highlights: "prend en charge %{count_languages} langues, %{count_voices} voix prédéfinies + clonage de voix, %{count_avatars} avatars prédéfinis + avatars personnalisés",
    Videoplus_Studio_highlights_1: " langues, ",
    Videoplus_Studio_highlights_2: " voix prédéfinies + clonage de voix, ",
    Videoplus_Studio_highlights_3: " avatars prédéfinis + avatars personnalisés",
    Videooplus_Studio_Tutorial: "Tutoriel Vidéoplus Studio",
    Videooplus_Studio_demo_video: "Vidéo de démonstration de VideoPlus Studio",
    Product_Service_Demos: "Démonstrations de produits et services",
    Product_Service_Demos_description: "Outil parfait pour démontrer des produits logiciels et créer des vidéos explicatives pour les services en ligne - enregistrez simplement l'écran montrant le fonctionnement du logiciel ou du service, puis ajoutez des présentateurs (voix + avatar) à l'enregistrement d'écran, comme la façon dont nous avons créé les vidéos du tutoriel aiTransformer dans anglais, français et espagnol, présenté par un groupe d'avatars de genres et d'accents variés à différents endroits.",
    Sales_Marketing: "Ventes et Marketing",
    Sales_Marketing_description: "Révolutionnez les ventes et le marketing virtuels en offrant une touche personnelle unique : les utilisateurs peuvent incorporer leurs propres images comme avatars dans les vidéos. Cette fonctionnalité ajoute de l'authenticité, favorisant des liens plus forts avec le public. Les professionnels de la vente peuvent humaniser les argumentaires, tandis que les spécialistes du marketing peuvent renforcer l'authenticité de la marque en mettant en valeur les membres de l'équipe.",
    Podcast_Helper: "Assistant de podcast",
    Podcast_Helper_description: "Transformez l'expérience de podcasting en permettant aux créateurs d'intégrer des aides à l'IA. La possibilité d'ajouter des présentateurs IA avec des voix et des avatars divers ajoute non seulement une touche unique au podcast, mais maintient également le public captivé. Les podcasteurs peuvent utiliser cette fonctionnalité pour simuler des conversations, introduire des sujets ou même fournir des informations supplémentaires, rendant ainsi leurs podcasts plus divertissants et informatifs.",
    Video_Transformer: "Transformateur vidéo",
    Video_Transformer_description: "Transformez des vidéos avec des effets de dessin animé et de filtre spéciaux, appliquez l'effet image par image tout en gardant l'auto intact, ce qui entraîne un transfert de style cohérent à chaque fois.",
    Storybook_Maker: "Créateur de livres d'histoires",
    Storybook_Maker_description: "Automatisez la création de livres d'histoires vidéo en combinant les technologies de modèle de langage étendu (LLM), de génération d'images et de synthèse vocale (TTS). À partir de simples entrées telles qu'une invite, une URL ou un document, il génère une histoire et les images qui l'accompagnent, les assemble dans une vidéo avec un présentateur personnalisé racontant l'histoire en utilisant la voix de votre choix.",
    Multilingual_More: "Multilingue et plus",
    Multilingual_More_description: "Élargissez votre audience en traduisant les sous-titres dans différentes langues et en présentant le contenu dans une langue autre que l'original, en brisant efficacement les barrières linguistiques et en atteignant un public mondial. Qu'il s'agisse de présentations professionnelles, de matériel pédagogique ou de contenu créatif, les fonctionnalités uniques de VideoPlus Studio améliorent l'accessibilité et garantissent une expérience multilingue véritablement immersive pour les spectateurs.",
    More_Use_Cases: "Plus de cas d'utilisation",
    Use_Case1_title: "Réinvention du didacticiel",
    Use_Case1: "Réutilisez les didacticiels vidéo existants en ajoutant de nouveaux commentaires, informations ou explications dans différentes langues. Améliorez le contenu éducatif sans refaire la totalité de la vidéo.",
    Use_Case2_title: "Exploration narrative",
    Use_Case2: "Créez du contenu narratif en utilisant diverses voix et avatars pour expliquer des concepts complexes ou raconter des histoires. Ceci est particulièrement utile pour les chaînes éducatives ou les entreprises souhaitant simplifier des sujets complexes.",
    Use_Case3_title: "Démonstrations de produits virtuels",
    Use_Case3: "Développez des démonstrations virtuelles de produits sans être devant la caméra. Les présentateurs peuvent expliquer les caractéristiques, les avantages et l'utilisation du produit, ce qui le rend idéal pour les entreprises du commerce électronique ou des secteurs technologiques.",
    Use_Case4_title: "Aide à l'apprentissage des langues",
    Use_Case4: "Facilitez l’apprentissage des langues en fournissant du contenu vidéo avec des sous-titres traduits et la langue parlée correspondante, offrant ainsi une nouvelle façon d’apprendre aux enseignants et aux apprenants en langues.",
    Use_Case5_title: "Améliorations régulières des podcasts",
    Use_Case5: "Améliorez continuellement les podcasts en intégrant des présentateurs IA pour introduire des segments, fournir des mises à jour ou engager des conversations. Cela apporte de la variété au format du podcast et maintient l'engagement du public.",
    Use_Case6_title: "Localisation du contenu et image de marque cohérente",
    Use_Case6: "Localisez régulièrement le contenu en traduisant les sous-titres et en adaptant la langue parlée pour atteindre des publics divers. Assurez une image de marque cohérente sur différents marchés linguistiques en utilisant VideoPlus Studio pour créer des vidéos promotionnelles avec des sous-titres traduits et du contenu parlé.",
    Use_Case7_title: "Emplacements de vente réguliers",
    Use_Case7: "Les équipes commerciales peuvent utiliser VideoPlus Studio pour créer des argumentaires de vente réguliers et personnalisés. La possibilité d'ajouter divers avatars et voix ajoute une touche unique à chaque pitch.",
    Use_Case8_title: "Présentations et propositions commerciales",
    Use_Case8: "Améliorez les documents professionnels et les présentations en ajoutant des voix off et des avatars. Cette fonctionnalité peut rendre les arguments de vente, les supports de formation et les propositions clients plus attrayants et mémorables, en captant l'attention du public et en transmettant les informations efficacement.",
    Use_Case9_title: "Matériel pédagogique et tutoriels",
    Use_Case9: "Transformez des documents pédagogiques tels que des présentations PowerPoint, des PDF ou du contenu HTML en didacticiels interactifs. Les utilisateurs peuvent ajouter des voix off et des avatars pour expliquer des concepts complexes, rendant ainsi l'apprentissage plus accessible et plus engageant pour les étudiants de tous âges.",
    Use_Case10_title: "Expositions culturelles et historiques",
    Use_Case10: "Développer des expositions ou des présentations interactives mettant en valeur le contenu culturel et historique. Utilisez des documents texte et des images pour créer des affichages informatifs, en ajoutant des avatars pour raconter des histoires, partager des idées ou fournir des commentaires, améliorant ainsi l'expérience du visiteur.",
    Use_Case11_title: "Cartes de vœux interactives",
    Use_Case11: "Créez des cartes de vœux personnalisées où chaque page présente un avatar dynamique délivrant un message personnalisé. Les utilisateurs peuvent télécharger leurs créations ou choisir parmi des modèles, ajoutant une touche unique aux occasions spéciales comme les anniversaires, les anniversaires ou les vacances.",
    Use_Case12_title: "Livres d'histoires engageants pour les enfants",
    Use_Case12: "Développez des livres d'histoires interactifs où chaque page prend vie avec un avatar parlant racontant l'histoire. Cette fonctionnalité peut améliorer l'engagement des enfants dans la narration, rendant l'apprentissage plus amusant et interactif.",
    Use_Case13_title: "Visites virtuelles et guides",
    Use_Case13: "Créez des visites virtuelles ou des guides interactifs à l'aide d'images et de documents texte. Ajoutez un présentateur pour guider les spectateurs à travers chaque section, en fournissant des informations, des explications ou des commentaires pour améliorer l'expérience et la compréhension du spectateur.",
    Use_Case14_title: "Projets multimédia personnalisés",
    Use_Case14: "Combinez du texte, des images et des voix off pour créer des projets multimédias personnalisés tels que des portfolios, des vitrines ou des albums numériques. Ajoutez des avatars pour raconter du contenu, partager des idées ou fournir des commentaires, rendant le projet plus engageant et interactif.",
    Use_Case15_title: "Transcription multilingue et doublage de films",
    Use_Case15: "Transcrivez l'audio/vidéo avec la détection automatique de la langue, facilitant une localisation et une traduction efficaces. Copiez facilement des films dans plusieurs langues, car chaque sous-titre peut avoir son propre présentateur, ce qui permet une voix off transparente dans différentes langues. Idéal pour les créateurs de contenu recherchant une distribution mondiale et l’engagement du public.",
    Use_Case16_title: "Transfert de style vidéo avec effets de dessin animé et de filtre",
    Use_Case16: "Améliorez les vidéos avec des effets de dessin animé et de filtre cohérents tout en préservant le contenu original. Appliquez des effets spéciaux image par image pour maintenir la cohérence visuelle tout au long de la vidéo. Idéal pour les équipes de production vidéo cherchant à rehausser leurs séquences avec des améliorations créatives sans compromettre leur intégrité.",
    Tutorials: "Tutoriels",
    tutorial1_title: "Comment appliquer des effets de dessin animé et de filtre aux vidéos",
    tutorial1_summary: "<ol><li>Cliquez sur le bouton « Effet vidéo » dans le menu.</li> \
    <li>Sélectionnez votre vidéo et un dessin animé ou un effet de filtre que vous aimez.</li> \
    <li>Cliquez sur « Soumettre » et enregistrez le numéro de la tâche, puis attendez de recevoir un e-mail concernant le résultat de la tâche, ou vérifiez l'historique des tâches pour connaître son statut, revenez si vous n'êtes pas prêt.</li> \
    <li>Recherchez la tâche et téléchargez le fichier vidéo obtenu lorsqu'il est disponible. Votre vidéo sera appliquée image par image avec l'effet sélectionné, tandis que l'audio d'origine est conservé intact.</li></ol>",
    tutorial2_title: "Comment transcrire des audios/vidéos",
    tutorial2_summary: "<ol><li>Cliquez sur le bouton « Transcription » dans le menu.</li> \
    <li>Sélectionnez votre audio/vidéo, ainsi que la langue et le format de sortie.</li> \
    <li>Cliquez sur « Soumettre » et enregistrez le numéro de la tâche, puis attendez de recevoir un e-mail concernant le résultat de la tâche, ou vérifiez l'historique des tâches pour connaître son statut, revenez si vous n'êtes pas prêt.</li> \
    <li>Recherchez la tâche et téléchargez le fichier texte résultant lorsqu'il est disponible.</li> \
    <li>Si vous avez sélectionné une vidéo, l'audio de la vidéo sera utilisé pour la transcription en texte. Si vous avez sélectionné le format de sortie srt ou vtt, le fichier résultant sera un fichier de sous-titres prêt à être édité dans TTS pour la vidéo ou le document/l'image.</li></ol>",
    tutorial3_title: "Comment créer des vidéos explicatives dynamiques à partir d'enregistrements sans voix",
    tutorial3_summary: "Supposons que vous ayez appris quelques astuces intéressantes et que vous souhaitiez créer une vidéo explicative à partager avec le monde, que vous aimeriez vous mettre dans la vidéo mais que vous ne vous sentiez pas à l'aise de parler devant une caméra, VideoPlus Studio peut résoudre le dilemme.<br/> \
    Si votre histoire se déroule dans le monde réel, comme un événement, la préparation d'un plat, la réparation d'un outil, etc., utilisez simplement un caméscope ou votre téléphone pour enregistrer ce qui se passe ; s'il s'agit de quelque chose dans le monde virtuel, vous pouvez facilement trouver un outil d'enregistrement d'écran pour enregistrer ce que vous faites sur l'écran. \
    Une fois l'enregistrement terminé, enregistrez-le au format MP4. Pendant l'enregistrement, vous n'avez pas à décider de quoi parler, car vous pouvez ensuite ouvrir la vidéo dans VideoPlus Studio, la lire et noter ce dont vous voulez parler comme sous-titres en fonction de la scène. \
    Dans l'application, vous pouvez définir un présentateur avec une voix que vous aimez, cloner votre propre voix si vous le souhaitez et télécharger votre image pour l'avatar du présentateur ; puis sélectionnez le présentateur pour vos sous-titres, l'application transformera les sous-titres en discours prononcés par l'avatar et les ajoutera à la vidéo, \
    résultant en une vidéo présentée par vous même si vous n'êtes pas du tout dans la vidéo originale.<br/> \
    Découvrez comment créer une vidéo explicative sur l'apprentissage de ComfyUI - un outil sophistiqué pour générer des images à l'aide de Stable Diffusion dans <a href='%{post_url}' target='_blank'>ce didacticiel étape par étape</a>.",
    tutorial4_title: "Comment transformer des documents en présentations dynamiques",
    tutorial4_summary: "Pouvez-vous transformer une simple présentation PowerPoint en un cours en ligne intéressant ? Essayons ce qui suit : \
    <ol><li>Utilisez une voix IA attrayante pour raconter le contenu ;</li> \
    <li>Ajoutez quelques effets sonores ;</li> \
    <li>Demandez à un chatbot de générer des paires questions/réponses amusantes en fonction du contenu ;</li> \
    <li>Ajouter des diapositives de questions/réponses présentées par des avatars ;</li> \
    <li>Assemblez le tout et générez une vidéo.</li></ol> \
    Découvrez comment transformer votre contenu statique en présentations dynamiques sans avoir besoin d'enregistrement vocal dans <a href='%{post_url}' target='_blank'>ce didacticiel étape par étape</a>.",
    tutorial5_title: "Comment doubler un film tout seul",
    tutorial5_summary: "Le doublage de films n'est pas une tâche simple, il nécessite généralement une équipe de professionnels pour la traduction, l'enregistrement de voix off, le mixage et le montage du son, etc. VideoPlus Studio peut détecter la langue parlée de l'audio dans une vidéo, transcrire l'audio en texte et l'enregistrer dans un fichier de sous-titres. \
    En plus d'un utilitaire de traduction et d'un éditeur de sous-titres flexible qui peut définir un présentateur pour chaque ligne de sous-titres, vous pouvez doubler un film vous-même - le processus ressemble beaucoup à celui effectué par un professionnel, mais sans les tracas liés à l'utilisation de vrais acteurs vocaux, à l'enregistrement, etc. \
    <br/>Apprenez à doubler la bande-annonce fictive du film « Retour vers le futur 4 » de l'anglais vers le français dans <a href='%{post_url}' target='_blank'>ce didacticiel étape par étape</a>.",
    tutorial6_title: "Comment combiner des images et des mots dans des vidéos narratives",
    tutorial6_summary: "\"Une image vaut mieux que mille mots.\" Les images et les mots peuvent tous deux raconter des histoires, mais les combiner dans une vidéo peut raconter une histoire plus vivante et donner vie à des contenus statiques. Nous aimons tous les livres d'histoires quand nous sommes enfants. Les mots accompagnés d'images sont plus attrayants et plus faciles à mémoriser. \
    Désormais, grâce à l'IA générative, des images peuvent être générées à partir de texte, et le texte peut être transformé en parole prononcée par un avatar. <br/>Apprenez à transformer un article en une vidéo narrative dynamique dans <a href='%{post_url}' target='_blank'>ce didacticiel étape par étape</a>.",
    tutorial7_title: "Créer des histoires personnalisées avec l'IA : un aperçu du créateur de livres d'histoires multimodal",
    tutorial7_summary: "L'art de raconter des histoires a évolué au cours de l'histoire, des traditions orales aux récits écrits, et maintenant à l'ère numérique. Le Storybook Maker est un outil considéré comme la prochaine étape de cette progression. Il a été développé dans le but d'automatiser la création d'histoires personnalisées, \
    intégrer l'apprentissage multimodal pour améliorer l'expérience de narration. <br/>Apprenez les subtilités et les astuces de l'outil Storybook dans <a href='%{post_url}' target='_blank'>cet article approfondi</a>.",
    Mobile_Apps: "Applications mobiles",
    Mobile_Apps_info: "Nous avons également développé quelques applications mobiles comme suit. Ces applications sont gratuites et sans aucun coût ni restriction. Aucune collecte de données utilisateur, aucune connexion, vos créations vous appartiennent, sans aucune marque. Nous espérons que vous les apprécierez et vos commentaires sont les bienvenus !",
    OfflineTextRecognizer: "Reconnaissance de texte hors ligne",
    OfflineTextRecognizer_description: "Extraire sans effort du texte à partir d'images, même sans connexion Internet",
    RussianBlock: "Russian Block",
    RussianBlock_description: "Redécouvrez le plaisir classique de Tetris en mode sans fin",
    aiTransformerBackgroundEditor: "aiTransformer Background Editor",
    aiTransformerBackgroundEditor_description: "Supprimez facilement l'arrière-plan de l'image, faites preuve de créativité en remplaçant l'arrière-plan par un nouveau",
    aiTransformerCartoonizer: "aiTransformer Cartoonizer",
    aiTransformerCartoonizer_description: "Transformez des images du monde réel en images de style dessin animé",
    aiTransformerEnhancer: "aiTransformer Enhancer",
    aiTransformerEnhancer_description: "Améliorez vos photos pour un aspect plus clair, plus net et plus professionnel",
    aiTransformerFilter: "aiTransformer Filter",
    aiTransformerFilter_description: "Ajoutez une touche d'originalité à vos photos avec plus de 50 filtres, notamment Sépia, Gaufrage et bien plus encore",
    aiTransformerMultiStylizer: "aiTransformer MultiStylizer",
    aiTransformerMultiStylizer_description: "Une approche créative du style d'image : appliquer plusieurs styles à une seule image",
    aiTransformerSketcher: "aiTransformer Sketcher",
    aiTransformerSketcher_description: "Créez différents styles de croquis à partir d'images, en simulant des dessins à la main",
    music_video_info: "Avec l'aide de l'application VideoPlus Studio, voici une vidéo musicale créée à l'aide d'images transformées avec les outils aiTransformer, avec une chanson générée sur ces outils ajoutée.",
};

const i18n = {
    en,
    es,
    fr,
};

export default i18n;

export const names = {
    en: "English",
    es: "Español",
    fr: "Française",
};

export const getName = key => {
    return (
        {
            en: "en",
            es: "es",
            fr: "fr",
        }[key] || "en"
    );
};

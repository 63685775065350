import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import NProgress from 'nprogress';
import HashMap from 'hashmap';
import RangeSlider from 'react-bootstrap-range-slider';
import{ Space, Tooltip, Select } from 'antd';
import { MdHelpOutline } from "react-icons/md";
//import debounce from 'lodash/debounce';
import { compareLanguages, compareVoices, resizeImage, axiosInstance, isFaceDetected, GENDER_OPTIONS, AVATAR_SHAPE_OPTIONS, MAX_AUDIO_FILE_SIZE, VOICE_WITH_STYLES, canUseVoice } from '../utils';
import VpsHeader from './VpsHeader';

export default function NewPresenter(props) {
    const { setLastScreen, setOption, setResultMessage, authenticated, sid, language, notify, ttsLanguages, ttsVoices, predefinedAvatars, faceDetector, user } = props;
    useEffect(() => {  
        setLastScreen('New_Presenter');
    }, [setLastScreen]);
    const navigate = useNavigate();

    const customAvatarValue = 'custom';
    const [name, setName] = useState(undefined);
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [selectedVoice, setSelectedVoice] = useState(undefined);
    const [voiceSampleUrl, setVoiceSampleUrl] = useState(undefined);
    const [voiceStyle, setVoiceStyle] = useState(undefined);
    const [styleDegree, setStyleDegree] = useState(undefined);
    const [cloneVoice, setCloneVoice] = useState(false);
    const [audioFile, setAudiofile] = useState(undefined);
    const [overrideFilmMode, setOverrideFilmMode] = useState(false);
    const [useAvatar, setUseAvatar] = useState(false);
    const [selectedAvatar, setSelectedAvatar] = useState(customAvatarValue);
    const [imageFile, setImageFile] = useState(undefined);
    const [useMovingHead, setUseMovingHead] = useState(false);
    const [adjustAudioProperties, setAdjustAudioProperties] = useState(false);
    const [audioPitch, setAudioPitch] = useState(1.0);
    const [audioSpeed, setAudioSpeed] = useState(1.0);
    const [audioVolume, setAudioVolume] = useState(0);
    const [avatarShape, setAvatarShape] = useState('original');
    const [avatarLocation, setAvatarLocation] = useState(1);
    const [avatarHeight, setAvatarHeight] = useState(25);
    const [hideAvatarWhenSilent, setHideAvatarWhenSilent] = useState(false);
    const [showLocationLegend, setShowLocationLegend] = useState(false);
  
    const languageMap = useRef();
    const languageOptions = useRef();
    const [voiceOptions, setVoiceOptions] = useState([]);
    const [voiceStyleOptions, setVoiceStyleOptions] = useState(undefined);
    const [avatarOptions, setAvatarOptions] = useState([]);
    const [creating, setCreating] = useState(false);
    
    useEffect(() => { 
        if (ttsLanguages.length > 0 && !languageMap.current) {
            languageMap.current = new HashMap();
            languageOptions.current = [];
            languageOptions.current.push({ value: "", text: "GENDER_OPTIONS_" });
            ttsLanguages.sort(compareLanguages).forEach(x => {
                languageMap.current.set(x.code, { "name": x.name, "test_text": x.test_text });
                languageOptions.current.push({ value: x.code, text: x.name });
            })
        }
    
        if (ttsVoices.current.length > 0 && languageMap.current && language) {
            const voiceOptions = [];
            ttsVoices.current.sort(compareVoices).forEach(v => {
                if (canUseVoice(user, v.voice_id)) {
                    const voiceLanguage = v.voice_id.substring(0, 3);
                    if ((!selectedGender || selectedGender === v.sex) && (!selectedLanguage || selectedLanguage === voiceLanguage)) {
                        let detail = v.voice_id;
                        detail += ' (' + t(languageMap.current.get(voiceLanguage)?.name);
                        if (v.sex)
                            detail +=  ', ' + t(v.sex);
                        if (v.clonable) 
                            detail +=  ', ' + t(`clonable_${v.clonable}`);
                        detail +=  ')';
                        voiceOptions.push({ value: v.voice_id, text: detail });
                    }
                }
            });
            setVoiceOptions(voiceOptions);
    
            if (voiceOptions && voiceOptions.length > 0) {
                if (!selectedVoice) {
                    const voice = voiceOptions[0].value;
                    setVoiceSampleUrl('/assets/voiceSamples/aiTransformer-synthesized_' + voice + '.mp3');
                    setSelectedVoice(voice);
                }
            } else {
                setVoiceSampleUrl(undefined);
                setCloneVoice(false);
            }
        }
    
        if (ttsVoices.current.length > 0 && predefinedAvatars.current.length > 0 && language) {
            const options = [];
            options.push({ value: customAvatarValue, text: t('custom_avatar_option') });
            let myAvatars;
            if (selectedGender) {
                myAvatars = predefinedAvatars.current.filter(a => a.sex === selectedGender);
            } else {
                myAvatars = predefinedAvatars.current;
            }
            myAvatars.forEach(v => {
                options.push({ value: v.name, text: v.name });
            });
            setAvatarOptions(options);
        }
    }, [ttsLanguages, ttsVoices, predefinedAvatars, selectedGender, selectedLanguage, selectedVoice, language, user]);
  
    const onChangeOfSelectedGender = newValue => {
        setSelectedGender(newValue);
        if (newValue) {
            setSelectedVoice(undefined);
            setVoiceStyleOptions(undefined);
            setSelectedAvatar(customAvatarValue);
            setImageFile(undefined);
        }
    } 
  
    const onChangeOfSelectedLanguage = newValue => {
        setSelectedLanguage(newValue);
        if (newValue) {
            setSelectedVoice(undefined);
            setVoiceStyleOptions(undefined);
        }
    } 
  
    const onChangeOfSelectedVoice = newValue => {
        setSelectedVoice(newValue);
        setVoiceSampleUrl('/assets/voiceSamples/aiTransformer-synthesized_' + newValue + '.mp3');
        setVoiceStyleOptions(undefined);
        if (VOICE_WITH_STYLES.has(newValue)) {
            const supportedStyles = VOICE_WITH_STYLES.get(newValue).split(',');
            setVoiceStyleOptions(supportedStyles);
            setVoiceStyle(supportedStyles[0]);
            setStyleDegree(undefined);
        }
    } 
  
    const onChangeOfSelectedVoiceStyle = newValue => {
        setVoiceStyle(newValue);
        setStyleDegree(undefined);
        if (newValue !== "default") {
            setStyleDegree(1.0);
        } 
    } 

    const onChangeOfStyleDegree = event => {
        setStyleDegree(event.target.value);
    }
  
    const updateAvatarOptions = () => {
        setSelectedAvatar(customAvatarValue);
        if (predefinedAvatars.current.length > 0) {
            avatarOptions.current = [];
            avatarOptions.current.push({ value: customAvatarValue, text: t('custom_avatar_option') });
            let myAvatars;
            if (selectedGender) {
                myAvatars = predefinedAvatars.current.filter(a => a.sex === selectedGender);
            } else {
                myAvatars = predefinedAvatars.current;
            }
            myAvatars.forEach(v => {
                avatarOptions.current.push({ value: v.name, text: v.name });
            });
        }
    } 
  
    const onChangeOfOverrideFilmMode = event => {
        setOverrideFilmMode(event.target.checked);
    } 
  
    const onChangeOfUseAvatar = event => {
        const isChecked = event.target.checked;
        setUseAvatar(isChecked);
        if (!avatarOptions.current)
            updateAvatarOptions();
    } 
  
    const selectAudioFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size < 100 || currentFile.size > MAX_AUDIO_FILE_SIZE) {  //a too small file is probably invalid
            setAudiofile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }
        /*
        if (currentFile.name.endsWith('.wav') || currentFile.name.endsWith('.aiff') || currentFile.name.endsWith('.aiff')) {
            try {  //convert uncompressed audio to mp3 format
                currentFile = await convertToMp3(currentFile);  
            } catch(err) {
                setAudiofile(undefined);
                notify({
                    message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                    level: 'error',
                });
                return;
            }
        }
        */
        setAudiofile(currentFile);
    }
  
    const onChangeOfSelectAvatar = newAvatar => {
        setSelectedAvatar(newAvatar);
        if (newAvatar === "custom") {
            setImageFile(undefined);
            return;
        }
        const fileName = `${newAvatar}.jpg`;
        const imageUrl = `/assets/predefinedAvatars/${fileName}`;
        fetch(imageUrl)
        .then(response => response.blob())
        .then(imageBlob => {
            const file = new File([imageBlob], fileName);
            setImageFile(file);
        });
    } 
  
    const selectImageFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size > 1048576 || currentFile.size < 100) {  //no need to resize when size < 1M, and a too small file is probably invalid
            try {
                currentFile = await resizeImage(currentFile);
            } catch(err) {
                setImageFile(undefined);
                notify({
                    message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                    level: 'warning',
                });
                return;
            }
        }

        const faceDetected = await isFaceDetected(faceDetector.current, currentFile);
        if (!faceDetected) {
            setImageFile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FACE_NOT_DETECTED', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }

        setImageFile(currentFile);
    }
  
    const onChangeOfName = event => {
        setName(event.target.value);
    } 
    
    const onChangeOfCloneVoice = event => {
        setCloneVoice(event.target.checked);
    } 
    
    const onChangeOfAdjustAudioProperties = event => {
        setAdjustAudioProperties(event.target.checked);
    } 
    
    const onChangeOfAudioPitch = event => {
        setAudioPitch(event.target.value);
    } 
    
    const onChangeOfAudioSpeed = event => {
        setAudioSpeed(event.target.value);
    } 

    const onChangeOfAudioVolume = event => {
        setAudioVolume(event.target.value);
    } 

    const onChangeOfAvatarShape = value => {
        setAvatarShape(value);
    } 
    
    const onChangeOfAvatarLocation = event => {
        setAvatarLocation(event.target.value);
    } 

    const onChangeOfAvatarHeight = event => {
        setAvatarHeight(event.target.value);
    } 

    const onChangeOfUseMovingHead = event => {
        setUseMovingHead(event.target.checked);
    } 
    
    const onChangeOfHideAvatarWhenSilent = event => {
        setHideAvatarWhenSilent(event.target.checked);
    } 
    
    const savePresenter = () => {
        let voice = selectedVoice;
        if (!voice && voiceOptions && voiceOptions.length > 0) {
            voice = voiceOptions[0].value;
        }
        if (!name) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_REQUIRED_FIELD', { field: t('Name') }),
                level: 'error',
            });
            return;
        }
        if (!voice) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_REQUIRED_FIELD', { field: t('Voice_Id') }),
                level: 'error',
            });
            return;
        }
        if (cloneVoice && !audioFile) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_CUSTOM_VOICE'),
                level: 'error',
            });
            return;
        }
        if (useAvatar && (selectedAvatar === customAvatarValue) && !imageFile) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_CUSTOM_AVATAR'),
                level: 'error',
            });
            return;
        }

        setCreating(true);
        NProgress.start().set(0.5);
        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("name", name);
        formData.append("sex", selectedGender);
        formData.append("voiceId", selectedVoice);
        formData.append("cloneVoice", cloneVoice);
        if (voiceStyle) {
            formData.append("voiceStyle", voiceStyle);
        }
        if (styleDegree) {
            formData.append("styleDegree", styleDegree);
        }
        if (adjustAudioProperties) {
            formData.append("audioPitch", audioPitch);
            formData.append("audioSpeed", audioSpeed);
            formData.append("audioVolume", audioVolume);
        }
        formData.append("overrideFilmMode", overrideFilmMode);
        formData.append("useAvatar", useAvatar);
        if (useAvatar) {
            formData.append("selectedAvatar", selectedAvatar);
            formData.append("useMovingHead", useMovingHead);
            formData.append("avatarShape", avatarShape);
            formData.append("avatarLocation", avatarLocation);
            formData.append("avatarHeight", avatarHeight);
            formData.append("hideAvatarWhenSilent", hideAvatarWhenSilent);
        }
    
        const selectedFiles = [];
        if (cloneVoice && audioFile) {
            selectedFiles.push(audioFile);
        }
        if (useAvatar && imageFile) {
            selectedFiles.push(imageFile);
        }
        selectedFiles.forEach(f => {
            formData.append("file", f);
        });

        axiosInstance.post("/savePresenter", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            NProgress.done();
            setCreating(false);
            setOption({ newPresenterDialog: false, reloadUserPresenters: true });
            setResultMessage(response.data.message);
            navigate('/PresenterResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            NProgress.done();
            setCreating(false);
        })
    }
    //const savePresenter2 = debounce(savePresenter, 3000);
    //const savePresenter3 = useCallback(savePresenter2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            {!authenticated ? (
                <div className="row m-3">
                    { t("Sign_in_to_edit_presenter") }
                </div>
            ) : (
                <div>
                    <div className="row mx-2 mb-3">
                        <div className="col-md-3">
                        <Space wrap>
                            { t('Name') } <font color="Red">*</font>
                            <Tooltip title={t('Presenter_Name_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                        </div>
                        <div className="col-md-9">
                            <input type="text" className="form-control w-50" value={name} onChange={onChangeOfName} />
                        </div>
                    </div>
                    
                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                            { t('Gender') }
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={selectedGender} onChange={onChangeOfSelectedGender}>
                                {GENDER_OPTIONS.map(item => 
                                    (<Select.Option key={item.value} value={item.value}>{ t('GENDER_OPTIONS_' + item.value) }</Select.Option>)
                                )}
                            </Select>
                        </div>
                    </div>
                    
                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                            { t('Language') }
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={selectedLanguage} onChange={onChangeOfSelectedLanguage}>
                                {languageOptions.current?.map(item => 
                                    (<Select.Option key={item.value} value={item.value}>{ t(item.text) }</Select.Option>)
                                )}
                            </Select>
                        </div>
                    </div>
                    
                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                            { t('Voice_Id') } <font color="Red">*</font>
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} showSearch value={selectedVoice} onChange={onChangeOfSelectedVoice}>
                                {voiceOptions?.map(item => 
                                    (<Select.Option key={item.value} value={item.value}>{ item.text }</Select.Option>)
                                )}
                            </Select>
                        </div>
                    </div>
                    
                    {voiceSampleUrl && (
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Voice_Sample') }
                            </div>
                            <div className="col-md-9">
                                <audio controls src={voiceSampleUrl} />
                            </div>
                        </div>
                    )}
                    
                    {voiceStyleOptions && (
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                { t('Voice_Style') }
                            </div>
                            <div className="col-md-9">
                                <Select popupMatchSelectWidth={false} value={voiceStyle} onChange={onChangeOfSelectedVoiceStyle}>
                                    {voiceStyleOptions?.map(item => 
                                        (<Select.Option key={item} value={item}>{ item }</Select.Option>)
                                    )}
                                </Select>
                            </div>
                        </div>
                    )}

                    {voiceStyleOptions && styleDegree && (
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                { t('Style_Degree') }
                            </div>
                            <div className="col-md-9">
                                <div className="input-group mb-3">
                                    <RangeSlider min={0.1} max={2.0} step={0.1} value={styleDegree} onChange={onChangeOfStyleDegree} />&nbsp;&nbsp;
                                    <div className="input-group-append">
                                        <span className="input-group-text ml-2">
                                            {styleDegree}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                        <Space wrap>
                            { t('Clone_Voice') }
                            <Tooltip title={t('Clone_Voice_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                        </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={cloneVoice} onChange={onChangeOfCloneVoice} />
                        </div>
                    </div>
                    {cloneVoice && (
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Voice_To_Clone') }
                                <Tooltip title={t('Voice_To_Clone_tip', {maxSize: MAX_AUDIO_FILE_SIZE/1048576})} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                            </div>
                            <div className="col-md-9">
                                <label className="btn mb-3 mr-3 btn-light">
                                    <input type="file" accept="audio/*" onChange={selectAudioFile} />
                                </label>
                                {audioFile && (
                                    <div>
                                        <audio controls src={URL.createObjectURL(audioFile)} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                            { t('Adjust_Audio_Properties') }
                        </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={adjustAudioProperties} onChange={onChangeOfAdjustAudioProperties} />
                        </div>
                    </div>
                    {adjustAudioProperties && (
                    <div>
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Pitch') }
                            </div>
                            <div className="col-md-9">
                                <div className="input-group mb-3">
                                    <RangeSlider min={0.5} max={2.0} step={0.1} value={audioPitch} onChange={onChangeOfAudioPitch} />&nbsp;&nbsp;
                                    <div className="input-group-append">
                                        <span className="input-group-text ml-2">
                                            {audioPitch}x
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Speed') }
                            </div>
                            <div className="col-md-9">
                                <div className="input-group mb-3">
                                    <RangeSlider min={0.5} max={2.0} step={0.1} value={audioSpeed} onChange={onChangeOfAudioSpeed} />&nbsp;&nbsp;
                                    <div className="input-group-append">
                                        <span className="input-group-text ml-2">
                                            {audioSpeed}x
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Volume') }
                            </div>
                            <div className="col-md-9">
                                <div className="input-group mb-3">
                                    <RangeSlider min={-20} max={20} step={1} value={audioVolume} onChange={onChangeOfAudioVolume} />&nbsp;&nbsp;
                                    <div className="input-group-append">
                                        <span className="input-group-text ml-2">
                                            {audioVolume}db
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}

                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                        <Space wrap>
                            { t('Override_Film_Mode') }
                            <Tooltip title={t('Override_Film_Mode_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                        </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={overrideFilmMode} onChange={onChangeOfOverrideFilmMode} />
                        </div>
                    </div>

                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                        <Space wrap>
                            { t('Use_Avatar') }
                            <Tooltip title={t('Use_Avatar_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                        </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={useAvatar} onChange={onChangeOfUseAvatar} />
                        </div>
                    </div>
                    {useAvatar && (
                    <div>
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Avatar_Image') }
                            </div>
                            <div className="col-md-9">
                                <Select popupMatchSelectWidth={false} showSearch value={selectedAvatar} onChange={onChangeOfSelectAvatar}>
                                    {avatarOptions?.map(item => 
                                        (<Select.Option key={item.value} value={item.value}>{ item.text }</Select.Option>)
                                    )}
                                </Select>
                                {(!selectedAvatar || selectedAvatar === customAvatarValue) && (
                                    <div>
                                        <label className="btn mb-3 mr-3 btn-light">
                                            <input type="file" accept="image/*" onChange={selectImageFile} />
                                        </label>
                                    </div>
                                )}
                                {imageFile && (
                                    <div>
                                        <img src={URL.createObjectURL(imageFile)} alt={selectedAvatar} className="img-fluid preview" />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Avatar_Shape') }
                            </div>
                            <div className="col-md-9">
                                <Select popupMatchSelectWidth={false} value={avatarShape} onChange={onChangeOfAvatarShape}>
                                    {AVATAR_SHAPE_OPTIONS.map(item => 
                                        (<Select.Option key={item.value} value={item.value}>{ t('AVATAR_SHAPE_OPTIONS_' + item.value) }</Select.Option>)
                                    )}
                                </Select>
                            </div>
                        </div>
                    
                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Avatar_Location') }
                                <Tooltip title={t('Avatar_Location_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                            </div>
                            <div className="col-md-9">
                                <div className="input-group mb-3">
                                    <RangeSlider min={1} max={16} step={1} value={avatarLocation} onChange={onChangeOfAvatarLocation} />&nbsp;&nbsp;
                                    <div className="input-group-append">
                                        <span className="input-group-text ml-2">
                                            {avatarLocation}
                                        </span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                            className="btn mb-3 mr-3 btn-outline-warning"
                                            onClick={() => setShowLocationLegend(!showLocationLegend)}
                                        >
                                            { t('Toggle_Show_Legend') }
                                        </button>
                                    </div>
                                    {showLocationLegend && (
                                        <div>
                                            <img src="/assets/legendOfAvatarLocation.jpg" alt={t("legendOfAvatarLocation")} className="img-fluid preview" />
                                        </div>
                                    )}
                            </div>
                        </div>

                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Avatar_Height') }
                                <Tooltip title={t('Avatar_Height_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                            </div>
                            <div className="col-md-9">
                                <div className="input-group mb-3">
                                    <RangeSlider min={10} max={90} step={1} value={avatarHeight} onChange={onChangeOfAvatarHeight} />&nbsp;&nbsp;
                                    <div className="input-group-append">
                                        <span className="input-group-text ml-2">
                                            {avatarHeight}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Use_Moving_Head') }
                                <Tooltip title={t('Use_Moving_Head_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                            </div>
                            <div className="col-md-9">
                                <input type="checkbox" checked={useMovingHead} onChange={onChangeOfUseMovingHead} />
                            </div>
                        </div>

                        <div className="row mx-2 my-3">
                            <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Hide_Avatar_When_Silent') }
                                <Tooltip title={t('Hide_Avatar_When_Silent_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                            </div>
                            <div className="col-md-9">
                                <input type="checkbox" checked={hideAvatarWhenSilent} onChange={onChangeOfHideAvatarWhenSilent} />
                            </div>
                        </div>
                    </div>
                    )}

                    <div className="row mx-2 my-3">
                        <div className="col-12 text-center">
                            <button
                                className="btn mb-3 mr-3 btn-info btn-lg"
                                onClick={() => savePresenter()}
                                disabled={creating}
                            >
                                { creating ? t('sending') : t('Save') }
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </VpsHeader>
    );
}
